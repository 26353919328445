import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ROLES } from '../../model';

@Injectable()
export class LaravelUserService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      currentUser: environment.baseUrl + "/api/currentUser",
      login: environment.baseUrl + "/api/login",
      show: environment.baseUrl + "/api/user",
      list: environment.baseUrl + "/api/users",
      store: environment.baseUrl + "/api/user",
      delete: environment.baseUrl + "/api/user",
      changePassword: environment.baseUrl + "/api/changePassword"
    };
  }

  public getCurrentUser(): Observable<any> {
    return this.httpClient.get<any>(this.ROUTES.currentUser);
  }

  public login(email: string, password: string): Observable<{ token: string, dto: any }> {
    let params = {
      email: email,
      password: password
    };
    return this.httpClient.post<any>(this.ROUTES.login, params).pipe(map(result => {
      return {
        token: result.success.token,
        dto: result.success.user
      }
    }));
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, filter?: string, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filter) params["filter"] = "" + filter;
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(name: string, surname: string, email: string, password: string, admin: boolean = false, viewer: boolean = false, office: boolean = false, operator_id?: number) {
    let user_roles = [];
    if (admin) user_roles.push(ROLES.ADMIN);
    if (office) user_roles.push(ROLES.OFFICE);
    if (viewer) user_roles.push(ROLES.VIEWER);
    if (operator_id) user_roles.push(ROLES.DRIVER);
    let user = {
      name: name,
      surname: surname,
      email: email,
      password: password,
      operator_id: operator_id,
      user_roles: user_roles
    };
    return this.httpClient.post(this.ROUTES.store, { user: user });
  }

  public update(id: number, name: string, surname: string, email: string, admin: boolean = false, viewer: boolean = false, office: boolean = false, operator_id?: number) {
    let user_roles = [];
    if (admin) user_roles.push(ROLES.ADMIN);
    if (office) user_roles.push(ROLES.OFFICE);
    if (viewer) user_roles.push(ROLES.VIEWER);
    if (operator_id) user_roles.push(ROLES.DRIVER);
    let user = {
      id: id,
      name: name,
      surname: surname,
      email: email,
      operator_id: operator_id,
      user_roles: user_roles
    };
    return this.httpClient.put(this.ROUTES.store, { user: user });
  }

  public changePassword(id: number, newPassword: string) {
    let params = {
      id: "" + id,
      new_password: newPassword
    };
    return this.httpClient.put(this.ROUTES.changePassword, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
