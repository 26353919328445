import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getNextDay } from 'src/app/shared/helpers/util';

import { DailyViewComponent } from '../planning/daily-view/daily-view.component';
import { OperatorsResolverService } from '../planning/helpers/operators-resolver.service';
import { VehiclesResolverService } from '../planning/helpers/vehicles-resolver.service';

const routes: Routes = [
  {
    path: "",
    redirectTo: "today",
    pathMatch: "full"
  },
  {
    path: "today",
    component: DailyViewComponent,
    data: {
      date: new Date()
    },
    resolve: {
      operators: OperatorsResolverService,
      vehicles: VehiclesResolverService
    }
  },
  {
    path: "tomorrow",
    component: DailyViewComponent,
    data: {
      date: getNextDay(new Date(), false)
    },
    resolve: {
      operators: OperatorsResolverService,
      vehicles: VehiclesResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [OperatorsResolverService, VehiclesResolverService]
})
export class AssignmentRoutingModule { }
