import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatAutocompleteSelectedEvent,
  MatDialog,
  MatDialogRef
} from "@angular/material";
import { BehaviorSubject, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  tap
} from "rxjs/operators";
import { UserService } from "src/app/shared/services";

import { Comment, Order, User } from "../../../shared/model";
import { AlertService } from "../../../shared/services/alert.service";
import { CommentService } from "../../../shared/services/comment.service";

@Component({
  selector: "app-comment-detail",
  templateUrl: "./comment-detail.component.html",
  styleUrls: ["./comment-detail.component.scss"]
})
export class CommentDetailComponent implements OnInit {
  userSearchResults = [];
  searchResultMaxLength = 15;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private searchingUser = new BehaviorSubject<boolean>(false);
  public searchingUser$ = this.searchingUser.asObservable();

  _comment: Comment;
  _hasUpdates: boolean = false;
  commentForm: FormGroup;
  _order: Order;

  userCtrl = new FormControl();
  @ViewChild("userInput") userInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CommentDetailComponent>,
    private commentService: CommentService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.data) {
      this.comment = this.data.comment;
      this.order = this.data.order;
    }
    this.ngOnChanges();
  }

  get comment(): Comment {
    return this._comment;
  }

  set comment(comment: Comment) {
    this._comment = comment;
    this.ngOnChanges();
  }

  get order(): Order {
    return this._order;
  }

  set order(order: Order) {
    this._order = order;
  }

  get hasUpdates(): boolean {
    return this._hasUpdates;
  }

  set hasUpdates(hasUpdates: boolean) {
    this._hasUpdates = hasUpdates;
  }

  createForm() {
    let group = {
      body: ["", Validators.required],
      users: [""]
    };
    this.commentForm = this.fb.group(group);

    this.userCtrl.valueChanges
      .pipe(
        debounceTime(250),
        tap(() => this.searchingUser.next(true)),
        distinctUntilChanged(),
        switchMap(data => {
          if (data && typeof data === "string") {
            return this.userService
              .getUsers(1, this.searchResultMaxLength, "name", "asc", data)
              .pipe(finalize(() => this.searchingUser.next(false)));
          }
          return of(null).pipe(finalize(() => this.searchingUser.next(false)));
        })
      )
      .subscribe(result => {
        if (result) {
          this.userSearchResults = result.data;
        }
      });
  }

  ngOnChanges() {
    if (this.commentForm) {
      this.commentForm.reset();
      if (this.comment) {
        this.commentForm.patchValue({
          body: this.comment.body,
          users: null
        });
      }
    }
  }

  prepareSaveComment(): Comment {
    let saveComment: Comment = Comment.fromFormGroup(this.commentForm);
    if (this.comment && this.comment.objectId) {
      saveComment.objectId = this.comment.objectId;
    }
    saveComment.order = this.order;
    return saveComment;
  }

  revert() {
    this.ngOnChanges();
  }

  close() {
    if (this.commentForm.pristine) {
      this.doClose();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  private doClose() {
    this.dialogRef.close(this.hasUpdates);
  }

  private getNotifiableUsers(): User[] {
    return this.commentForm.value["users"];
  }

  onSubmit() {
    this.loadingSubject.next(true);
    let unsavedComment = this.prepareSaveComment();
    if (unsavedComment.objectId) {
      this.commentService
        .updateComment(unsavedComment, this.getNotifiableUsers())
        .subscribe(
          savedComment => {
            this.comment = savedComment;
            console.log(`Comment updated`);
            this.hasUpdates = true;
            this.alertService.showConfirmMessage("Commento aggiornato");
            this.loadingSubject.next(false);
          },
          error => {
            console.error(`Error while updating comment`, error);
            this.loadingSubject.next(false);
          }
        );
    } else {
      this.commentService
        .createComment(unsavedComment, this.getNotifiableUsers())
        .subscribe(
          savedComment => {
            this.comment = savedComment;
            console.log(`Comment added`);
            this.hasUpdates = true;
            this.alertService.showConfirmMessage("Commento aggiunto");
            this.loadingSubject.next(false);
          },
          error => {
            console.error(`Error while adding order`, error);
            this.loadingSubject.next(false);
          }
        );
    }
  }

  deleteCommet() {
    this.alertService
      .showConfirmDialog(
        "Conferma eliminazione",
        "Sei sicuro di voler eliminare il commento?"
      )
      .subscribe(result => {
        if (result) {
          this.loadingSubject.next(true);
          this.commentService.deleteComment(this.comment).subscribe(() => {
            console.log("Commento eliminato");
            this.alertService.showConfirmMessage("Commento archiviato");
            this.hasUpdates = true;
            this.loadingSubject.next(false);
            this.close();
          });
        }
      });
  }

  removeUser(index: number): void {
    this.selectedUsers.splice(index, 1)[0];
    this.commentForm.patchValue({
      users: this.selectedUsers
    });
    this.commentForm.markAsDirty();
  }

  get selectedUsers(): User[] {
    let users = this.commentForm.get("users").value;
    if (!users) {
      users = [];
      this.commentForm.patchValue({
        users: users
      });
    }
    return users;
  }

  displayUser(user?: User): string | undefined {
    return user ? `${user.name} ${user.surname}` : undefined;
  }

  userSelected(event: MatAutocompleteSelectedEvent): void {
    let user = event.option.value;
    this.selectedUsers.push(user);
    this.commentForm.patchValue({
      users: this.selectedUsers
    });
    this.userInput.nativeElement.value = "";
    this.userCtrl.setValue(null);
    this.commentForm.markAsDirty();
  }
}
