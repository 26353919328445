import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../helpers/util';

@Injectable()
export class LaravelOrderService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/order",
      downloadPdf: environment.baseUrl + "/api/order/download_PDF",
      list: environment.baseUrl + "/api/orders",
      store: environment.baseUrl + "/api/order",
      delete: environment.baseUrl + "/api/order",
      restore: environment.baseUrl + "/api/orderRestore",
      close: environment.baseUrl + "/api/orderClose",
      reopen: environment.baseUrl + "/api/orderReopen",
      duplicate: environment.baseUrl + "/api/orderDuplicate",
      default_client: environment.baseUrl + "/api/default_client",
      export: environment.baseUrl + "/api/orders/export",
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public downloadPdf(id: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    return this.httpClient.get(this.ROUTES.downloadPdf, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public list(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    order_id?: number,
    client_id?: number,
    supplier_id?: number,
    address_id?: number,
    include_closed?: boolean,
    check_tech?: boolean,
    check_commercial?: boolean,
    check_logistic?: boolean,
    check_plant?: boolean,
    urgent?: boolean,
    on_plan?: boolean,
    include_planned?: boolean,
    include_trashed?: boolean,
    types?: string[],
    search_query?: string,
    destination?: string,
    include?: string[],
    excluded?: number[]
  ): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (order_id) params["order_id"] = "" + order_id;
    if (client_id) params["client_id"] = "" + client_id;
    if (supplier_id) params["supplier_id"] = "" + supplier_id;
    if (address_id) params["address_id"] = "" + address_id;
    if (include_closed != undefined && include_closed != null)
      params["include_closed"] = "" + include_closed;
    if (check_tech != undefined) params["check_tech"] = "" + check_tech;
    if (check_commercial != undefined)
      params["check_commercial"] = "" + check_commercial;
    if (check_logistic != undefined)
      params["check_logistic"] = "" + check_logistic;
    if (check_plant != undefined) params["check_plant"] = "" + check_plant;
    if (urgent != undefined) params["urgent"] = "" + urgent;
    if (on_plan != undefined) params["on_plan"] = "" + on_plan;
    if (include_planned != undefined && include_planned != null)
      params["include_planned"] = "" + include_planned;
    if (include_trashed != undefined && include_trashed != null)
      params["include_trashed"] = "" + include_trashed;
    if (types) params["types[]"] = types;
    if (search_query) params["search_query"] = search_query;
    if (destination) params["destination"] = destination;
    if (include) params["include[]"] = include;
    if (excluded) params["excluded[]"] = excluded;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(
    status: string,
    client_id: number,
    supplier_id: number,
    type: string,
    date: Date,
    urgent: boolean,
    on_plan: boolean,
    check_tech: boolean,
    check_commercial: boolean,
    check_logistic: boolean,
    check_plant: boolean,
    need_formulary: boolean,
    need_transport: boolean,
    need_dumping: boolean,
    need_rollout: boolean,
    need_packaging: boolean,
    attachment: any,
    reference: string,
    note: string,
    ordered_products: Array<any>
  ) {
    let formData: FormData = new FormData();
    if (status != undefined) formData.append("status", status);
    if (client_id != undefined) formData.append("client_id", "" + client_id);
    if (supplier_id != undefined)
      formData.append("supplier_id", "" + supplier_id);
    if (type != undefined) formData.append("type", type);
    if (date != undefined) formData.append("date", formatDateForBackend(date));
    if (urgent != undefined) formData.append("urgent", "" + urgent);
    if (on_plan != undefined) formData.append("on_plan", "" + on_plan);
    if (check_tech != undefined) formData.append("check_tech", "" + check_tech);
    if (check_commercial != undefined)
      formData.append("check_commercial", "" + check_commercial);
    if (check_logistic != undefined)
      formData.append("check_logistic", "" + check_logistic);
    if (check_plant != undefined)
      formData.append("check_plant", "" + check_plant);
    if (need_formulary != undefined)
      formData.append("need_formulary", "" + need_formulary);
    if (need_transport != undefined)
      formData.append("need_transport", "" + need_transport);
    if (need_rollout != undefined)
      formData.append("need_rollout", "" + need_rollout);
    if (need_dumping != undefined)
      formData.append("need_dumping", "" + need_dumping);
    if (need_rollout != undefined)
      formData.append("need_rollout", "" + need_rollout);
    if (need_packaging != undefined)
      formData.append("need_packaging", "" + need_packaging);
    if (reference != undefined) formData.append("reference", reference);
    if (note != undefined) formData.append("note", note);
    if (ordered_products != undefined)
      formData.append("ordered_products", JSON.stringify(ordered_products));

    if (attachment) {
      formData.append("attachment", attachment);
    }
    return this.httpClient.post(this.ROUTES.store, formData);
  }

  public update(
    id: number,
    status: string,
    client_id: number,
    supplier_id: number,
    type: string,
    date: Date,
    urgent: boolean,
    on_plan: boolean,
    check_tech: boolean,
    check_commercial: boolean,
    check_logistic: boolean,
    check_plant: boolean,
    need_formulary: boolean,
    need_transport: boolean,
    need_dumping: boolean,
    need_rollout: boolean,
    need_packaging: boolean,
    attachment: any,
    reference: string,
    note: string,
    ordered_products: Array<any>
  ) {
    let formData: FormData = new FormData();
    formData.append("id", "" + id);
    if (status != undefined) formData.append("status", status);
    if (client_id != undefined) formData.append("client_id", "" + client_id);
    if (supplier_id != undefined)
      formData.append("supplier_id", "" + supplier_id);
    if (type != undefined) formData.append("type", type);
    if (date != undefined) formData.append("date", formatDateForBackend(date));
    if (urgent != undefined) formData.append("urgent", "" + urgent);
    if (on_plan != undefined) formData.append("on_plan", "" + on_plan);
    if (check_tech != undefined) formData.append("check_tech", "" + check_tech);
    if (check_commercial != undefined)
      formData.append("check_commercial", "" + check_commercial);
    if (check_logistic != undefined)
      formData.append("check_logistic", "" + check_logistic);
    if (check_plant != undefined)
      formData.append("check_plant", "" + check_plant);
    if (need_formulary != undefined)
      formData.append("need_formulary", "" + need_formulary);
    if (need_transport != undefined)
      formData.append("need_transport", "" + need_transport);
    if (need_rollout != undefined)
      formData.append("need_rollout", "" + need_rollout);
    if (need_dumping != undefined)
      formData.append("need_dumping", "" + need_dumping);
    if (need_rollout != undefined)
      formData.append("need_rollout", "" + need_rollout);
    if (need_packaging != undefined)
      formData.append("need_packaging", "" + need_packaging);
    if (reference != undefined) formData.append("reference", reference);
    if (note != undefined) formData.append("note", note);
    if (ordered_products != undefined)
      formData.append("ordered_products", JSON.stringify(ordered_products));

    if (attachment) {
      formData.append("attachment", attachment);
    }

    return this.httpClient.post(this.ROUTES.store, formData);
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public close(
    id: number,
    closing_date: Date,
    closer_id: number
  ): Observable<any> {
    let data = {
      id: id,
      closing_date: formatDateForBackend(closing_date),
      closer_id: closer_id
    };
    return this.httpClient.post(this.ROUTES.close, { closing_data: data });
  }

  public duplicate(id) {
    return this.httpClient.post(this.ROUTES.duplicate, { id: id });
  }

  public reopen(id) {
    return this.httpClient.post(this.ROUTES.reopen, { id: id });
  }

  public restore(id) {
    return this.httpClient.put(this.ROUTES.restore, { id: id });
  }

  public getDefaultClient(include?: string[]): Observable<any> {
    let params = {};
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.default_client, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public exportOrders(
    order_id?: number,
    client_id?: number,
    supplier_id?: number,
    address_id?: number,
    include_closed?: boolean,
    check_tech?: boolean,
    check_commercial?: boolean,
    check_logistic?: boolean,
    check_plant?: boolean,
    urgent?: boolean,
    on_plan?: boolean,
    include_planned?: boolean,
    include_trashed?: boolean,
    types?: string[],
    search_query?: string
  ): Observable<Blob> {
    let params = {};
    if (order_id) params["order_id"] = "" + order_id;
    if (client_id) params["client_id"] = "" + client_id;
    if (supplier_id) params["supplier_id"] = "" + supplier_id;
    if (address_id) params["address_id"] = "" + address_id;
    if (include_closed != undefined && include_closed != null)
      params["include_closed"] = "" + include_closed;
    if (check_tech != undefined) params["check_tech"] = "" + check_tech;
    if (check_commercial != undefined)
      params["check_commercial"] = "" + check_commercial;
    if (check_logistic != undefined)
      params["check_logistic"] = "" + check_logistic;
    if (check_plant != undefined) params["check_plant"] = "" + check_plant;
    if (urgent != undefined) params["urgent"] = "" + urgent;
    if (on_plan != undefined) params["on_plan"] = "" + on_plan;
    if (include_planned != undefined && include_planned != null)
      params["include_planned"] = "" + include_planned;
    if (include_trashed != undefined && include_trashed != null)
      params["include_trashed"] = "" + include_trashed;
    if (types) params["types[]"] = types;
    if (search_query) params["search_query"] = search_query;
    return this.httpClient.get(this.ROUTES.export, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }
}
