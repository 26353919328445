import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';

import { Comment, Order } from '../../../shared/model';
import { AuthService, CommentService } from '../../../shared/services';
import { CommentDetailComponent } from '../comment-detail/comment-detail.component';

@Component({
  selector: "app-comments-list",
  templateUrl: "./comments-list.component.html",
  styleUrls: ["./comments-list.component.scss"]
})
export class CommentsListComponent implements OnInit {
  _order: Order;

  constructor(private dialog: MatDialog, private commentService: CommentService, private authService: AuthService) { }

  ngOnInit() { }

  get currentUser() {
    return this.authService.getCurrentUser();
  }

  get order(): Order {
    return this._order;
  }

  @Input()
  set order(order: Order) {
    this._order = order;
  }

  get comments(): Comment[] {
    return this.order ? this.order.comments : [];
  }

  reLoadComments() {
    this.order.comments = [];
    this.commentService.getComments(0, 9999999, "created_at", "asc", this.order ? this.order.objectId : null, ["author", "sent_to"]).subscribe(result => {
      this.order.comments = result.data;
    });
  }
  addComment() {
    this.openCommentDialog();
  }

  editComment(comment: Comment) {
    this.openCommentDialog(comment);
  }

  getSentToString(comment: Comment) {
    if (comment && comment.sentTo.length > 0) {
      return comment.sentTo.map(user => user.name + " " + user.surname).join(", ");
    }
    return "";
  }

  private openCommentDialog(comment?: Comment) {
    let config: MatDialogConfig = {
      maxWidth: "80%",
      data: {
        comment: comment,
        order: this.order
      }
    };
    let dialogRef = this.dialog.open(CommentDetailComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reLoadComments();
      }
    });
  }
}
