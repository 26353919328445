import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";

import { Order } from "../../../shared/model";
import { OrderService, OrderFilters } from "../../../shared/services";

export class OrderDatasource implements DataSource<Order> {
  private orderSubject = new BehaviorSubject<Order[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private orderService: OrderService) {}

  connect(): Observable<Order[]> {
    return this.orderSubject.asObservable();
  }

  disconnect(): void {
    this.orderSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  loadOrders(
    page: number = 0,
    perPage: number = 10,
    order: string = "date",
    direction: string = "",
    filters: OrderFilters,
    include?: string | string[],
    excluded?: number[]
  ) {
    this.loadingSubject.next(true);

    this.orderService
      .getOrders(
        page,
        perPage,
        order,
        direction,
        filters,
        include,
        excluded
      )
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.orderSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.orderSubject.next([]);
        }
      );
  }
}
