import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CalendarDayViewBeforeRenderEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
  CalendarViewPeriod,
  CalendarWeekViewBeforeRenderEvent,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services';

import { Operator } from '../../../shared/model/operator.model';
import { PlannedDay } from '../../../shared/model/planned-day.model';
import { Vehicle } from '../../../shared/model/vehicle.model';
import { PlanningService } from '../../../shared/services/planning.service';

@Component({
  selector: "app-planning-dashboard",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./planning-dashboard.component.html",
  styleUrls: ["./planning-dashboard.component.scss"]
})
export class PlanningDashboardComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  // Calendar
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  view: CalendarView = CalendarView.Month;
  locale: string = "it";
  viewDate: Date = new Date();
  startdate: Date;
  enddate: Date;
  refresh: Subject<any> = new Subject();

  //year: string = "2018";
  //month: string = "10";
  year = this.datePipe.transform(this.viewDate, "yyyy");
  month = this.datePipe.transform(this.viewDate, "MM");

  events = [];
  period: CalendarViewPeriod;

  allVehicles: Vehicle[] = [];
  allOperators: Operator[] = [];

  constructor(
    private planningService: PlanningService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private authService: AuthService
  ) { }

  beforeViewRender(
    event:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ) {
    this.period = event.period;
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.loadPlanningsCalendar(this.year, this.month);
    this.allVehicles = this.route.snapshot.data.vehicles;
    this.allOperators = this.route.snapshot.data.operators;
  }

  loadPlanningsCalendar(year: string, month: string) {
    this.loadingSubject.next(true);

    // per avere i dati dei giorni precedenti all'inizio mese e successivi alla fine mese allargo il range di date di 10 giorni
    this.startdate = new Date(year + "-" + month + "-01");
    this.enddate = new Date(year + "-" + month + "-28");
    this.startdate.setDate(this.startdate.getDate() - 10);
    this.enddate.setDate(this.enddate.getDate() + 10);
    console.log(this.startdate);
    console.log(this.enddate);

    this.planningService
      .getPlannedDays(1, 999999, null, null, this.startdate, this.enddate, [
        "orders.order",
        "orders.vehicles",
        "orders.operators"
      ])
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(results => {
        this.events = [];
        results.data.forEach(element => {
          this.events.push({
            //id: element.objectId,
            title: "Evento",
            start: new Date(element.date),
            norders: element.orders.length,
            busyoperators: element.getBusyOperators(this.allOperators),
            busyvehicles: element.getBusyVehicles(this.allVehicles),
            dailyplan: element
          });
        });
        //console.log(this.events);
        this.refresh.next();
      });
  }

  monthChange() {
    console.log(this.datePipe.transform(this.viewDate, "yyyy-MM-dd"));
    this.year = this.datePipe.transform(this.viewDate, "yyyy");
    this.month = this.datePipe.transform(this.viewDate, "MM");
    this.loadPlanningsCalendar(this.year, this.month);
  }

  onDayClick(day: any) {
    console.log("Day clicked", day); //.events[0]?.dailyplan
    if (day.events && day.events.length > 0) {
      this.viewDailyPlan(day.events[0].dailyplan);
    } else {
      this.authService.isAdmin().pipe(take(1)).subscribe(admin => {
        if (admin) {
          this.addDailyPlan(day.date);
        } else {
          this.viewDailyPlan(day.date);
        }
      })
    }
  }

  addDailyPlan(selectedDay: Date) {
    this.router.navigate(
      ["dailyPlan", moment(selectedDay).format("YYYY-MM-DD")],
      {
        relativeTo: this.route
      }
    );
  }

  editDailyPlan(plannedDay: PlannedDay) {
    this.router.navigate(["dailyPlan", plannedDay.objectId], {
      relativeTo: this.route
    });
  }

  viewDailyPlan(plannedDay?: PlannedDay) {
    this.router.navigate(
      [
        "dailyView",
        moment(plannedDay ? plannedDay.date : new Date()).format("YYYY-MM-DD")
      ],
      {
        relativeTo: this.route
      }
    );
  }
}
