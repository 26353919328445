import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Anagraphic } from 'src/app/shared/model';
import { AnagraphicService } from 'src/app/shared/services';

import { ORDER_TYPE } from './../../model/order.model';
import { OrderFilters } from './../../services/order.service';

@Component({
  selector: "app-orders-filter",
  templateUrl: "./orders-filter.component.html",
  styleUrls: ["./orders-filter.component.scss"]
})
export class OrdersFilterComponent implements OnInit {
  orderTypes = ORDER_TYPE;

  filtersForm: FormGroup;

  _client: Anagraphic;
  _supplier: Anagraphic;
  linkedAnagraphics: Anagraphic[] = [];

  clientSearchResults = [];
  supplierSearchResults = [];
  searchResultMaxLength = 15;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private searchResultLength = new BehaviorSubject<number>(-1);
  public searchResultLength$ = this.searchResultLength.asObservable();

  private searchingClient = new BehaviorSubject<boolean>(false);
  public searchingClient$ = this.searchingClient.asObservable();

  private searchSupplierResultLength = new BehaviorSubject<number>(-1);
  public searchSupplierResultLength$ = this.searchSupplierResultLength.asObservable();

  private searchingSupplier = new BehaviorSubject<boolean>(false);
  public searchingSupplier$ = this.searchingSupplier.asObservable();

  filteredAddresses: Observable<Anagraphic[]>;

  @Input()
  showTrashedOption: boolean = false;

  @Output()
  onFilter: EventEmitter<any> = new EventEmitter();

  _defaultFilters: OrderFilters;

  constructor(
    private fb: FormBuilder,
    private anagraphicService: AnagraphicService
  ) { }

  ngOnInit() {
    this.createFiltersForm();
    this.setDefaultValues();
  }

  private createFiltersForm() {
    let group = {
      orderId: [""],
      client: [""],
      supplier: [""],
      address: [""],
      includeClosed: [""],
      includePlanned: [""],
      includeTrashed: [""],
      checkTech: "",
      checkCommercial: "",
      checkLogistic: "",
      checkPlant: "",
      urgent: "",
      onPlan: "",
      types: "",
      searchQuery: "",
      destination: ""
    };

    this.filtersForm = this.fb.group(group);

    this.filtersForm.controls["client"].valueChanges.subscribe(client => {
      this.clientSearchResults = [];
      if (client && client instanceof Anagraphic) {
        this.client = client;
      }
    });

    this.filtersForm.controls["supplier"].valueChanges.subscribe(supplier => {
      this.supplierSearchResults = [];
      if (supplier && supplier instanceof Anagraphic) {
        this.supplier = supplier;
      }
    });

    this.filteredAddresses = this.filtersForm.controls[
      "address"
    ].valueChanges.pipe(
      startWith<string | Anagraphic>(""),
      map(anagraphic =>
        typeof anagraphic === "string"
          ? anagraphic
          : anagraphic
            ? anagraphic.completeAddress
            : ""
      ),
      map(value => this._filterAddress(value))
    );

    this.filtersForm.controls["client"].valueChanges
      .pipe(
        debounceTime(250),
        tap(() => this.searchingClient.next(true)),
        distinctUntilChanged(),
        switchMap(data => {
          if (data && typeof data === "string") {
            return this.anagraphicService
              .getAnagraphics(
                1,
                this.searchResultMaxLength,
                "name",
                "asc",
                data
              )
              .pipe(finalize(() => this.searchingClient.next(false)));
          }
          return of(null).pipe(
            finalize(() => this.searchingClient.next(false))
          );
        })
      )
      .subscribe(result => {
        if (result) {
          this.clientSearchResults = result.data;
          this.searchResultLength.next(result.total);
        }
      });

    this.filtersForm.controls["supplier"].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(data => {
          this.searchingSupplier.next(true);
          if (data && typeof data === "string") {
            return this.anagraphicService
              .getAnagraphics(
                1,
                this.searchResultMaxLength,
                "name",
                "asc",
                data
              )
              .pipe(finalize(() => this.searchingSupplier.next(false)));
          } else {
            return of(null).pipe(
              finalize(() => this.searchingSupplier.next(false))
            );
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.supplierSearchResults = result.data;
          this.searchSupplierResultLength.next(result.total);
        }
      });
  }

  private _filterAddress(value: string): Anagraphic[] {
    const filterValue = value.toLowerCase();

    return this.linkedAnagraphics.filter(
      anagraphic =>
        anagraphic.completeAddress.toLowerCase().indexOf(filterValue) != -1
    );
  }

  compareAnagraphic(a1: Anagraphic, a2: Anagraphic) {
    return a1 && a2 ? a1.objectId === a2.objectId : a1 === a2;
  }

  applyFilters() {
    this.onFilter.emit(this.getOrderFilters());
  }

  private getOrderFilters(): OrderFilters {
    let filters: OrderFilters = {};
    if (this.filtersForm) {
      let values = this.filtersForm.value;
      filters.orderId = values.orderId;
      filters.clientId = values.client ? values.client.objectId : null;
      filters.supplierId = values.supplier ? values.supplier.objectId : null;
      filters.addressId = values.address ? values.address.objectId : null;
      filters.includeClosed = values.includeClosed;
      filters.includePlanned = values.includePlanned;
      filters.includeTrashed = values.includeTrashed;
      filters.checkTech = values.checkTech;
      filters.checkCommercial = values.checkCommercial;
      filters.checkLogistic = values.checkLogistic;
      filters.checkPlant = values.checkPlant;
      filters.urgent = values.urgent;
      filters.onPlan = values.onPlan;
      filters.types = values.types;
      filters.searchQuery = values.searchQuery;
      filters.destination = values.destination;
    }

    return filters;
  }

  private resolveClientAddresses(): Observable<Anagraphic[]> {
    this.linkedAnagraphics = [];
    if (this.client) {
      //this.anagraphicService.getLinkedAnagraphics(this.client).subscribe(linkedAnagraphics => (this.linkedAnagraphics = linkedAnagraphics));
      return this.anagraphicService.getLinkedAnagraphics(this.client);
    } else {
      return of(null);
    }
  }

  private resolveSupplierAddresses(): Observable<Anagraphic[]> {
    this.linkedAnagraphics = [];
    if (this.supplier) {
      return this.anagraphicService.getLinkedAnagraphics(this.supplier);
    } else {
      return of(null);
    }
  }

  displayAnagraphic(anagraphic?: Anagraphic): string | undefined {
    return anagraphic ? anagraphic.name : undefined;
  }

  displayAddress(anagraphic?: Anagraphic): string | undefined {
    return anagraphic ? anagraphic.completeAddress : "";
  }

  get client(): Anagraphic {
    return this._client;
  }

  set client(client: Anagraphic) {
    this._client = client;
    this.loadingSubject.next(true);
    this.resolveClientAddresses()
      .pipe(
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe(results => {
        this.linkedAnagraphics = results;
        //mrosetti - l'aggiornamento dei prodotti è gestito nella resolveProducts;
      });
  }

  get supplier(): Anagraphic {
    return this._supplier;
  }

  set supplier(supplier: Anagraphic) {
    this._supplier = supplier;
    this.loadingSubject.next(true);
    this.resolveSupplierAddresses()
      .pipe(
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe(results => {
        this.linkedAnagraphics = results;
      });
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      includeClosed: "",
      includePlanned: "",
      checkTech: "",
      checkCommercial: "",
      checkLogistic: "",
      checkPlant: "",
      urgent: "",
      onPlan: "",
      type: "",
      searchQuery: "",
      destination: ""
    });
    this.setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): OrderFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: OrderFilters) {
    this._defaultFilters = defaultFilters;
    this.setDefaultValues();
  }

  private setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          // mrosetti - Questi campi andrebbero gestiti meglio, ricaricando l'intera entità se è valorizzata
          // client: this.defaultFilters.clientId,
          // supplier: this.defaultFilters.supplierId,
          // address: this.defaultFilters.addressId,
          includeClosed:
            this.defaultFilters.includeClosed != undefined
              ? this.defaultFilters.includeClosed
              : "",
          includePlanned:
            this.defaultFilters.includePlanned != undefined
              ? this.defaultFilters.includePlanned
              : "",
          checkTech:
            this.defaultFilters.checkTech != undefined
              ? this.defaultFilters.checkTech
              : "",
          checkCommercial:
            this.defaultFilters.checkCommercial != undefined
              ? this.defaultFilters.checkCommercial
              : "",
          checkLogistic:
            this.defaultFilters.checkLogistic != undefined
              ? this.defaultFilters.checkLogistic
              : "",
          checkPlant:
            this.defaultFilters.checkPlant != undefined
              ? this.defaultFilters.checkPlant
              : "",
          urgent:
            this.defaultFilters.urgent != undefined
              ? this.defaultFilters.urgent
              : "",
          onPlan:
            this.defaultFilters.onPlan != undefined
              ? this.defaultFilters.onPlan
              : "",
          types:
            this.defaultFilters.types != undefined
              ? this.defaultFilters.types
              : "",
          searchQuery:
            this.defaultFilters.searchQuery != undefined
              ? this.defaultFilters.searchQuery
              : "",
          destination:
            this.defaultFilters.destination != undefined
              ? this.defaultFilters.destination
              : ""
        });
      }
    }
  }
}