import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import {
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
  CalendarViewPeriod,
  CalendarWeekViewBeforeRenderEvent,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';
import { DailyReport } from 'src/app/shared/model';
import { AlertService, TodoService } from 'src/app/shared/services';

import { ReportDayComponent } from '../report-day/report-day.component';

@Component({
  selector: 'app-report-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './report-calendar.component.html',
  styleUrls: ['./report-calendar.component.scss']
})
export class ReportCalendarComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  @ViewChild("downloadCSVLink")
  private downloadCSVLink: ElementRef;

  private generatingCSV = new BehaviorSubject<boolean>(false);
  public generatingCSV$ = this.generatingCSV.asObservable();

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  private period$: BehaviorSubject<CalendarViewPeriod> = new BehaviorSubject<
    CalendarViewPeriod
  >(null);

  dailyReports$: BehaviorSubject<
    CalendarEvent<DailyReport>[]
  > = new BehaviorSubject<CalendarEvent<DailyReport>[]>([]);

  viewDate: Date = new Date();

  constructor(private todoService: TodoService, private cdr: ChangeDetectorRef, private dialog: MatDialog, private alertService: AlertService, private route: ActivatedRoute) {

    this.route.queryParams.subscribe(({ date }) => {
      if (date) {
        this.dayClick(date);
      }
    })
    this.period$
      .pipe(

        distinctUntilChanged(
          (p1: CalendarViewPeriod, p2: CalendarViewPeriod) => {
            return (
              p1 != null &&
              p2 != null &&
              (moment(p1.start).isSameOrBefore(p2.start, "day") &&
                moment(p1.end).isSameOrAfter(p2.end, "day"))
            );
          }
        ),
        filter(p => p != null),
        map((period) => {
          return {
            from: moment(period.start).startOf('day').toDate(),
            to: moment(period.end).endOf('day').toDate()
          }
        }),
      )
      .subscribe(({ from, to }) => {
        this.loadReports(from, to);
      });
  }

  beforeViewRender(
    event:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ) {
    this.period$.next(event.period);
    this.cdr.detectChanges();
  }

  ngOnInit() {
  }

  loadReports(from: Date, to: Date) {
    this.loadingSubject.next(true);
    this.todoService.getDailyReports(from, to).pipe(
      finalize(() => this.loadingSubject.next(false)),
      map(reports => reports.map(report => {
        let calendarEvent: CalendarEvent = {
          title: `${report.total}`,
          start: new Date(report.day),
          end: new Date(report.day),
          meta: report
        };
        return calendarEvent;
      }))
    ).subscribe(reports => {
      this.dailyReports$.next(reports);
    })
  }

  dayClick(date: Date) {
    let data = {
      date: date
    };

    this.dialog.open(ReportDayComponent, {
      data,
      width: "90%",
    });
  }

  exportCSV() {
    this.generatingCSV.next(true);
    const monthStart = moment(this.viewDate).startOf('month').toDate();
    const monthEnd = moment(this.viewDate).endOf('month').toDate();
    let filename = `activities_${moment(monthStart).format('YYYYMMDD')}_${moment(monthEnd).format('YYYYMMDD')}.csv`;

    this.todoService.exportDailyActivities({ from: monthStart, to: monthEnd }).pipe(finalize(() => this.generatingCSV.next(false))).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = this.downloadCSVLink.nativeElement;
      link.href = url;
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(url);
      this.alertService.showConfirmMessage('Esportazione mensile generata con successo');
    }, error => {
      this.alertService.showErrorMessage('Errore nell\'esportazione mensile', error);
    });
  }

}
