import { Component, OnInit, Input } from "@angular/core";
import { Order } from "../../model";
import * as moment from "moment";

@Component({
  selector: "app-order-status-indicator",
  templateUrl: "./order-status-indicator.component.html",
  styleUrls: ["./order-status-indicator.component.scss"]
})
export class OrderStatusIndicatorComponent implements OnInit {
  constructor() { }

  ngOnInit() { }

  _order: Order;

  public get order(): Order {
    return this._order;
  }

  @Input()
  public set order(order: Order) {
    this._order = order;
  }

  get statusLabel() {
    switch (this.order.status) {
      case "closed":
        return "Evaso";
      case "created":
        return "Creato";
      case "planned":
        return "Pianificato";
      case "validated":
        return "Validato";

      default:
        return this.order.status;
    }
  }

  get tooltip() {
    switch (this.order.status) {
      case "planned":
        return `Pianificato il ${this.order.getPlannedTooltip()}`;
      case "closed":
        return `Evaso il ${moment(this.order.closedAt).format("DD-MM-YYYY")}`

      default:
        return "";
    }
  }
}
