import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTooltipModule,
} from '@angular/material';
import { VehicleInListModule } from 'src/app/shared/modules/vehicle-in-list/vehicle-in-list.module';

import { VehicleSelectionComponent } from './vehicle-selection.component';

@NgModule({
  declarations: [VehicleSelectionComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    VehicleInListModule,
    ReactiveFormsModule
  ],
  exports: [VehicleSelectionComponent],
  entryComponents: [VehicleSelectionComponent]
})
export class VehicleSelectionModule { }
