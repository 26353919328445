import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Observable, of } from "rxjs";

import { Order } from "./../../../shared/model/order.model";
import { OrderService } from "./../../../shared/services/order.service";

@Injectable()
export class OrderResolverService implements Resolve<Order> {
  constructor(private orderService: OrderService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Order> {
    let orderId = route.params["orderId"];
    if (orderId) {
      return this.orderService.getOrder(orderId, [
        "client",
        "supplier",
        "planned_orders.planned_day"
      ]);
    } else {
      return of(null);
    }
  }
}
