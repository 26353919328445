import { DataSource } from '@angular/cdk/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { User } from 'src/app/shared/model';
import { TodoService } from 'src/app/shared/services';

import { Todo, TodoFilters } from './../../../shared/model/activity.model';
import { TodoDetailComponent } from './../todo-detail/todo-detail.component';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {
  showFilters: boolean = false;
  defaultFilters: TodoFilters = {};

  dataSource: TodoDatasource;
  displayedColumns = [
    "assigner",
    "executor",
    "expirationDate",
    "activity",
    "area",
    "type",
    "executionDate",
    "status",
  ];
  pageSizeOptions: number[] = [100, 200, 500];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  users: User[];

  private filtersSubject: BehaviorSubject<TodoFilters> = new BehaviorSubject<TodoFilters>(null);

  constructor(private todoService: TodoService, private route: ActivatedRoute, private dialog: MatDialog) {
    this.users = this.route.snapshot.data.users || [];
  }

  ngOnInit() {
    this.dataSource = new TodoDatasource(this.todoService);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filtersSubject)
      .pipe(tap(() => this.loadTodos()))
      .subscribe();

    this.filtersSubject.next(this.defaultFilters);
  }

  loadTodos() {
    this.filtersSubject.pipe(take(1)).subscribe(filters => {
      this.dataSource.loadTodos(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize | this.pageSizeOptions[0],
        this.sort.active,
        this.sort.direction,
        filters,
        ["activity.assigner", "activity.substitute", "executor"]
      );
    });
  }

  onFilter(filters: TodoFilters) {
    this.filtersSubject.next(filters);
  }

  editTodo(todo: Todo) {
    let data = {
      todo: todo
    };

    let dialogRef = this.dialog.open(TodoDetailComponent, {
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadTodos();
      }
    });
  }

}

class TodoDatasource implements DataSource<Todo> {
  private dataSubject = new BehaviorSubject<Todo[]>([]);

  private totalSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public total$ = this.totalSubject.asObservable();

  constructor(private todoService: TodoService) { }

  connect(): Observable<Todo[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.totalSubject.complete();
  }

  loadTodos(
    page: number = 0,
    perPage: number = 10,
    order: string = "execution_date",
    direction: string = "desc",
    filters: TodoFilters,
    include?: string | string[]
  ) {
    this.loadingSubject.next(true);

    this.todoService
      .getTodos(
        page,
        perPage,
        order,
        direction,
        filters,
        include
      )
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        result => {
          this.totalSubject.next(result.total);
          this.dataSubject.next(result.data);
        },
        error => {
          console.error("Error", error);
          this.totalSubject.next(0);
          this.dataSubject.next([]);
        }
      );
  }
}