import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../helpers/util';

@Injectable()
export class LaravelOperatorService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/operator",
      list: environment.baseUrl + "/api/operators",
      store: environment.baseUrl + "/api/operator",
      update_availability: environment.baseUrl + "/api/operator/update_availability",
      archive: environment.baseUrl + "/api/operator/archive",
      delete: environment.baseUrl + "/api/operator"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    archived: boolean,
    include_generics: boolean
  ): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (archived) params["archived"] = "" + archived;
    if (include_generics) params["include_generics"] = "" + include_generics;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(
    name: string,
    surname: string,
    patente_C: boolean = false,
    patente_E: boolean = false,
    ADR: boolean = false,
    preposto: boolean = false,
    autospurgo: boolean = false,
    ragno: boolean = false,
    gru: boolean = false,
    spazi_confinati: boolean = false,
    pictureUrl?: string,
    category?: string
  ) {
    let operator = {
      name: name,
      surname: surname,
      patente_C: patente_C,
      patente_E: patente_E,
      ADR: ADR,
      preposto: preposto,
      autospurgo: autospurgo,
      ragno: ragno,
      gru: gru,
      spazi_confinati: spazi_confinati,
      pictureUrl: pictureUrl,
      category: category,
    };
    return this.httpClient.post(this.ROUTES.store, { operator: operator });
  }

  public update(
    id: number,
    name: string,
    surname: string,
    patente_C: boolean = false,
    patente_E: boolean = false,
    ADR: boolean = false,
    preposto: boolean = false,
    autospurgo: boolean = false,
    ragno: boolean = false,
    gru: boolean = false,
    spazi_confinati: boolean = false,
    pictureUrl?: string,
    category?: string,
  ) {
    let operator = {
      id: id,
      name: name,
      surname: surname,
      patente_C: patente_C,
      patente_E: patente_E,
      ADR: ADR,
      preposto: preposto,
      autospurgo: autospurgo,
      ragno: ragno,
      gru: gru,
      spazi_confinati: spazi_confinati,
      pictureUrl: pictureUrl,
      category: category,
    };
    return this.httpClient.put(this.ROUTES.store, { operator: operator });
  }

  public updateUnavailability(id: number, start: Date, end?: Date, note?: string) {
    let availability = {
      id: id,
      start_date: formatDateForBackend(start),
      end_date: formatDateForBackend(end),
      note: note
    };
    return this.httpClient.put(this.ROUTES.update_availability, {
      availability: availability
    });
  }

  public archive(id) {
    return this.httpClient.put(this.ROUTES.archive, { id: id });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
