import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Operator } from 'src/app/shared/model';

@Component({
  selector: 'app-operator-activities',
  templateUrl: './operator-activities.component.html',
  styleUrls: ['./operator-activities.component.scss']
})
export class OperatorActivitiesComponent implements OnInit {

  operator: Operator;
  start: Date;
  end: Date;

  constructor(
    public dialogRef: MatDialogRef<OperatorActivitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.operator = this.data.operator;
      this.start = this.data.start;
      this.end = this.data.end;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
