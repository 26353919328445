import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ListResult } from '../helpers/listResult.interface';
import { Anagraphic, Order, OrderedProduct } from '../model';
import { AuthService } from './auth.service';
import { LaravelOrderedProductService, LaravelOrderService } from './laravel';

@Injectable()
export class OrderService {
  constructor(
    private laravelOrderService: LaravelOrderService,
    private laravelOrderedProductService: LaravelOrderedProductService,
    private authService: AuthService
  ) { }

  public getOrder(id: number, include?: string | string[]): Observable<Order> {
    return this.laravelOrderService
      .getById(id, typeof include === "string" ? [include] : include)
      .pipe(
        map(response => {
          return new Order(response);
        })
      );
  }

  public getOrders(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    filters?: OrderFilters,
    include?: string | string[],
    excluded?: number | number[]
  ): Observable<ListResult<Order>> {
    return this.laravelOrderService
      .list(
        page,
        perPage,
        order,
        direction,
        filters ? filters.orderId : null,
        filters ? filters.clientId : null,
        filters ? filters.supplierId : null,
        filters ? filters.addressId : null,
        filters ? filters.includeClosed : false,
        filters ? filters.checkTech : null,
        filters ? filters.checkCommercial : null,
        filters ? filters.checkLogistic : null,
        filters ? filters.checkPlant : null,
        filters ? filters.urgent : null,
        filters ? filters.onPlan : null,
        filters ? filters.includePlanned : null,
        filters ? filters.includeTrashed : null,
        filters ? filters.types : null,
        filters ? filters.searchQuery : null,
        filters ? filters.destination : null,
        typeof include === "string" ? [include] : include,
        typeof excluded === "number" ? [excluded] : excluded
      )
      .pipe(
        map(response => {
          let data = new Array<Order>();
          let total = 0;
          if (perPage) {
            response.data.forEach(element => {
              data.push(new Order(element));
            });
            total = response.total;
          } else {
            response.forEach(element => {
              data.push(new Order(element));
            });
            total = response.length;
          }
          return { data: data, total: total };
        })
      );
  }

  public registerOrder(order: Order): Observable<Order> {
    //TODO mrosetti - Find a way to translate this in a more functional approach, without 'new Observable' and 'subscribe' instructions
    return new Observable<Order>(subscriber => {
      this.createOrder(order).subscribe(
        createdOrder => {
          if (order.orderedProducts) {
            let observableBatch: Observable<OrderedProduct>[] = [];
            order.orderedProducts.forEach(orderedProduct => {
              orderedProduct.order = createdOrder;
              observableBatch.push(this.createOrderedProduct(orderedProduct));
            });
            forkJoin(observableBatch).subscribe(
              products => {
                createdOrder.orderedProducts = products;
                subscriber.next(createdOrder);
                subscriber.complete();
              },
              error => {
                subscriber.error(error);
                subscriber.complete();
              }
            );
          } else {
            subscriber.next(createdOrder);
            subscriber.complete();
          }
        },
        error => {
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  public updateOrder(order: Order): Observable<Order> {
    return this.laravelOrderService
      .update(
        order.objectId,
        order.status,
        order.client ? order.client.objectId : null,
        order.supplier ? order.supplier.objectId : null,
        order.type,
        order.date,
        order.urgent,
        order.onPlan,
        order.checkTech,
        order.checkCommercial,
        order.checkLogistic,
        order.checkPlant,
        order.needFormulary,
        order.needTransport,
        order.needDumping,
        order.needRollout,
        order.needPackaging,
        order.attachmentFile,
        order.reference,
        order.note,
        this.formatOrderedProductsForBackend(order.orderedProducts)
      )
      .pipe(
        map(response => {
          return new Order(response);
        })
      );
  }

  public updateOrderedProduct(
    orderedProduct: OrderedProduct
  ): Observable<OrderedProduct> {
    return this.laravelOrderedProductService
      .update(
        orderedProduct.objectId,
        orderedProduct.product.objectId,
        orderedProduct.order.objectId,
        orderedProduct.supposedQuantity,
        orderedProduct.tech_note,
        orderedProduct.comm_note,
        orderedProduct.plant_note
      )
      .pipe(
        map(response => {
          return new OrderedProduct(response);
        })
      );
  }

  public deleteOrder(order: Order): Observable<Order> {
    return this.laravelOrderService.delete(order.objectId).pipe(
      map(result => {
        return new Order(result);
      })
    );
  }

  public deleteOrderedProduct(
    orderedProduct: OrderedProduct
  ): Observable<OrderedProduct> {
    return this.laravelOrderedProductService
      .delete(orderedProduct.objectId)
      .pipe(
        map(result => {
          return new OrderedProduct(result);
        })
      );
  }

  public getOrderedProduct(
    id: number,
    include?: string | string[]
  ): Observable<OrderedProduct> {
    return this.laravelOrderedProductService
      .getById(id, typeof include === "string" ? [include] : include)
      .pipe(
        map(response => {
          return new OrderedProduct(response);
        })
      );
  }

  public createOrder(order: Order): Observable<Order> {
    return this.laravelOrderService
      .create(
        order.status,
        order.client ? order.client.objectId : null,
        order.supplier ? order.supplier.objectId : null,
        order.type,
        order.date,
        order.urgent,
        order.onPlan,
        order.checkTech,
        order.checkCommercial,
        order.checkLogistic,
        order.checkPlant,
        order.needFormulary,
        order.needTransport,
        order.needDumping,
        order.needRollout,
        order.needPackaging,
        order.attachmentFile,
        order.reference,
        order.note,
        this.formatOrderedProductsForBackend(order.orderedProducts)
      )
      .pipe(
        map(response => {
          return new Order(response);
        })
      );
  }

  public createOrderedProduct(
    orderedProduct: OrderedProduct
  ): Observable<OrderedProduct> {
    return this.laravelOrderedProductService
      .create(
        orderedProduct.product.objectId,
        orderedProduct.order.objectId,
        orderedProduct.supposedQuantity,
        orderedProduct.tech_note,
        orderedProduct.comm_note,
        orderedProduct.plant_note
      )
      .pipe(
        map(response => {
          return new OrderedProduct(response);
        })
      );
  }

  public closeOrder(
    orderId: number,
    closingDate: Date = new Date()
  ): Observable<Order> {
    return this.authService.getCurrentUser().pipe(
      switchMap(currentUser => {
        return this.laravelOrderService
          .close(
            orderId,
            closingDate,
            currentUser.objectId
          )
          .pipe(
            map(response => {
              return new Order(response);
            })
          );
      })
    )
  }

  public duplicate(order: Order): Observable<Order> {
    return this.laravelOrderService.duplicate(order.objectId).pipe(
      switchMap(result => {
        let createdOrder = new Order(result);
        return this.getOrder(createdOrder.objectId, "client");
      })
    );
  }

  private formatOrderedProductsForBackend(orderedProducts: OrderedProduct[]) {
    let result = [];
    if (orderedProducts) {
      orderedProducts.forEach(orderedProduct => {
        let ordered_product = {
          id: orderedProduct.objectId,
          product_id: orderedProduct.product.objectId,
          order_id: orderedProduct.order.objectId,
          supposed_quantity: orderedProduct.supposedQuantity,
          tech_note: orderedProduct.tech_note,
          comm_note: orderedProduct.comm_note,
          plant_note: orderedProduct.plant_note,
          done: orderedProduct.done,
          destination: orderedProduct.destination
        };
        result.push(ordered_product);
      });
    }
    return result;
  }

  public downloadPDF(order: Order): Observable<Blob> {
    return this.laravelOrderService
      .downloadPdf(order.objectId)
      .pipe(map(data => new Blob([data], { type: "application/pdf" })));
  }

  public reopenOrder(order: Order): Observable<Order> {
    return this.laravelOrderService.reopen(order.objectId).pipe(
      map(response => {
        return new Order(response);
      })
    );
  }

  public restoreOrder(order: Order): Observable<Order> {
    return this.laravelOrderService
      .restore(order.objectId)
      .pipe(map(response => new Order(response)));
  }

  public getDefaultClient(include?: string | string[]): Observable<Anagraphic> {
    return this.laravelOrderService
      .getDefaultClient(typeof include === "string" ? [include] : include)
      .pipe(
        map(response => {
          return new Anagraphic(response);
        })
      );
  }

  public exportOrders(
    filters?: OrderFilters
  ): Observable<Blob> {
    return this.laravelOrderService.exportOrders(
      filters ? filters.orderId : null,
      filters ? filters.clientId : null,
      filters ? filters.supplierId : null,
      filters ? filters.addressId : null,
      filters ? filters.includeClosed : false,
      filters ? filters.checkTech : null,
      filters ? filters.checkCommercial : null,
      filters ? filters.checkLogistic : null,
      filters ? filters.checkPlant : null,
      filters ? filters.urgent : null,
      filters ? filters.onPlan : null,
      filters ? filters.includePlanned : null,
      filters ? filters.includeTrashed : null,
      filters ? filters.types : null,
      filters ? filters.searchQuery : null
    )
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }
}

export interface OrderFilters {
  orderId?: number;
  clientId?: number;
  supplierId?: number;
  addressId?: number;
  includeClosed?: boolean;
  includePlanned?: boolean;
  includeTrashed?: boolean;
  checkTech?: boolean;
  checkCommercial?: boolean;
  checkLogistic?: boolean;
  checkPlant?: boolean;
  urgent?: boolean;
  onPlan?: boolean;
  types?: string[];
  searchQuery?: string;
  destination?: string;
}
