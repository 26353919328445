import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../helpers/util';

@Injectable()
export class LaravelPlannedDayService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/plannedDay",
      downloadPdf: environment.baseUrl + "/api/plannedDay/download_PDF",
      downloadCsv: environment.baseUrl + "/api/plannedDay/download_CSV",
      checkUpdated: environment.baseUrl + "/api/plannedDay/check_updated",
      list: environment.baseUrl + "/api/plannedDays",
      compiled_dates: environment.baseUrl + "/api/plannedDays/compiled_dates",
      store: environment.baseUrl + "/api/plannedDay",
      delete: environment.baseUrl + "/api/plannedDay"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = {
      id: "" + id
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public downloadPdf(id: number, operator_id?: number, vehicle_id?: number, client_id?: number): Observable<Blob> {
    let params = {
      id: "" + id
    };
    if (operator_id) params["operator_id"] = "" + operator_id;
    if (vehicle_id) params["vehicle_id"] = "" + vehicle_id;
    if (client_id) params["client_id"] = "" + client_id;
    return this.httpClient.get(this.ROUTES.downloadPdf, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }

  public getCompiledDates(start_date?: Date, end_date?: Date): Observable<any> {
    let params = {};
    if (start_date) params["start_date"] = formatDateForBackend(start_date);
    if (end_date) params["end_date"] = formatDateForBackend(end_date);
    return this.httpClient.get(this.ROUTES.compiled_dates, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, start_date: Date, end_date: Date, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (start_date) params["start_date"] = formatDateForBackend(start_date);
    if (end_date) params["end_date"] = formatDateForBackend(end_date);
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(date: Date, note: string, planned_orders: Array<any>) {
    let planned_day = {
      date: formatDateForBackend(date),
      note: note,
      planned_orders: planned_orders
    };
    return this.httpClient.post(this.ROUTES.store, {
      planned_day: planned_day
    });
  }

  public update(id: number, date: Date, note: string, planned_orders: Array<any>) {
    let planned_day = {
      id: id,
      date: formatDateForBackend(date),
      note: note,
      planned_orders: planned_orders
    };
    return this.httpClient.put(this.ROUTES.store, {
      planned_day: planned_day
    });
  }

  public getUpdatedPlannedDay(date: Date, updateDate: Date, include?: string[]) {
    let params = {
      date: formatDateForBackend(date, true),
      update_date: formatDateForBackend(updateDate, true)
    };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.checkUpdated, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public export(date: Date): Observable<Blob> {
    let params = {};
    if (date) params["start_date"] = formatDateForBackend(date);
    if (date) params["end_date"] = formatDateForBackend(date);

    return this.httpClient.get(this.ROUTES.downloadCsv, {
      params: new HttpParams({ fromObject: params }),
      responseType: "blob",
    });
  }
}
