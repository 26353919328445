import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Vehicle } from '../../model';

@Component({
  selector: 'app-vehicle-selection',
  templateUrl: './vehicle-selection.component.html',
  styleUrls: ['./vehicle-selection.component.scss']
})
export class VehicleSelectionComponent implements OnInit {

  all: Vehicle[];
  busy: Vehicle[];
  selectedDate: Date;

  search: FormControl = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<VehicleSelectionComponent>) { }

  ngOnInit() {
    if (this.data) {
      this.all = this.data.allVehicles;
      this.busy = this.data.busyVehicles;
      this.selectedDate = this.data.selectedDate;
    }
    this.search.valueChanges.subscribe((value) => {
      console.log('Valore search', value);
    })
  }

  getMapKeys(map: Map<string, any>): string[] {
    return Array.from(map.keys());
  }

  get filteredVehicles() {
    return this._filterVehicle(
      this.search.value,
      this.all
    );
  }

  private _filterVehicle(
    value: string | Vehicle,
    vehicles: Vehicle[]
  ): Vehicle[] {
    if (value instanceof Vehicle) {
      return [value];
    }

    if (!value) {
      return vehicles;
    }

    const filterValue = value.toLowerCase();
    return vehicles.filter(vehicle =>
      vehicle.license
        ? vehicle.license.toLowerCase().indexOf(filterValue) > -1
        : false
    );
  }

  get allVehicles(): Map<string, Vehicle[]> {
    return this.groupVehiclesByType(this.all);
  }

  filterAvailable(vehicles: Vehicle[]): Vehicle[] {
    return vehicles
      ? vehicles.filter(v => !this.busy.find(b => Vehicle.compare(b, v)))
      : [];
  }

  filterBusy(vehicles: Vehicle[]): Vehicle[] {
    return vehicles
      ? vehicles.filter(v => this.busy.find(b => Vehicle.compare(b, v)))
      : [];
  }

  private sortOrder = [
    "trattore",
    "trattore_no_pompa",
    "semirimorchio_cisternato",
    "semirimorchio_pianale",
    "semirimorchio_vasca",
    "autospurgo",
    "rimorchio_cisternato",
    "rimorchio_pianale",
    "rimorchio_scarrabile",
    "autocarro_pianale_gru",
    "autocarro_scarrabile",
    "autocarro_pianale_gru",
    "autocarro_pulmino"
  ];

  private groupVehiclesByType(vehicles: Vehicle[]): Map<string, Vehicle[]> {
    let map: Map<string, Vehicle[]> = new Map<string, Vehicle[]>();
    this.sortOrder.forEach(key => {
      map.set(key, []);
    });

    if (vehicles) {
      vehicles.filter(v => !v.generic).forEach(vehicle => {
        let array = map.get(vehicle.type) || [];
        array.push(vehicle);
        map.set(vehicle.type, array);
      });
    }

    return map;
  }

  typeIcon(type: string) {
    return Vehicle.resolveTypeIcon(type);
  }

  typeLabel(type: string) {
    return Vehicle.resolveTypeLabel(type);
  }

  selectVehicle(vehicle: Vehicle) {
    this.dialogRef.close(vehicle);
  }

  selectGenericVehicle(type: string) {
    this.selectVehicle(this.all.filter(v => v.generic).find(v => v.type == type));
  }
}
