import { Resource } from "./resource.model";
import { FormGroup } from "@angular/forms";

export enum VEHICLE_TYPES {
  autocarro_pianale_gru = "Autocarro pianale con gru",
  autocarro_pulmino = "Autocarro pulmino",
  autocarro_scarrabile = "Autocarro scarrabile",
  autospurgo = "Autospurgo",
  rimorchio_scarrabile = "Rimorchio scarrabile",
  rimorchio_pianale = "Rimorchio pianale",
  rimorchio_cisternato = "Rimorchio cisternato",
  semirimorchio_cisternato = "Semirimorchio cisternato",
  semirimorchio_pianale = "Semirimorchio pianale",
  semirimorchio_vasca = "Semirimorchio vasca",
  trattore = "Trattore",
  trattore_no_pompa = "Trattore no pompa cisterna"
}

export class Vehicle extends Resource {
  license: string;
  equipment: string;
  type: string;
  description?: string;
  manufacturer?: string;
  ADR?: boolean = false;
  water?: boolean = false;
  tankCode?: string;
  tankDescription?: string;
  weightNet?: number;
  weightGross?: number;
  registration?: Date;
  acqusition?: Date;
  availability?: string;
  dismission?: Date;

  archived?: boolean = false;
  constructor(source: any) {
    super(source);
    if (source) {
      this.license = source.license;
      this.equipment = source.equipment;
      this.type = source.type;
      this.description = source.description;
      this.manufacturer = source.manufacturer;
      this.ADR = source.ADR;
      this.water = source.water;

      this.tankCode = source.tankCode;
      this.tankDescription = source.tankDescription;
      this.weightNet = source.weightNet;
      this.weightGross = source.weightGross;
      if (source.registration) {
        this.registration = new Date(source.registration);
      }
      if (source.acqusition) {
        this.acqusition = new Date(source.acqusition);
      }
      this.availability = source.availability;
      if (source.dismission) {
        this.dismission = new Date(source.dismission);
      }
      this.archived = source.archived;
    }
  }

  static fromFormGroup(formGroup: FormGroup): Vehicle {
    const formModel = formGroup.value;

    let vehicle: Vehicle = new Vehicle(null);
    vehicle.createdAt = new Date();
    vehicle.license = formModel.license;
    vehicle.equipment = formModel.equipment;
    vehicle.type = formModel.type;
    vehicle.description = formModel.description;
    vehicle.ADR = formModel.ADR;
    vehicle.water = formModel.water;
    vehicle.tankCode = formModel.tankCode;
    vehicle.tankDescription = formModel.tankDescription;
    vehicle.weightNet = formModel.weightNet;
    vehicle.weightGross = formModel.weightGross;
    vehicle.registration = formModel.registration;
    vehicle.acqusition = formModel.acqusition;
    vehicle.availability = formModel.availability;
    vehicle.dismission = formModel.dismission;
    return vehicle;
  }

  public static resolveTypeIcon(type: string) {
    return `assets/images/${type.toLowerCase()}.png`;
  }

  public static resolveTypeLabel(type: string) {
    return VEHICLE_TYPES[type];
  }

  get typeIcon() {
    return Vehicle.resolveTypeIcon(this.type);
  }

  get typeLabel() {
    return Vehicle.resolveTypeLabel(this.type);
  }

  get generic(): boolean {
    return !this.license;
  }
}
