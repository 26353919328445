import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { formatDateForBackend } from "../../helpers/util";
import { environment } from "../../../../environments/environment";

@Injectable()
export class LaravelVehicleService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/vehicle",
      list: environment.baseUrl + "/api/vehicles",
      vehicle_types: environment.baseUrl + "/api/vehicle_types",
      store: environment.baseUrl + "/api/vehicle",
      update_availability:
        environment.baseUrl + "/api/vehicle/update_availability",
      archive: environment.baseUrl + "/api/vehicle/archive",
      delete: environment.baseUrl + "/api/vehicle"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    archived: boolean,
    include_generics: boolean
  ): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (archived) params["archived"] = "" + archived;
    if (include_generics) params["include_generics"] = "" + include_generics;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public getTypes(): Observable<any> {
    return this.httpClient.get(this.ROUTES.vehicle_types);
  }

  public create(
    license: string,
    equipment: string,
    type: string,
    description: string = "",
    manufacturer: string = "",
    ADR: boolean = false,
    water: boolean = false,
    tankCode: string = "",
    tankDescription: string = "",
    weightNet: number = 0,
    weightGross: number = 0,
    registration: Date = null,
    acqusition: Date = null,
    availability: string = "",
    dismission: Date = null
  ) {
    let vehicle = {
      license: license,
      equipment: equipment,
      type: type,
      description: description,
      manufacturer: manufacturer,
      ADR: ADR,
      water: water,
      tankCode: tankCode,
      tankDescription: tankDescription,
      weightNet: weightNet,
      weightGross: weightGross,
      registration: formatDateForBackend(registration),
      acqusition: formatDateForBackend(acqusition),
      availability: availability,
      dismission: formatDateForBackend(dismission)
    };
    return this.httpClient.post(this.ROUTES.store, { vehicle: vehicle });
  }

  public update(
    id: number,
    license: string,
    equipment: string,
    type: string,
    description: string = "",
    manufacturer: string = "",
    ADR: boolean = false,
    water: boolean = false,
    tankCode: string = "",
    tankDescription: string = "",
    weightNet: number = 0,
    weightGross: number = 0,
    registration: Date = null,
    acqusition: Date = null,
    availability: string = "",
    dismission: Date = null
  ) {
    let vehicle = {
      id: id,
      license: license,
      equipment: equipment,
      type: type,
      description: description,
      manufacturer: manufacturer,
      ADR: ADR,
      water: water,
      tankCode: tankCode,
      tankDescription: tankDescription,
      weightNet: weightNet,
      weightGross: weightGross,
      registration: formatDateForBackend(registration),
      acqusition: formatDateForBackend(acqusition),
      availability: availability,
      dismission: formatDateForBackend(dismission)
    };
    return this.httpClient.put(this.ROUTES.store, { vehicle: vehicle });
  }

  public updateUnavailability(
    id: number,
    start: Date,
    end?: Date,
    note?: string
  ) {
    let availability = {
      id: id,
      start_date: formatDateForBackend(start),
      end_date: formatDateForBackend(end),
      note: note
    };
    return this.httpClient.put(this.ROUTES.update_availability, {
      availability: availability
    });
  }

  public archive(id) {
    return this.httpClient.put(this.ROUTES.archive, { id: id });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
