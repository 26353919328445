import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../helpers/util';

@Injectable()
export class LaravelPlannedOrderService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/plannedOrder",
      list: environment.baseUrl + "/api/plannedOrders",
      store: environment.baseUrl + "/api/plannedOrder",
      plan: environment.baseUrl + "/api/plannedOrder/plan",
      delete: environment.baseUrl + "/api/plannedOrder",
      close: environment.baseUrl + "/api/plannedOrderClose",
      reopen: environment.baseUrl + "/api/plannedOrderReopen",
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    start_date: Date,
    end_date: Date,
    operator_ids?: number[],
    vehicle_ids?: number[],
    include?: string[]
  ): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (start_date) params["start_date"] = formatDateForBackend(start_date);
    if (end_date) params["end_date"] = formatDateForBackend(end_date);
    if (operator_ids) params["operator_ids[]"] = operator_ids;
    if (vehicle_ids) params["vehicle_ids[]"] = vehicle_ids;
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(
    order_id: number,
    planned_day_id: number,
    start_date: Date,
    position: number,
    description: string,
    overnight: boolean,
    note: string,
    vehicle_ids: number[],
    operator_ids: number[]
  ) {
    let planned_order = {
      order_id: order_id,
      planned_day_id: planned_day_id,
      start_date: formatDateForBackend(start_date, true),
      position: position,
      description: description,
      overnight: overnight,
      note: note,
      vehicle_ids: vehicle_ids,
      operator_ids: operator_ids
    };
    return this.httpClient.post(this.ROUTES.store, {
      planned_order: planned_order
    });
  }

  public update(
    id: number,
    order_id: number,
    planned_day_id: number,
    start_date: Date,
    position: number,
    description: string,
    overnight: boolean,
    note: string,
    vehicle_ids: number[],
    operator_ids: number[]
  ) {
    let planned_order = {
      id: id,
      order_id: order_id,
      planned_day_id: planned_day_id,
      start_date: formatDateForBackend(start_date, true),
      position: position,
      description: description,
      overnight: overnight,
      note: note,
      vehicle_ids: vehicle_ids,
      operator_ids: operator_ids
    };
    return this.httpClient.put(this.ROUTES.store, {
      planned_order: planned_order
    });
  }

  public plan(
    order_id: number,
    day: Date,
    start_date?: Date,
    position?: number,
    description?: string,
    note?: string,
    vehicle_ids?: number[],
    operator_ids?: number[]
  ) {
    let plan = {
      order_id: order_id,
      day: formatDateForBackend(day, false),
    }
    if (start_date) plan["start_date"] = formatDateForBackend(start_date, true);
    if (position) plan["position"] = position;
    if (position) plan["description"] = description;
    if (position) plan["note"] = note;
    if (position) plan["vehicle_ids"] = vehicle_ids;
    if (position) plan["operator_ids"] = operator_ids;
    return this.httpClient.post(this.ROUTES.plan, {
      plan: plan
    })
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }


  public close(
    id: number,
    closing_date: Date,
    closer_id: number
  ): Observable<any> {
    let data = {
      id: id,
      closing_date: formatDateForBackend(closing_date),
      closer_id: closer_id
    };
    return this.httpClient.post(this.ROUTES.close, { closing_data: data });
  }

  public reopen(id) {
    return this.httpClient.post(this.ROUTES.reopen, { id: id });
  }
}
