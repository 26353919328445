import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import {
  MatDialogModule,
  MatButtonModule,
  MatTooltipModule
} from "@angular/material";
import { OrderStatusIndicatorComponent } from "./order-status-indicator.component";

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatTooltipModule],
  declarations: [OrderStatusIndicatorComponent],
  entryComponents: [OrderStatusIndicatorComponent],
  exports: [OrderStatusIndicatorComponent]
})
export class OrderStatusIndicatorModule {}
