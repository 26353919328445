import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { Operator, Vehicle } from '../../model';
import { BusyOperatorsDetailsComponent } from './busy-operators-details/busy-operators-details.component';
import { BusyVehiclesDetailsComponent } from './busy-vehicles-details/busy-vehicles-details.component';

@Component({
  selector: "app-resource-indicator",
  templateUrl: "./resource-indicator.component.html",
  styleUrls: ["./resource-indicator.component.scss"]
})
export class ResourceIndicatorComponent implements OnInit {
  private _allVehicles: Vehicle[] = [];
  private _allOperators: Operator[] = [];

  private _busyVehicles: Vehicle[] = [];
  private _busyOperators: Operator[] = [];

  @Input()
  selectedDate: Date;

  constructor(private dialog: MatDialog) { }

  ngOnInit() { }

  showOperatorsDetails() {
    let data = {
      allOperators: this.allOperators,
      busyOperators: this.busyOperators,
      selectedDate: this.selectedDate
    };
    this.dialog.open(BusyOperatorsDetailsComponent, { data, width: "600px" });
  }

  showVehiclesDetails() {
    let data = {
      allVehicles: this.allVehicles,
      busyVehicles: this.busyVehicles,
      selectedDate: this.selectedDate
    };
    this.dialog.open(BusyVehiclesDetailsComponent, { data, width: "600px" });
  }

  public get allVehicles(): Vehicle[] {
    return this._allVehicles ? this._allVehicles.filter(v => !v.generic) : [];
  }

  public get allOperators(): Operator[] {
    return this._allOperators ? this._allOperators.filter(o => !o.generic) : [];
  }

  public get busyVehicles(): Vehicle[] {
    return this._busyVehicles ? this._busyVehicles.filter(v => !v.generic) : [];
  }

  public get busyOperators(): Operator[] {
    return this._busyOperators ? this._busyOperators.filter(o => !o.generic) : [];
  }

  @Input()
  public set allVehicles(value: Vehicle[]) {
    this._allVehicles = value;
  }

  @Input()
  public set allOperators(value: Operator[]) {
    this._allOperators = value;
  }

  @Input()
  public set busyVehicles(value: Vehicle[]) {
    this._busyVehicles = value;
  }

  @Input()
  public set busyOperators(value: Operator[]) {
    this._busyOperators = value;
  }
}
