import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guard/auth.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./home/home.module#HomeModule",
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    loadChildren: "./login/login.module#LoginModule"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
