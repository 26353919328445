import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/model';

import { ListResult } from '../helpers/listResult.interface';
import { Activity, ActivityFilters } from '../model/activity.model';
import { LaravelActivityService, LaravelDailyActivityService } from './laravel';

@Injectable()
export class ActivityService {
  constructor(private laravelActivityService: LaravelActivityService, private laravelDailyActivityService: LaravelDailyActivityService) { }

  public getActivity(id: number): Observable<Activity> {
    return this.laravelActivityService.getById(id).pipe(map(response => new Activity(response)));
  }

  public getActivities(page: number, perPage: number, order: string, direction: string, filters?: ActivityFilters, include?: string | string[]): Observable<ListResult<Activity>> {
    return this.laravelActivityService.list(page, perPage, order, direction, filters, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<Activity>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new Activity(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new Activity(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public createActivity(activity: Activity): Observable<Activity> {
    return this.laravelActivityService.create(
      activity.name,
      activity.description,
      activity.type,
      activity.area,
      activity.note,
      activity.assigner ? activity.assigner.objectId : null,
      activity.substitute ? activity.substitute.objectId : null,
      activity.frequency
    ).pipe(
      map(result => new Activity(result)))
  }

  public updateActivity(activity: Activity): Observable<Activity> {
    return this.laravelActivityService.update(
      activity.objectId,
      activity.name,
      activity.description,
      activity.type,
      activity.area,
      activity.note,
      activity.assigner ? activity.assigner.objectId : null,
      activity.substitute ? activity.substitute.objectId : null,
      activity.frequency
    ).pipe(
      map(result => new Activity(result)))
  }

  public deleteActivity(activity: Activity): Observable<Activity> {
    return this.laravelActivityService.delete(activity.objectId).pipe(
      map(result => {
        return new Activity(result);
      })
    );
  }

  public refreshDaily(date: Date): Observable<any> {
    return this.laravelDailyActivityService.createDailies(date, moment(date).add(1, "days").toDate());
  }

  public getReplacingUsers(user: User): Observable<User[]> {
    return this.laravelActivityService.replacing(user.objectId).pipe(map(results => results.map(dto => new User(dto))));
  }
}
