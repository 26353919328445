import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersResolverService } from 'src/app/shared/helpers/users-resolver.service';

import { DailyActivityComponent } from './daily-activity/daily-activity.component';
import { ReportCalendarComponent } from './report-calendar/report-calendar.component';
import { ReportListComponent } from './report-list/report-list.component';

const routes: Routes = [
  {
    path: "",
    component: DailyActivityComponent,
    resolve: {
      users: UsersResolverService
    },
  },
  {
    path: "calendar",
    component: ReportCalendarComponent,
  },
  {
    path: "list",
    component: ReportListComponent,
    resolve: {
      users: UsersResolverService
    },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [UsersResolverService],
  exports: [RouterModule]
})
export class DailyActivityRoutingModule { }
