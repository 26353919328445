import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { VehicleActivitiesComponent } from '../../resource-activity/vehicle-activities/vehicle-activities.component';
import { Vehicle } from './../../../model/vehicle.model';

@Component({
  selector: "app-busy-vehicles-details",
  templateUrl: "./busy-vehicles-details.component.html",
  styleUrls: ["./busy-vehicles-details.component.scss"]
})
export class BusyVehiclesDetailsComponent implements OnInit {
  all: Vehicle[];
  busy: Vehicle[];
  selectedDate: Date;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.all = this.data.allVehicles;
      this.busy = this.data.busyVehicles;
      this.selectedDate = this.data.selectedDate;
    }
  }

  getMapKeys(map: Map<string, any>): string[] {
    return Array.from(map.keys());
  }

  // get available(): Vehicle[] {
  //   return getArrayDifference(this.all, this.busy);
  // }

  get allVehicles(): Map<string, Vehicle[]> {
    return this.groupVehiclesByType(this.all);
  }

  filterAvailable(vehicles: Vehicle[]): Vehicle[] {
    return vehicles
      ? vehicles.filter(v => !this.busy.find(b => Vehicle.compare(b, v)))
      : [];
  }

  filterBusy(vehicles: Vehicle[]): Vehicle[] {
    return vehicles
      ? vehicles.filter(v => this.busy.find(b => Vehicle.compare(b, v)))
      : [];
  }

  // get availableVehicles(): Map<string, Vehicle[]> {
  //   return this.groupVehiclesByType(this.available);
  // }

  // get busyVehicles(): Map<string, Vehicle[]> {
  //   return this.groupVehiclesByType(this.busy);
  // }

  private sortOrder = [
    "trattore",
    "trattore_no_pompa",
    "semirimorchio_cisternato",
    "semirimorchio_pianale",
    "semirimorchio_vasca",
    "autospurgo",
    "rimorchio_cisternato",
    "rimorchio_pianale",
    "rimorchio_scarrabile",
    "autocarro_pianale_gru",
    "autocarro_scarrabile",
    "autocarro_pianale_gru",
    "autocarro_pulmino"
  ];

  private groupVehiclesByType(vehicles: Vehicle[]): Map<string, Vehicle[]> {
    let map: Map<string, Vehicle[]> = new Map<string, Vehicle[]>();
    this.sortOrder.forEach(key => {
      map.set(key, []);
    });

    if (vehicles) {
      vehicles.forEach(vehicle => {
        if (!vehicle.generic) {
          let array = map.get(vehicle.type) || [];
          array.push(vehicle);
          map.set(vehicle.type, array);
        }
      });
    }

    return map;
  }

  typeIcon(type: string) {
    return Vehicle.resolveTypeIcon(type);
  }

  typeLabel(type: string) {
    return Vehicle.resolveTypeLabel(type);
  }

  onRangeSelected(vehicle: Vehicle, event) {
    if (event && event.value) {
      this.openActivitiesDialog(vehicle, event.value.begin, event.value.end);
    }
  }

  private openActivitiesDialog(vehicle: Vehicle, start: Date, end: Date) {
    let data = {
      vehicle: vehicle,
      start: start,
      end: end
    };
    this.dialog.open(VehicleActivitiesComponent, {
      data,
      width: "98%",
      height: "95vh",
      //maxHeight: "76vh",
      panelClass: ["no-padding", "activities"]
    });
  }
}
