import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Todo } from 'src/app/shared/model';
import { AlertService, TodoService } from 'src/app/shared/services';

@Component({
  selector: 'app-todo-detail',
  templateUrl: './todo-detail.component.html',
  styleUrls: ['./todo-detail.component.scss']
})
export class TodoDetailComponent implements OnInit {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  todoForm: FormGroup;

  private hasUpdates: boolean = false;
  private _todo: Todo;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TodoDetailComponent>,
    private alertService: AlertService,
    private todoService: TodoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._createForm();
  }

  ngOnInit() {
    if (this.data) {
      this.todo = this.data.todo;
    }
  }

  private _createForm() {
    let group = {
      adminNote: [""]
    }

    this.todoForm = this.fb.group(group);
  }

  ngOnChanges() {
    if (this.todoForm) {
      this.todoForm.reset();
      if (this.todo) {
        this.todoForm.patchValue({
          adminNote: this.todo.adminNote
        })
      }
    }
  }

  save() {

    if (this.todo) {
      this.loadingSubject.next(true);
      this.todoService.updateAdminNote(this.todo, this.todoForm.value.adminNote).pipe(finalize(() => this.loadingSubject.next(false))).subscribe(todo => {
        this.todo = todo;
        this.hasUpdates = true;
        this.alertService.showConfirmMessage('Salvataggio completato correttamente');
        this.close()
      }, error => {
        this.alertService.showErrorMessage('Errore nel salvataggio della nota', error);
      })
    }
  }

  revert() {
    this.ngOnChanges();
  }

  close() {
    if (this.todoForm.pristine) {
      this.doClose();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  private doClose() {
    this.dialogRef.close(this.hasUpdates ? this.todo : null);
  }

  get todo(): Todo {
    return this._todo;
  }

  set todo(todo: Todo) {
    this._todo = todo;
    this.ngOnChanges();
  }

}
