import { Component, Input, OnInit } from '@angular/core';

import { Operator } from '../../model';

@Component({
  selector: "app-operator-in-list",
  templateUrl: "./operator-in-list.component.html",
  styleUrls: ["./operator-in-list.component.scss"]
})
export class OperatorInListComponent implements OnInit {
  @Input()
  operator: Operator;
  @Input()
  date: Date;
  @Input()
  showIcon: boolean = true;
  @Input()
  expandNote: boolean = false;

  constructor() { }

  ngOnInit() { }

  isUnavailable(): boolean {
    return this.operator ? this.operator.isUnavailable(this.date) : false;
  }
}
