import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OrderDetailComponent } from 'src/app/home/orders/order-detail/order-detail.component';
import { Operator, Order, Vehicle } from 'src/app/shared/model';
import { PlannedDay, PlannedOrder } from 'src/app/shared/model/planned-day.model';
import { PlanningService } from 'src/app/shared/services';

@Component({
  selector: 'app-resource-activity',
  templateUrl: './resource-activity.component.html',
  styleUrls: ['./resource-activity.component.scss']
})
export class ResourceActivityComponent implements OnInit, OnChanges {

  private _loading = new BehaviorSubject<boolean>(false);
  public loading$ = this._loading.asObservable();

  @Input()
  operator: Operator;
  @Input()
  vehicle: Vehicle;

  @Input()
  start: Date;
  @Input()
  end: Date;

  planning: Map<number, PlannedOrder[]>;

  constructor(private planningService: PlanningService, private dialog: MatDialog) { }

  ngOnInit() { }

  loadActivities() {
    this._loading.next(true);
    this.planningService.getPlannedOrders(
      1, 0, "", "", this.start, this.end,
      this.operator ? [this.operator.objectId] : null,
      this.vehicle ? [this.vehicle.objectId] : null,
      ['planned_day', 'order.client', 'order.supplier', 'operators', 'vehicles']
    ).pipe(finalize(() => this._loading.next(false)))
      .subscribe(results => {
        this.planning = new Map<number, PlannedOrder[]>();
        let days: Map<number, PlannedDay> = new Map<number, PlannedDay>();
        results.data.map(o => <PlannedDay>o.plannedDay).forEach(day => {
          days.set(day.date.getTime(), day);
        })

        results.data.forEach(plannedOrder => {
          const date: Date = (<PlannedDay>plannedOrder.plannedDay).date;
          let day: PlannedDay = days.get(date.getTime());
          day.orders.push(plannedOrder);
        });

        const current = moment(this.start)

        while (moment(this.end).diff(current, 'days') >= 0) {
          this.planning.set(current.toDate().getTime(), days.get(current.toDate().getTime()) ? days.get(current.toDate().getTime()).orders : []);
          current.add(1, 'days');
        }
      })
  }

  ngOnChanges() {
    this.loadActivities();
  }

  isUnavailable(date: Date): boolean {
    if (this.operator) {
      return this.operator.isUnavailable(date);
    }
    if (this.vehicle) {
      return this.vehicle.isUnavailable(date);
    }
    return false
  }

  editOrder(order: Order) {
    this.openOrderDialog(order);
  }

  private openOrderDialog(order?: Order) {
    let data = {
      order: order
    };
    let dialogRef = this.dialog.open(OrderDetailComponent, {
      data,
      width: "98%",
      height: "95vh",
      maxHeight: "100vh",
      panelClass: "no-padding"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reload) {
          this.loadActivities();
        }
        if (result.duplicate) this.openOrderDialog(result.duplicate);
      }
    });
  }
}
