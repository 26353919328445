import { NgModule } from '@angular/core';

import {
  ActivityService,
  AlertService,
  AnagraphicService,
  CommentService,
  OperatorService,
  OrderService,
  PlanningService,
  ProductService,
  TodoService,
  UserService,
  VehicleService,
} from '.';
import { LaravelServiceModule } from './laravel/laravel-service.module';

@NgModule({
  declarations: [],
  imports: [LaravelServiceModule],
  providers: [
    AlertService,
    AnagraphicService,
    ActivityService,
    OperatorService,
    CommentService,
    OrderService,
    PlanningService,
    ProductService,
    TodoService,
    UserService,
    VehicleService
  ]
})
export class ServiceModule { }
