import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BehaviorSubject, interval } from 'rxjs';
import { finalize, mergeMap, switchMap } from 'rxjs/operators';
import { getNextDay, getPreviousDay } from 'src/app/shared/helpers/util';
import { AlertService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

import { Operator, Order } from '../../../shared/model';
import { PlannedDay, PlannedOrder } from '../../../shared/model/planned-day.model';
import { OrderDetailComponent } from '../../orders/order-detail/order-detail.component';
import { PlannedOrderDialogComponent } from '../planned-order-dialog/planned-order-dialog.component';
import { Vehicle } from './../../../shared/model/vehicle.model';
import { PlanningService } from './../../../shared/services/planning.service';

const includes = [
  "orders.order",
  "orders.order.client",
  "orders.order.supplier",
  "orders.vehicles",
  "orders.operators",
  "orders.order.comments",
  "orders.order.products.product"
];

@Component({
  selector: "app-daily-view",
  templateUrl: "./daily-view.component.html",
  styleUrls: ["./daily-view.component.scss"]
})
export class DailyViewComponent implements OnInit {
  displayedColumns: string[] = [
    "operators",
    "vehicles",
    "client",
    "description",
    "note",
    "time",
    "order",
    "attachment",
    "checks",
    "actions"
  ];

  dragDisabled: boolean = true;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private generatingPDF = new BehaviorSubject<boolean>(false);
  public generatingPDF$ = this.generatingPDF.asObservable();

  allVehicles: Vehicle[] = [];
  allOperators: Operator[] = [];
  date: Date;
  showFilters: boolean = false;

  private _plannedDay: PlannedDay;
  private _sortedOrders: PlannedOrder[];

  private intervalSubscribe;

  filtersForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private planningService: PlanningService,
    public cd: ChangeDetectorRef,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params["date"]) {
        this.date = params["date"];
        this.loadPlannedDay();
      }
    });

    this.route.data.subscribe(data => {
      if (data["date"]) {
        this.date = data["date"];
        this.loadPlannedDay();
      }
    });

    if (this.route.snapshot.data && this.route.snapshot.data.date) {
      this.date = this.route.snapshot.data.date;
    }
    if (this.route.snapshot.params && this.route.snapshot.params.date) {
      this.date = this.route.snapshot.params.date;
    }
    this.loadPlannedDay();

    this.allVehicles = this.route.snapshot.data.vehicles;
    this.allOperators = this.route.snapshot.data.operators;

    this.createForm();
  }

  private filteredOrdersSubject = new BehaviorSubject<PlannedOrder[]>(
    this.sortedOrders
  );
  public filteredOrders$ = this.filteredOrdersSubject.asObservable();

  private createForm() {
    let group = {
      operator: [""],
      vehicle: [""],
      client: [""],
      search: [""]
    };

    this.filtersForm = this.fb.group(group);

    this.filtersForm.controls["operator"].valueChanges.subscribe(() => {
      this.filteredOrdersSubject.next(this._filterOrders());
    });
    this.filtersForm.controls["vehicle"].valueChanges.subscribe(() => {
      this.filteredOrdersSubject.next(this._filterOrders());
    });
    this.filtersForm.controls["client"].valueChanges.subscribe(() => {
      this.filteredOrdersSubject.next(this._filterOrders());
    });
    this.filtersForm.controls["search"].valueChanges.subscribe(() => {
      this.filteredOrdersSubject.next(this._filterOrders());
    });
  }

  private _filterOrders() {
    return this.sortedOrders
      .filter(order => {
        let selectedOperator = this.filtersForm.controls["operator"].value;
        return selectedOperator
          ? order.operators.find(
            operator => operator.objectId == selectedOperator.objectId
          ) != null
          : true;
      })
      .filter(order => {
        let selectedVehicle = this.filtersForm.controls["vehicle"].value;
        return selectedVehicle
          ? order.vehicles.find(
            vehicle => vehicle.objectId == selectedVehicle.objectId
          ) != null
          : true;
      })
      .filter(plannedOrder => {
        let selectedClient = this.filtersForm.controls["client"].value;
        let order: Order = plannedOrder.order as Order;
        return selectedClient
          ? order.client.headquarter.objectId == selectedClient.objectId
          : true;
      })
      .filter(plannedOrder => {
        let search: string = this.filtersForm.controls["search"].value;
        if (search) {
          search = search.toLowerCase();
          let order: Order = plannedOrder.order as Order;
          let inProduct: boolean = order.orderedProducts.map(op => op.product).some(p => {
            return p.CER.toLowerCase().indexOf(search) != -1
              || p.description.toLowerCase().indexOf(search) != -1
          });
          let inDescription: boolean = (plannedOrder.description || "").toLowerCase().indexOf(search) != -1;
          let inNote: boolean = (order.note || "").toLowerCase().indexOf(search) != -1;
          let inClient: boolean = false
          if (order.client) {
            inClient =
              (order.client.name || "").toLowerCase().indexOf(search) != -1 ||
              (order.client.completeAddress || "").toLowerCase().indexOf(search) != -1
          }
          let inSupplier: boolean = false
          if (order.supplier) {
            inSupplier =
              (order.supplier.name || "").toLowerCase().indexOf(search) != -1 ||
              (order.supplier.completeAddress || "").toLowerCase().indexOf(search) != -1
          }
          let inOrderedProducts: boolean = order.orderedProducts.some(op => {
            return op.tech_note.toLowerCase().indexOf(search) != -1
          });
          let inComments: boolean = (order.comments || []).some(comment => (comment.body || "").toLowerCase().indexOf(search) != -1)
          return inProduct || inDescription || inNote || inClient || inSupplier || inOrderedProducts || inComments;
        }
        return true;
      });
  }

  private loadPlannedDay() {
    this.loadingSubject.next(true);
    this.planningService
      .getPlannedDayByDate(this.date, includes)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(plannedDay => (this.plannedDay = plannedDay));
  }

  ngOnDestroy() {
    if (this.intervalSubscribe) {
      this.intervalSubscribe.unsubscribe();
    }
  }

  get plannedDay(): PlannedDay {
    return this._plannedDay;
  }

  set plannedDay(plannedDay: PlannedDay) {
    this._plannedDay = plannedDay;
    this.cd.markForCheck();
    this.sortedOrders = plannedDay ? plannedDay.orders : [];

    if (this.intervalSubscribe) {
      this.intervalSubscribe.unsubscribe();
    }

    if (plannedDay) {
      // Refresh submission status
      if (environment["refreshInterval"]) {
        this.intervalSubscribe = interval(environment["refreshInterval"]).subscribe(t => {
          this.checkForUpdates();
        });
      }
    }
  }

  formatOperators(operators: Operator[]): string {
    return operators && operators.length > 0
      ? operators
        .map(operator => {
          return operator.name + " " + operator.surname;
        })
        .join(", ")
      : "";
  }

  formatVehicles(vehicles: Vehicle[]): string {
    return vehicles && vehicles.length > 0
      ? vehicles
        .map(vehicle => {
          return vehicle.license;
        })
        .join(", ")
      : "";
  }

  getOrderType(plannedOrder: PlannedOrder): string {
    let order: Order =
      plannedOrder && plannedOrder.order ? (plannedOrder.order as Order) : null;
    let result = order && order.isClosed() ? "box--closed " : "";
    return (result += order ? order.type : "");
  }

  get sortedOrders(): PlannedOrder[] {
    return this._sortedOrders;
  }

  set sortedOrders(orders: PlannedOrder[]) {
    this._sortedOrders = orders.sort((a: PlannedOrder, b: PlannedOrder) => {
      return a.position < b.position ? -1 : 1;
    });
    this.filteredOrdersSubject.next(this._filterOrders());
  }

  dropTable(event: CdkDragDrop<PlannedOrder[]>) {
    const prevIndex = this.filteredOrdersSubject.value.findIndex(
      d => d === event.item.data
    );
    moveItemInArray(this.plannedDay.orders, prevIndex, event.currentIndex);
    this.filteredOrdersSubject.next(this._filterOrders());
  }

  getRowClass(plannedOrder: PlannedOrder) {
    let order = <Order>plannedOrder.order;
    if (order) {
      let result = plannedOrder && plannedOrder.isClosed() ? "closed " : "";
      return (result += order ? order.type : "");
    }
    return "";
  }

  updatePositions() {
    this.plannedDay.orders.forEach((plannedOrder, index) => {
      plannedOrder.position = index;
    });
    this.loadingSubject.next(true);

    this.planningService
      .updatePlannedDay(this.plannedDay)
      .pipe(
        switchMap(() => {
          return this.planningService
            .getPlannedDay(this.plannedDay.objectId, includes)
            .pipe(
              finalize(() => {
                this.loadingSubject.next(false);
              })
            );
        })
      )
      .subscribe(plannedDay => {
        this.plannedDay = plannedDay;
      });
  }

  checkForUpdates() {
    this.planningService
      .checkForUpdates(
        this.plannedDay ? this.plannedDay.date : this.date,
        this.plannedDay ? this.plannedDay.updatedAt : null,
        includes
      )
      .subscribe(plannedDay => {
        if (plannedDay) {
          console.log("Update available");
          this.plannedDay = plannedDay;
        }
      });
  }

  completePlannedOrder(plannedOrder: PlannedOrder) {
    this.alertService
      .showConfirmDialog(
        "Evadi ordine",
        "Sei sicuro di voler impostare l'ordine come evaso?"
      )
      .subscribe(result => {
        if (result) {
          this.doComplete(plannedOrder);
        }
      });
  }

  private doComplete(plannedOrder: PlannedOrder) {
    this.loadingSubject.next(true);
    this.planningService
      .closePlannedOrder(plannedOrder.objectId)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(result => {
        plannedOrder.closedAt = result.closedAt;
        this.loadPlannedDay();
      });
  }

  reopenPlannedOrder(plannedOrder: PlannedOrder) {
    this.alertService
      .showConfirmDialog(
        "Riapri ordine",
        "Sei sicuro di voler riaprire l'ordine precedentemente evaso?"
      )
      .subscribe(result => {
        if (result) {
          this.doReopen(plannedOrder);
        }
      });
  }

  private doReopen(plannedOrder: PlannedOrder) {
    this.loadingSubject.next(true);
    this.planningService
      .reopenPlannedOrder(plannedOrder)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(result => {
        plannedOrder.closedAt = result.closedAt;
        this.loadPlannedDay();
      });
  }

  editOrder(order: Order) {
    this.openOrderDialog(order);
  }

  private openOrderDialog(order?: Order) {
    let data = {
      order: order
    };
    let dialogRef = this.dialog.open(OrderDetailComponent, {
      data,
      width: "98%",
      height: "95vh",
      maxHeight: "100vh",
      panelClass: "no-padding"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reload) {
          this.loadPlannedDay();
        }
        if (result.duplicate) this.openOrderDialog(result.duplicate);
      }
    });
  }

  editDailyPlan() {
    this.router.navigate(
      ["/dailyPlan", moment(this.plannedDay.date).format("YYYY-MM-DD")],
      {
        relativeTo: this.route
      }
    );
  }

  editPlannedOrder(plannedOrder: PlannedOrder) {
    let data = {
      plannedOrder: plannedOrder,
      plannedDay: this.plannedDay,
      allOperators: this.allOperators,
      allVehicles: this.allVehicles
    };
    let dialogRef = this.dialog.open(PlannedOrderDialogComponent, {
      data,
      width: "80%"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPlannedDay();
      }
    });
  }

  goToPreviousDay() {
    this.goToDate(getPreviousDay(this.date, true));
  }

  goToNextDay() {
    this.goToDate(getNextDay(this.date, true));
  }

  onDateSelected(event) {
    let date = event.value.toDate();
    if (date) {
      this.goToDate(date);
    }
  }

  private goToDate(date: Date) {
    this.router.navigate(["/dailyView", moment(date).format("YYYY-MM-DD")], {
      relativeTo: this.route
    });
  }

  addDailyPlan() {
    this.router.navigate(
      ["/dailyPlan", moment(this.date).format("YYYY-MM-DD")],
      {
        relativeTo: this.route
      }
    );
  }

  onMoveDateSelected(plannedOrder: PlannedOrder, event) {
    let date = event.value.toDate();
    if (date) {
      this.moveOrder(plannedOrder, date);
    }
  }

  onCopyDateSelected(plannedOrder: PlannedOrder, event) {
    let date = event.value.toDate();
    if (date) {
      this.copyOrder(plannedOrder, date);
    }
  }

  private moveOrder(plannedOrder: PlannedOrder, date: Date) {
    this.loadingSubject.next(true);
    this.planningService
      .movePlannedOrder(plannedOrder, date)
      .pipe(
        mergeMap(() => {
          return this.planningService.getPlannedDay(
            this.plannedDay.objectId,
            includes
          );
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(
        plannedDay => {
          console.log("Moved", plannedOrder);
          let formattedDay = moment(date).format("DD-MM-YYYY");
          this.alertService.showConfirmMessage(
            `Ordine ${plannedOrder.order.objectId} spostato al ${formattedDay}`
          );
          this.plannedDay = plannedDay;
        },
        error => {
          console.error("Error while moving", error);
        }
      );
  }

  private copyOrder(plannedOrder: PlannedOrder, date: Date) {
    this.loadingSubject.next(true);
    this.planningService
      .copyPlannedOrder(plannedOrder, date)
      .pipe(
        mergeMap(() => {
          return this.planningService.getPlannedDay(
            this.plannedDay.objectId,
            includes
          );
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(
        savedPlannedDay => {
          console.log("Order copied", plannedOrder);
          if (moment(savedPlannedDay.date).isSame(this.date)) {
            this.loadPlannedDay();
          }
          let formattedDay = moment(date).format("DD-MM-YYYY");
          this.alertService.showConfirmMessage(
            `Ordine ${plannedOrder.order.objectId} copiato al ${formattedDay}`
          );
        },
        error => {
          console.error("Error while moving", error);
        }
      );
  }

  downloadPDF() {
    let fileName = `Pianificazione del ${moment(this.plannedDay.date).format(
      "DD-MM-YYYY"
    )}.pdf`;
    this.generatingPDF.next(true);
    this.planningService
      .downloadPDF(this.plannedDay, this.filtersForm.value.operator, this.filtersForm.value.vehicle, this.filtersForm.value.client)
      .pipe(finalize(() => this.generatingPDF.next(false)))
      .subscribe(blob => {
        saveAs(blob, fileName);
      });
  }

  downloadCSV() {
    let fileName = `Pianificazione del ${moment(this.plannedDay.date).format(
      "DD-MM-YYYY"
    )}.csv`;
    this.generatingPDF.next(true);
    this.planningService
      .exportDay(this.plannedDay.date)
      .pipe(finalize(() => this.generatingPDF.next(false)))
      .subscribe(blob => {
        saveAs(blob, fileName);
      });
  }

  get unavOperators(): Operator[] {
    return this.allOperators.filter(o => o.isUnavailable(this.date));
  }

  get unavVehicles(): Vehicle[] {
    return this.allVehicles.filter(v => v.isUnavailable(this.date));
  }

}
