import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { ROLES } from '../../model';
import { AuthService } from '../../services/auth.service';

@Directive({ selector: "[showFor]" })
export class ShowForDirective implements OnInit {

  private _roles: ROLES[];

  constructor(private templateRef: TemplateRef<any>, private authService: AuthService, private viewContainer: ViewContainerRef) { }

  get roles(): ROLES[] {
    return this._roles;
  }

  @Input()
  set showFor(roles: ROLES[]) {
    this._roles = roles;
  }

  ngOnInit() {
    this.authService.getCurrentUser().subscribe(currentUser => {
      let show = false;
      if (currentUser && this.roles && this.roles.length > 0) {
        let temp = this.roles.map(role => currentUser.hasRole(role))
        show = temp.indexOf(true) != -1;
      }

      if (show) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
