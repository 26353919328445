import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort } from '@angular/material';
import { merge, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DataSource } from '../../../../../node_modules/@angular/cdk/table';
import { Order, ORDER_TYPE } from '../../../shared/model';
import { OrderFilters, OrderService } from '../../../shared/services';
import { OrderDatasource } from '../../orders/helpers/order.datasource';
import { OrderDetailComponent } from '../../orders/order-detail/order-detail.component';

@Component({
  selector: "app-order-selection",
  templateUrl: "./order-selection.component.html",
  styleUrls: ["./order-selection.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", visibility: "hidden" })
      ),
      state("expanded", style({ height: "*", visibility: "visible" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})
export class OrderSelectionComponent implements OnInit {
  defaultOrderFilters: OrderFilters = { includePlanned: true, types: Object.keys(ORDER_TYPE) };
  showFilters: boolean = false;
  selectedOrder: Order;
  dataSource: OrderDatasource;
  dataSourceWithDetails: OrderDetailDatasource | null;
  displayedColumns = [
    "id",
    "createdAt",
    "date",
    "checkTech",
    "checkCommercial",
    "checkLogistic",
    "checkPlant",
    "client",
    "needs",
    "actions"
  ];
  pageSizeOptions: number[] = [100, 200, 500];

  selectedOrders: Order[];

  wasExpanded = new Set<Order>();
  isDetailRow = (_index: number, row: DetailRow | Order) =>
    row.hasOwnProperty("detailRow");

  expandedElement: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private orderService: OrderService,
    public dialogRef: MatDialogRef<OrderSelectionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.selectedOrder = this.data.order;
      this.selectedOrders = this.data.selectedOrders;
    }
    this.dataSource = new OrderDatasource(this.orderService);
    this.dataSourceWithDetails = new OrderDetailDatasource(this.dataSource);
    this.loadOrders();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.loadOrders()))
      .subscribe();
  }

  loadOrders(filters?: OrderFilters) {
    this.dataSource.loadOrders(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize | this.pageSizeOptions[0],
      this.sort.active,
      this.sort.direction,
      filters,
      ["client", "supplier", "planned_orders.planned_day"]
    );
  }

  orderSelect(order) {
    this.selectedOrder = order;
    this.close();
  }

  close() {
    this.dialogRef.close({
      order: this.selectedOrder
    });
  }

  createOrder(plantOrder: boolean = false) {
    this.openOrderDialog(null, plantOrder);
  }

  getRowClass(order: Order) {
    let result = order && order.isClosed() ? "closed " : "";
    if (order == this.expandedElement) result += "expanded ";
    return (result += order ? order.type : "");
  }

  private openOrderDialog(order?: Order, plantOrder?: boolean) {
    let data = {
      order: order,
      plantOrder: plantOrder
    };
    let dialogRef = this.dialog.open(OrderDetailComponent, {
      data,
      width: "98%",
      height: "95vh",
      maxHeight: "100vh",
      panelClass: "no-padding"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reload) this.loadOrders();
        if (result.duplicate) this.openOrderDialog(result.duplicate);
      }
    });
  }

  onFilter(filters: OrderFilters) {
    this.loadOrders(filters);
  }
}

interface DetailRow {
  detailRow: boolean;
  data: Order;
}

class OrderDetailDatasource extends DataSource<any> {
  constructor(private orderDataSource: OrderDatasource) {
    super();
  }

  connect(): Observable<(Order | DetailRow)[]> {
    return this.orderDataSource.connect().pipe(
      map(data => {
        const rows: (Order | DetailRow)[] = [];

        // Interweave a detail data object for each row data object that will be used for displaying
        // row details. Contains the row data.
        data.forEach(order =>
          rows.push(order, { detailRow: true, data: order })
        );

        return rows;
      })
    );
  }

  disconnect() {
    // No-op
  }
}
