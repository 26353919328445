import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { Anagraphic, Operator, Vehicle } from 'src/app/shared/model';

import { ListResult } from '../helpers/listResult.interface';
import { formatDateForBackend } from '../helpers/util';
import { Order } from '../model';
import { PlannedDay, PlannedOrder } from '../model/planned-day.model';
import { AuthService } from './auth.service';
import { LaravelPlannedDayService, LaravelPlannedOrderService } from './laravel';

@Injectable()
export class PlanningService {
  constructor(private laravelPlannedDayService: LaravelPlannedDayService, private laravelPlannedOrderService: LaravelPlannedOrderService, private authService: AuthService) { }

  public getPlannedDay(id: number, include?: string | string[]): Observable<PlannedDay> {
    return this.laravelPlannedDayService.getById(id, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return new PlannedDay(response);
      })
    );
  }

  public getCompiledDates(): Observable<Date[]> {
    return this.laravelPlannedDayService.getCompiledDates(new Date(), null).pipe(
      map(results => {
        if (results) {
          return results.map(result => new Date(result));
        } else {
          return [];
        }
      })
    );
  }

  public getPlannedDayByDate(date: Date, include?: string | string[]): Observable<PlannedDay> {
    return this.getPlannedDays(0, 1, null, null, date, date, include).pipe(
      map(result => {
        if (result && result.data.length > 0) {
          return result.data[0];
        } else {
          return null;
        }
      })
    );
  }

  public getPlannedDays(start: number, perPage: number, order: string, direction: string, startDate?: Date, endDate?: Date, include?: string | string[]): Observable<ListResult<PlannedDay>> {
    return this.laravelPlannedDayService.list(start, perPage, order, direction, startDate, endDate, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<PlannedDay>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new PlannedDay(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new PlannedDay(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public createPlannedDay(plannedDay: PlannedDay): Observable<PlannedDay> {
    return this.laravelPlannedDayService.create(plannedDay.date, plannedDay.note, this.formatOrdersForBackend(plannedDay.orders)).pipe(
      map(response => {
        return new PlannedDay(response);
      })
    );
  }

  public updatePlannedDay(plannedDay: PlannedDay): Observable<PlannedDay> {
    return this.laravelPlannedDayService.update(plannedDay.objectId, plannedDay.date, plannedDay.note, this.formatOrdersForBackend(plannedDay.orders)).pipe(
      map(response => {
        return new PlannedDay(response);
      })
    );
  }

  public checkForUpdates(date: Date, updatedAt: Date, include?: string | string[]): Observable<PlannedDay> {
    return this.laravelPlannedDayService.getUpdatedPlannedDay(date, updatedAt, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return response ? new PlannedDay(response) : null;
      })
    );
  }

  public deletePlannedDay(plannedDay: PlannedDay): Observable<PlannedDay> {
    return this.laravelPlannedDayService.delete(plannedDay.objectId).pipe(
      map(result => {
        return new PlannedDay(result);
      })
    );
  }

  /// PLANNED ORDERS ///
  public getPlannedOrder(id: number, include?: string | string[]): Observable<PlannedOrder> {
    return this.laravelPlannedOrderService.getById(id, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return new PlannedOrder(response);
      })
    );
  }

  public planOrder(order: Order, day: Date): Observable<PlannedOrder> {
    return this.laravelPlannedOrderService.plan(order.objectId, day).pipe(map(response => new PlannedOrder(response)));
  }

  public createPlannedOrder(plannedOrder: PlannedOrder): Observable<PlannedOrder> {
    return this.laravelPlannedOrderService
      .create(
        plannedOrder.order ? plannedOrder.order.objectId : null,
        plannedOrder.plannedDay ? plannedOrder.plannedDay.objectId : null,
        plannedOrder.startDate,
        plannedOrder.position,
        plannedOrder.description,
        plannedOrder.overnight,
        plannedOrder.note,
        plannedOrder.vehicles.map(vehicle => {
          return vehicle.objectId;
        }),
        plannedOrder.operators.map(operator => {
          return operator.objectId;
        })
      )
      .pipe(
        map(response => {
          return new PlannedOrder(response);
        })
      );
  }

  public updatePlannedOrder(plannedOrder: PlannedOrder): Observable<PlannedOrder> {
    return this.laravelPlannedOrderService
      .update(
        plannedOrder.objectId,
        plannedOrder.order ? plannedOrder.order.objectId : null,
        plannedOrder.plannedDay ? plannedOrder.plannedDay.objectId : null,
        plannedOrder.startDate,
        plannedOrder.position,
        plannedOrder.description,
        plannedOrder.overnight,
        plannedOrder.note,
        plannedOrder.vehicles.map(vehicle => {
          return vehicle.objectId;
        }),
        plannedOrder.operators.map(operator => {
          return operator.objectId;
        })
      )
      .pipe(
        map(response => {
          return new PlannedOrder(response);
        })
      );
  }

  public deletePlannedOrder(plannedOrder: PlannedOrder): Observable<PlannedOrder> {
    return this.laravelPlannedOrderService.delete(plannedOrder.objectId).pipe(
      map(result => {
        return new PlannedOrder(result);
      })
    );
  }

  private formatOrdersForBackend(orders: Array<PlannedOrder>) {
    let result = [];
    if (orders) {
      orders.forEach(order => {
        result.push({
          id: order.objectId,
          note: order.note,
          description: order.description,
          order_id: order.order ? order.order.objectId : null,
          planned_day_id: order.plannedDay ? order.plannedDay.objectId : null,
          start_date: formatDateForBackend(order.startDate, true),
          vehicle_ids: order.vehicles.map(vehicle => vehicle.objectId),
          operator_ids: order.operators.map(operator => operator.objectId),
          overnight: order.overnight,
          position: order.position
        });
      });
    }
    return result;
  }

  public copyPlannedOrder(plannedOrder: PlannedOrder, newDate: Date): Observable<PlannedDay> {
    let newPlannedOrder: PlannedOrder = plannedOrder.clone();
    return this.getPlannedDayByDate(newDate).pipe(
      mergeMap(plannedDay => {
        if (plannedDay) {
          newPlannedOrder.plannedDay = plannedDay;
          return this.createPlannedOrder(newPlannedOrder).pipe(
            mergeMap(() => {
              return of(plannedDay);
            })
          );
        } else {
          let newPlannedDay: PlannedDay = new PlannedDay(null);
          newPlannedDay.date = newDate;
          newPlannedDay.orders = [newPlannedOrder];
          return this.createPlannedDay(newPlannedDay);
        }
      })
    );
  }

  public movePlannedOrder(plannedOrder: PlannedOrder, newDate: Date): Observable<any> {
    //TODO gestire correttamente la posizione dell'ordine
    return this.getPlannedDayByDate(newDate).pipe(
      mergeMap(plannedDay => {
        if (plannedDay) {
          plannedOrder.plannedDay = plannedDay;
          return this.updatePlannedOrder(plannedOrder).pipe(
            mergeMap(order => {
              return of(plannedDay);
            })
          );
        } else {
          let newPlannedDay: PlannedDay = new PlannedDay(null);
          newPlannedDay.date = newDate;
          newPlannedDay.orders = [plannedOrder];
          return this.createPlannedDay(newPlannedDay);
        }
      })
    );
  }

  public downloadPDF(plannedDay: PlannedDay, operator?: Operator, vehicle?: Vehicle, client?: Anagraphic): Observable<Blob> {
    return this.laravelPlannedDayService.downloadPdf(
      plannedDay.objectId,
      operator ? operator.objectId : null,
      vehicle ? vehicle.objectId : null,
      client ? client.objectId : null
    ).pipe(map(data => new Blob([data], { type: "application/pdf" })));
  }

  public closePlannedOrder(
    plannedOrderId: number,
    closingDate: Date = new Date()
  ): Observable<PlannedOrder> {
    return this.authService.getCurrentUser().pipe(
      switchMap(currentUser => {
        return this.laravelPlannedOrderService
          .close(
            plannedOrderId,
            closingDate,
            currentUser.objectId
          )
          .pipe(
            map(response => {
              return new PlannedOrder(response);
            })
          );
      })
    )
  }

  public reopenPlannedOrder(plannedOrder: PlannedOrder): Observable<PlannedOrder> {
    return this.laravelPlannedOrderService.reopen(plannedOrder.objectId).pipe(
      map(response => {
        return new PlannedOrder(response);
      })
    );
  }

  public getPlannedOrders(page: number, perPage: number, order: string, direction: string, from: Date, to: Date, operatorIds: number[], vehicleIds: number[], include?: string | string[]): Observable<ListResult<PlannedOrder>> {
    return this.laravelPlannedOrderService.list(page, perPage, order, direction, from, to, operatorIds, vehicleIds, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<PlannedOrder>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new PlannedOrder(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new PlannedOrder(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    )
  }

  public exportDay(day: Date): Observable<Blob> {
    return this.laravelPlannedDayService.export(day);
  }
}
