import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
} from '@angular/material';
import { ShowForDirectivesModule } from 'src/app/shared/directives/show-for/show-for.module';

import { OrderStatusIndicatorModule } from '../../../shared/modules/order-status-indicator/order-status-indicator.module';
import { CommentDetailComponent } from '../comment-detail/comment-detail.component';
import { CommentsListComponent } from '../comments-list/comments-list.component';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { OrdersRoutingModule } from '../orders-routing.module';

@NgModule({
    imports: [
        CommonModule,
        OrdersRoutingModule,
        MatButtonToggleModule,
        MatCardModule,
        MatTooltipModule,
        MatIconModule,
        MatTabsModule,
        MatTableModule,
        MatDialogModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatMenuModule,
        MatSortModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        OrderStatusIndicatorModule,
        ShowForDirectivesModule
    ],
    declarations: [
        OrderDetailComponent,
        CommentDetailComponent,
        CommentsListComponent,
    ],
    entryComponents: [
        OrderDetailComponent,
        CommentDetailComponent
    ],
    exports: [
        OrderDetailComponent,
        CommentDetailComponent
    ]
})
export class OrderDetailModule { }
