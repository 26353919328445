export class LazyLoadable {
  objectId?: number;
  loaded?: boolean = true;

  constructor(source: any) {
    if (source) {
      this.objectId = source.id;
    }
  }
}

export class CommonObject extends LazyLoadable {
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;

  constructor(source: any) {
    super(source);
    if (source) {
      this.createdAt = source.created_at;
      this.updatedAt = source.updated_at;
      this.deletedAt = source.deleted_at;
    }
  }

  public static compare(b1: CommonObject, b2: CommonObject): boolean {
    return b1 && b2 ? b1.objectId === b2.objectId : b1 === b2;
  }
}
