import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import "hammerjs";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

const environmentLoader = new Promise<any>((resolve, reject) => {
  var xmlhttp = new XMLHttpRequest(),
    method = "GET",
    url = "./assets/environments/environment.json";
  xmlhttp.open(method, url, true);
  xmlhttp.onload = function() {
    if (xmlhttp.status === 200) {
      resolve(JSON.parse(xmlhttp.responseText));
    } else {
      resolve({});
    }
  };
  xmlhttp.send();
});

environmentLoader.then(loadedEnv => {
  if (loadedEnv) {
    Object.assign(environment, loadedEnv);
  }

  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});
