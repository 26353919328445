import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListResult } from "../helpers/listResult.interface";
import { Vehicle } from "../model/vehicle.model";
import { LaravelVehicleService } from "./laravel/laravel-vehicle.service";
import { Resource } from "../model/resource.model";

@Injectable()
export class VehicleService {
  constructor(private laravelVehicleService: LaravelVehicleService) {}

  public getVehicle(id: number): Observable<Vehicle> {
    return this.laravelVehicleService.getById(id).pipe(
      map(response => {
        return new Vehicle(response);
      })
    );
  }

  public getVehicles(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    archived: boolean,
    includeGenerics: boolean = false
  ): Observable<ListResult<Vehicle>> {
    return this.laravelVehicleService
      .list(page, perPage, order, direction, archived, includeGenerics)
      .pipe(
        map(response => {
          let data = new Array<Vehicle>();
          let total = 0;
          if (perPage) {
            response.data.forEach(element => {
              data.push(new Vehicle(element));
            });
            total = response.total;
          } else {
            response.forEach(element => {
              data.push(new Vehicle(element));
            });
            total = response.length;
          }
          return { data: data, total: total };
        })
      );
  }

  public getGenerics(): Observable<Vehicle[]> {
    return this.laravelVehicleService
      .getTypes()
      .pipe(map(results => results.map(r => new Vehicle(r))));
  }

  public createVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return this.laravelVehicleService
      .create(
        vehicle.license,
        vehicle.equipment,
        vehicle.type,
        vehicle.description,
        vehicle.manufacturer,
        vehicle.ADR,
        vehicle.water,
        vehicle.tankCode,
        vehicle.tankDescription,
        vehicle.weightNet,
        vehicle.weightGross,
        vehicle.registration,
        vehicle.acqusition,
        vehicle.availability,
        vehicle.dismission
      )
      .pipe(
        map(result => {
          return new Vehicle(result);
        })
      );
  }

  public updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return this.laravelVehicleService
      .update(
        vehicle.objectId,
        vehicle.license,
        vehicle.equipment,
        vehicle.type,
        vehicle.description,
        vehicle.manufacturer,
        vehicle.ADR,
        vehicle.water,
        vehicle.tankCode,
        vehicle.tankDescription,
        vehicle.weightNet,
        vehicle.weightGross,
        vehicle.registration,
        vehicle.acqusition,
        vehicle.availability,
        vehicle.dismission
      )
      .pipe(
        map(result => {
          return new Vehicle(result);
        })
      );
  }

  public updateUnavailability(resource: Resource): Observable<Vehicle> {
    return this.laravelVehicleService
      .updateUnavailability(
        resource.objectId,
        resource.unavStartDate,
        resource.unavEndDate,
        resource.unavNote
      )
      .pipe(
        map(result => {
          return new Vehicle(result);
        })
      );
  }

  public archiveVehicle(vehicle: Vehicle): Observable<Vehicle> {
    return this.laravelVehicleService.archive(vehicle.objectId).pipe(
      map(result => {
        return new Vehicle(result);
      })
    );
  }
}
