import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OperatorInListComponent } from "./operator-in-list.component";
import { MatIconModule, MatTooltipModule } from "@angular/material";

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  declarations: [OperatorInListComponent],
  exports: [OperatorInListComponent]
})
export class OperatorInListModule {}
