import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';

import { addIfNotExists, getArrayDifference } from '../helpers/util';
import { Anagraphic } from './anagraphic.model';
import { CommonObject, LazyLoadable } from './common-object.model';
import { Operator } from './operator.model';
import { Order } from './order.model';
import { Vehicle } from './vehicle.model';

export class PlannedDay extends CommonObject {
  date: Date;
  note?: string;

  orders: PlannedOrder[] = new Array<PlannedOrder>();

  constructor(source) {
    super(source);
    if (source) {
      if (source.date) {
        this.date = new Date(source.date);
      }
      this.note = source.note;

      if (source.orders) {
        source.orders.forEach(order => {
          let plannedOrder = new PlannedOrder(order);
          plannedOrder.plannedDay = this;
          this.orders.push(plannedOrder);
        });
      }
    }
  }

  static fromFormGroup(formGroup: AbstractControl): PlannedDay {
    const formModel = formGroup.value;
    let plannedDay: PlannedDay = new PlannedDay(null);

    plannedDay.date = formModel.date;
    plannedDay.note = formModel.note;
    return plannedDay;
  }

  getServedClients(): Anagraphic[] {
    let clients = this.orders.map(plannedOrder => {
      if (plannedOrder.order.loaded) {
        return (plannedOrder.order as Order).client.headquarter;
      }
      return null;
    });

    const results: Anagraphic[] = [];
    const map = new Map();
    clients.forEach(client => {
      if (!map.has(client.objectId)) {
        map.set(client.objectId, true);
        results.push(client);
      }
    });
    return results;
  }

  getAvailableOperators(allOperators: Operator[]): Operator[] {
    return getArrayDifference(
      allOperators,
      this.getBusyOperators(allOperators)
    );
  }

  getBusyOperators(
    allOperators: Operator[],
    excludedOrder?: PlannedOrder
  ): Operator[] {
    let busyOperators: Operator[] = allOperators.filter(operator => {
      return operator.isUnavailable(this.date);
    });

    if (this.orders) {
      this.orders.forEach(order => {
        if (!PlannedOrder.compare(order, excludedOrder)) {
          let operators = order.operators;
          if (operators) {
            operators.forEach(operator => {
              addIfNotExists(busyOperators, operator);
            });
          }
        }
      });
    }

    return busyOperators;
  }

  getAvailableVehicles(allVehicles: Vehicle[]): Vehicle[] {
    return getArrayDifference(allVehicles, this.getBusyVehicles(allVehicles));
  }

  getBusyVehicles(
    allVehicles: Vehicle[],
    excludedOrder?: PlannedOrder
  ): Vehicle[] {
    let busyVehicles: Vehicle[] = allVehicles.filter(vehicle => {
      return !vehicle.generic && vehicle.isUnavailable(this.date);
    });

    if (this.orders) {
      this.orders.forEach(order => {
        if (!PlannedOrder.compare(order, excludedOrder)) {
          let vehicles = order.vehicles;
          if (vehicles) {
            vehicles.forEach(vehicle => {
              if (!vehicle.generic) {
                addIfNotExists(busyVehicles, vehicle);
              }
            });
          }
        }
      });
    }
    return busyVehicles;
  }
}

export class PlannedOrder extends CommonObject {
  plannedDay: PlannedDay | LazyLoadable;
  order: Order | LazyLoadable;
  startDate: Date;
  vehicles: Vehicle[] = new Array<Vehicle>();
  operators: Operator[] = new Array<Operator>();
  description?: string;
  position: number;
  overnight?: boolean;
  note?: string;
  closedAt?: Date;

  constructor(source) {
    super(source);

    if (source) {
      if (source.planned_day) {
        this.plannedDay = new PlannedDay(source.planned_day);
      } else if (source.planned_day_id) {
        this.plannedDay = {
          objectId: source.planned_day_id,
          loaded: false
        };
      }
      if (source.order) {
        this.order = new Order(source.order);
      } else if (source.order_id) {
        this.order = {
          objectId: source.order_id,
          loaded: false
        };
      }

      if (source.vehicles) {
        this.vehicles = [];
        source.vehicles.forEach(vehicleSource => {
          let vehicle = new Vehicle(vehicleSource);
          this.vehicles.push(vehicle);
        });
      }

      if (source.operators) {
        this.operators = [];
        source.operators.forEach(operatorSource => {
          let operator = new Operator(operatorSource);
          this.operators.push(operator);
        });
      }
      this.startDate = source.start_date;
      this.position = source.position;
      this.description = source.description;
      this.overnight = source.overnight;
      this.note = source.note;
      if (source.closing_date) {
        this.closedAt = new Date(source.closing_date);
      }
    }
  }

  clone(): PlannedOrder {
    let cloned: PlannedOrder = new PlannedOrder(null);
    cloned.order = this.order;
    cloned.note = this.note;
    cloned.description = this.description;
    cloned.operators = this.operators;
    cloned.vehicles = this.vehicles;
    cloned.startDate = this.startDate;

    cloned.plannedDay = this.plannedDay;
    cloned.position = this.position;
    cloned.overnight = this.overnight;
    return cloned;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    plannedDay: PlannedDay,
    position: number
  ): PlannedOrder {
    const formModel = formGroup.value;
    let startDate;
    if (formModel.startTime) {
      startDate = moment(plannedDay.date);
      let splits = formModel.startTime.split(":");
      startDate.set({
        hours: splits[0],
        minutes: splits[1]
      });
    }
    let plannedOrder: PlannedOrder = new PlannedOrder(null);
    plannedOrder.plannedDay = plannedDay;
    plannedOrder.objectId = formModel.objectId;
    plannedOrder.order = formModel.order;
    plannedOrder.operators = formModel.operators;
    plannedOrder.vehicles = formModel.vehicles;
    plannedOrder.startDate = startDate ? startDate.toDate() : null;
    plannedOrder.position = 0;
    plannedOrder.description = formModel.description;
    plannedOrder.note = formModel.note;
    plannedOrder.overnight = formModel.overnight;
    plannedOrder.position = position;
    return plannedOrder;
  }

  isClosed(): boolean {
    //mrosetti - Per retrocompatibilità, dove la data di chiusura era solamente sull'ordine
    return this.closedAt != null || this.order && (<Order>this.order).closedAt != null;
  }
}
