import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatDialogModule, MatSnackBarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssignmentModule } from './home/assignment/assignment.module';
import { DailyActivityModule } from './home/daily-activity/daily-activity.module';
import { ShowForDirectivesModule } from './shared/directives/show-for/show-for.module';
import { TokenInterceptor } from './shared/helpers/token.interceptor';
import { UnauthInterceptor } from './shared/helpers/unauth.interceptor';
import { ConfirmDialogModule } from './shared/modules/confirm-dialog/confirm-dialog.module';
import { OperatorSelectionModule } from './shared/modules/operator-selection/operator-selection.module';
import { VehicleSelectionModule } from './shared/modules/vehicle-selection/vehicle-selection.module';
import { AuthService } from './shared/services';
import { ServiceModule } from './shared/services/service.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.threeBounce,
  hasProgressBar: false,
  fgsPosition: POSITION.bottomCenter,
  fgsColor: "#2196f3"
};

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  // for development
  /*return new TranslateHttpLoader(
        http,
        '/start-javascript/sb-admin-material/master/dist/assets/i18n/',
        '.json'
    );*/
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
};

export function initUserFactory(authService: AuthService): Function {
  return () => authService.loadCurrentUser();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlayModule,
    ServiceModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true
    }),
    MatSnackBarModule,
    MatDialogModule,
    ConfirmDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ShowForDirectivesModule,
    OperatorSelectionModule,
    VehicleSelectionModule,
    AssignmentModule,
    DailyActivityModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initUserFactory,
      deps: [AuthService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: "it" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
