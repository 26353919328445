import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";

const ROUTES = {
  show: environment.baseUrl + "/api/orderedProduct",
  list: environment.baseUrl + "/api/orderedProducts",
  store: environment.baseUrl + "/api/orderedProduct",
  delete: environment.baseUrl + "/api/orderedProduct"
};

@Injectable()
export class LaravelOrderedProductService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/orderedProduct",
      list: environment.baseUrl + "/api/orderedProducts",
      store: environment.baseUrl + "/api/orderedProduct",
      delete: environment.baseUrl + "/api/orderedProduct"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(product_id: number, order_id: number, supposed_quantity: string, tech_note: string, comm_note: string, plant_note: string) {
    let ordered_product = {
      product_id: product_id,
      order_id: order_id,
      supposed_quantity: supposed_quantity,
      tech_note: tech_note,
      comm_note: comm_note,
      plant_note: plant_note
    };
    return this.httpClient.post(this.ROUTES.store, {
      ordered_product: ordered_product
    });
  }

  public update(id: number, product_id: number, order_id: number, supposed_quantity: string, tech_note: string, comm_note: string, plant_note: string) {
    let ordered_product = {
      id: id,
      product_id: product_id,
      order_id: order_id,
      supposed_quantity: supposed_quantity,
      tech_note: tech_note,
      comm_note: comm_note,
      plant_note: plant_note
    };
    return this.httpClient.put(this.ROUTES.store, {
      ordered_product: ordered_product
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
