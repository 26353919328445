import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
} from '@angular/material';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ShowForDirectivesModule } from 'src/app/shared/directives/show-for/show-for.module';
import { OperatorInListModule } from 'src/app/shared/modules/operator-in-list/operator-in-list.module';
import { OrderLegendaModule } from 'src/app/shared/modules/order-legenda/order-legenda.module';
import { ResourceActivityModule } from 'src/app/shared/modules/resource-activity/resource-activity.module';
import { ResourceIndicatorModule } from 'src/app/shared/modules/resource-indicator/resource-indicator.module';
import { VehicleInListModule } from 'src/app/shared/modules/vehicle-in-list/vehicle-in-list.module';

import { OrderStatusIndicatorModule } from '../../shared/modules/order-status-indicator/order-status-indicator.module';
import { OrderDetailModule } from '../orders/order-detail/order-detail.module';
import { OrdersModule } from '../orders/orders.module';
import { OrdersFilterModule } from './../../shared/modules/orders-filter/orders-filter.module';
import { DailyViewComponent } from './daily-view/daily-view.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderSelectionComponent } from './order-selection/order-selection.component';
import { PlannedOrderDialogComponent } from './planned-order-dialog/planned-order-dialog.component';
import { PlannedOrderComponent } from './planned-order/planned-order.component';
import { PlanningDashboardComponent } from './planning-dashboard/planning-dashboard.component';
import { PlanningDetailComponent } from './planning-detail/planning-detail.component';
import { PlanningRoutingModule } from './planning-routing.module';

registerLocaleData(localeIt);

@NgModule({
  imports: [
    CommonModule,
    PlanningRoutingModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatChipsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    OrderStatusIndicatorModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    OrderLegendaModule,
    ResourceIndicatorModule,
    OperatorInListModule,
    VehicleInListModule,
    OrdersModule,
    DragDropModule,
    OrdersFilterModule,
    ShowForDirectivesModule,
    ResourceActivityModule,
    OrderDetailModule
  ],
  declarations: [
    PlanningDashboardComponent,
    PlanningDetailComponent,
    PlannedOrderComponent,
    OrderSelectionComponent,
    OrderDetailsComponent,
    DailyViewComponent,
    PlannedOrderDialogComponent
  ],
  entryComponents: [
    PlanningDetailComponent,
    OrderSelectionComponent,
    PlannedOrderDialogComponent
  ],
  providers: [DatePipe],
  exports: [DailyViewComponent]
})
export class PlanningModule { }
