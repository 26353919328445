import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ActivityFilters } from '../../model';

@Injectable()
export class LaravelActivityService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/activity",
      list: environment.baseUrl + "/api/activities",
      store: environment.baseUrl + "/api/activity",
      delete: environment.baseUrl + "/api/activity",
      replacing: environment.baseUrl + "/api/replacing",
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, filters?: ActivityFilters, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filters) {
      if (filters.search) params["search"] = filters.search;
      if (filters.assigner) params["assigner_ids[]"] = [filters.assigner.objectId];
      if (filters.substitue) params["substitue_ids[]"] = [filters.substitue.objectId];
      if (filters.frequency) params["frequency"] = filters.frequency;
    }

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(name: string, description: string, type: string, area: string, note: string, assigner_id: number, substitute_id: number, frequency: string) {
    let activity: any = {
      name: name,
      description: description,
      type: type,
      area: area,
      note: note,
      assigner_id: assigner_id,
      substitute_id: substitute_id,
      frequency: frequency
    };
    return this.httpClient.post(this.ROUTES.store, {
      activity: activity
    });
  }

  public update(id: number, name: string, description: string, type: string, area: string, note: string, assigner_id: number, substitute_id: number, frequency: string) {
    let activity: any = {
      id: id,
      name: name,
      description: description,
      type: type,
      area: area,
      note: note,
      assigner_id: assigner_id,
      substitute_id: substitute_id,
      frequency: frequency
    };

    return this.httpClient.put(this.ROUTES.store, {
      activity: activity
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public replacing(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.get<any[]>(this.ROUTES.replacing, { params: params });
  }
}
