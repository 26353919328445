import { LaravelPlannedDayService } from "./../services/laravel/laravel-plannedDay.service";
import { CommonObject } from "./common-object.model";
import * as moment from "moment";
import { FormGroup } from "@angular/forms";
export class Resource extends CommonObject {
  unavStartDate?: Date;
  unavEndDate?: Date;
  unavNote?: string;

  constructor(source: any) {
    super(source);
    if (source) {
      if (source.unav_start_date) {
        this.unavStartDate = new Date(source.unav_start_date);
      }
      if (source.unav_end_date) {
        this.unavEndDate = new Date(source.unav_end_date);
      }
      this.unavNote = source.note;
    }
  }

  isUnavailable(day: Date = new Date()): boolean {
    return (
      this.unavStartDate &&
      moment(this.unavStartDate).isSameOrBefore(moment(day), "day") &&
      this.unavEndDate &&
      moment(this.unavEndDate).isSameOrAfter(moment(day), "day")
    );
  }

  static fromFormGroup(formGroup: FormGroup): Resource {
    const formModel = formGroup.value;

    let resource: Resource = new Resource(null);

    resource.unavStartDate = formModel.start_date;
    resource.unavEndDate = formModel.end_date;
    resource.unavNote = formModel.note;

    return resource;
  }
}
