import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ListResult } from "../helpers/listResult.interface";
import { Anagraphic } from "../model";
import { LaravelAnagraphicService } from "./laravel";

@Injectable()
export class AnagraphicService {
  constructor(private laravelAnagraphicService: LaravelAnagraphicService) {}

  public getAnagraphic(id: number, include?: string | string[]): Observable<Anagraphic> {
    return this.laravelAnagraphicService.getById(id, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return new Anagraphic(response);
      })
    );
  }

  public getAnagraphicByExternalId(code: string, unit: number, include?: string | string[]): Observable<Anagraphic> {
    return this.laravelAnagraphicService.getByExternalKey(code, unit, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return new Anagraphic(response);
      })
    );
  }

  public getAnagraphics(page: number, perPage: number, order: string, direction: string, filter?: string, include?: string | string[]): Observable<ListResult<Anagraphic>> {
    return this.laravelAnagraphicService.list(page, perPage, order, direction, filter, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<Anagraphic>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new Anagraphic(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new Anagraphic(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public getLinkedAnagraphics(anagraphic: Anagraphic): Observable<Anagraphic[]> {
    return this.laravelAnagraphicService.listAddresses(0, null, null, null, anagraphic.code).pipe(
      map(response => {
        return response.map(element => new Anagraphic(element));
      })
    );
  }
}
