import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { User } from '../model/user.model';
import { LaravelUserService } from './laravel';

@Injectable({ providedIn: "root" })
export class AuthService {
  private TOKEN_NAME = "Italbonifiche\\authToken";
  private accessToken;

  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private laravelUserService: LaravelUserService) {
    this.setToken(localStorage.getItem(this.TOKEN_NAME));
  }

  loadCurrentUser(): Promise<User> {
    return this.laravelUserService
      .getCurrentUser()
      .pipe(
        map(dto => {
          let user = new User(dto);
          this.currentUserSubject.next(user);
          return user;
        }),
        catchError(error => {
          if (error && error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              this.currentUserSubject.next(null);
              return Promise.resolve(null);
            }
          }
          return Promise.reject(error);
        })
      )
      .toPromise();
  }

  public login(username: string, password: string) {
    return this.laravelUserService.login(username, password).pipe(
      map(result => {
        this.setToken(result.token);
        this.currentUserSubject.next(new User(result.dto));
      })
    );
  }

  public logout() {
    this.currentUserSubject.next(null);
    localStorage.removeItem(this.TOKEN_NAME);
    this.accessToken = null;
  }

  public isAuthenticated(): Observable<boolean> {
    return of(this.getToken() != null);
  }

  public isAdmin(): Observable<boolean> {
    return this.getCurrentUser().pipe(map(user => user.isAdmin));
  }

  public isOffice(): Observable<boolean> {
    return this.getCurrentUser().pipe(map(user => user.isOffice));
  }

  public isViewer(): Observable<boolean> {
    return this.getCurrentUser().pipe(map(user => user.isViewer));
  }

  public isDriver(): Observable<boolean> {
    return this.getCurrentUser().pipe(map(user => user.isDriver));
  }

  getCurrentUser(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  public updateCurrentUser(user: User): Observable<User> {
    return this.laravelUserService.update(user.objectId, user.name, user.surname, user.email).pipe(
      map(result => {
        let user = new User(result);
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  private setToken(token) {
    localStorage.setItem(this.TOKEN_NAME, token);
    this.accessToken = token;
  }

  getToken(): string {
    return this.accessToken;
  }
}
