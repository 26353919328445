import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatProgressBarModule,
  MatTooltipModule,
} from '@angular/material';
import { NgObjectPipesModule } from 'angular-pipes';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';

import { OperatorInListModule } from '../operator-in-list/operator-in-list.module';
import { ResourceActivityModule } from '../resource-activity/resource-activity.module';
import { VehicleInListModule } from '../vehicle-in-list/vehicle-in-list.module';
import { BusyOperatorsDetailsComponent } from './busy-operators-details/busy-operators-details.component';
import { BusyVehiclesDetailsComponent } from './busy-vehicles-details/busy-vehicles-details.component';
import { ResourceIndicatorComponent } from './resource-indicator.component';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    OperatorInListModule,
    VehicleInListModule,
    NgObjectPipesModule,
    SatDatepickerModule,
    SatNativeDateModule,
    ResourceActivityModule
  ],
  declarations: [
    ResourceIndicatorComponent,
    BusyVehiclesDetailsComponent,
    BusyOperatorsDetailsComponent
  ],
  exports: [ResourceIndicatorComponent],
  entryComponents: [BusyVehiclesDetailsComponent, BusyOperatorsDetailsComponent]
})
export class ResourceIndicatorModule { }
