import { FormGroup } from '@angular/forms';

import { Resource } from './resource.model';

export enum OPERATOR_CATEGORY {
  driver = "driver",
  employee = "employee",
  external = "external"
}

export class Operator extends Resource {
  name: string;
  surname: string;
  patente_C: boolean = false;
  patente_E: boolean = false;
  ADR: boolean = false;
  preposto: boolean = false;
  gru: boolean = false;
  ragno: boolean = false;
  autospurgo: boolean = false;
  spazi_confinati: boolean = false;
  pictureUrl?: string;
  archived?: boolean = false;
  category?: OPERATOR_CATEGORY;
  generic?: boolean;

  constructor(source: any) {
    super(source);
    if (source) {
      this.name = source.name;
      this.surname = source.surname;
      this.pictureUrl = source.pictureUrl;
      this.patente_C = source.patente_C;
      this.patente_E = source.patente_E;
      this.ADR = source.ADR;
      this.preposto = source.preposto;
      this.ragno = source.ragno;
      this.autospurgo = source.autospurgo;
      this.gru = source.gru;
      this.spazi_confinati = source.spazi_confinati;
      this.archived = source.archived;
      this.category = source.category;
      this.generic = source.generic;
    }
  }

  static fromFormGroup(formGroup: FormGroup): Operator {
    const formModel = formGroup.value;

    let operator: Operator = new Operator(null);
    operator.createdAt = new Date();
    operator.name = formModel.name;
    operator.surname = formModel.surname;
    operator.patente_C = formModel.patente_C;
    operator.patente_E = formModel.patente_E;
    operator.ADR = formModel.ADR;
    operator.preposto = formModel.preposto;
    operator.gru = formModel.gru;
    operator.ragno = formModel.ragno;
    operator.autospurgo = formModel.autospurgo;
    operator.spazi_confinati = formModel.spazi_confinati;
    operator.category = formModel.category;
    return operator;
  }

  public static resolveCategoryIcon(category: string) {
    return category ? `assets/images/${category.toLowerCase()}.png` : '';
  }

  public static resolveCategoryLabel(category: string) {
    switch (category) {
      case OPERATOR_CATEGORY.driver: return "Autista"
      case OPERATOR_CATEGORY.employee: return "Operatore Impianto"
      case OPERATOR_CATEGORY.external: return "Trasportatore Terzo"
    }
  }

  get categoryLabel(): string {
    return Operator.resolveCategoryLabel(this.category);
  }

  get categoryIcon() {
    return Operator.resolveCategoryIcon(this.category);
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }
}
