import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { PlanningService } from "../../../shared/services";
import { PlannedDay } from "../../../shared/model/planned-day.model";

@Injectable()
export class PlanningDayResolverService implements Resolve<PlannedDay> {
  constructor(private planningService: PlanningService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlannedDay> {
    //let id = route.params["id"];
    let date = route.params["date"];
    return this.planningService.getPlannedDayByDate(date, ["orders.order", "orders.order.client", "orders.vehicles", "orders.operators", "orders.order.products.product"]);
  }
}
