import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";

import { PlanningService } from "../../../shared/services";

@Injectable()
export class CompiledDatesResolverService implements Resolve<Date[]> {
  constructor(private planningService: PlanningService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Date[]> {
    return this.planningService.getCompiledDates();
  }
}
