import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatProgressBarModule,
  MatTooltipModule,
} from '@angular/material';
import { OrderDetailModule } from 'src/app/home/orders/order-detail/order-detail.module';

import { OperatorInListModule } from '../operator-in-list/operator-in-list.module';
import { VehicleInListModule } from '../vehicle-in-list/vehicle-in-list.module';
import { OperatorActivitiesComponent } from './operator-activities/operator-activities.component';
import { ResourceActivityComponent } from './resource-activity.component';
import { VehicleActivitiesComponent } from './vehicle-activities/vehicle-activities.component';

@NgModule({
  declarations: [ResourceActivityComponent, OperatorActivitiesComponent, VehicleActivitiesComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    OrderDetailModule,
    VehicleInListModule,
    OperatorInListModule,
    OrderDetailModule
  ],
  entryComponents: [OperatorActivitiesComponent, VehicleActivitiesComponent],
  exports: [OperatorActivitiesComponent, VehicleActivitiesComponent]
})
export class ResourceActivityModule { }
