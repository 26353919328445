import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderLegendaComponent } from "./order-legenda.component";

@NgModule({
  imports: [CommonModule],
  declarations: [OrderLegendaComponent],
  exports: [OrderLegendaComponent]
})
export class OrderLegendaModule {}
