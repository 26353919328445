import { Vehicle } from "./../../../shared/model/vehicle.model";
import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-vehicle-in-list",
  templateUrl: "./vehicle-in-list.component.html",
  styleUrls: ["./vehicle-in-list.component.scss"]
})
export class VehicleInListComponent implements OnInit {
  @Input()
  vehicle: Vehicle;
  @Input()
  date: Date;
  @Input()
  showIcon: boolean = true;
  @Input()
  expandNote: boolean = false;

  constructor() { }

  ngOnInit() { }

  isUnavailable(): boolean {
    return this.vehicle ? this.vehicle.isUnavailable(this.date) : false;
  }
}
