import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

import { finalize } from '../../../../../node_modules/rxjs/operators';
import { addIfNotExists, getArrayDifference } from '../../../shared/helpers/util';
import { Operator, Order, Vehicle } from '../../../shared/model';
import { PlannedDay, PlannedOrder } from '../../../shared/model/planned-day.model';
import { AlertService } from '../../../shared/services';
import { PlanningService } from '../../../shared/services/planning.service';

@Component({
  selector: "app-planning-detail",
  templateUrl: "./planning-detail.component.html",
  styleUrls: ["./planning-detail.component.scss"]
})
export class PlanningDetailComponent implements OnInit {
  @ViewChild("ordersContainer")
  private ordersContainer: ElementRef;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  _plannedDay: PlannedDay;
  _hasUpdates: boolean = false;

  plannedDayForm: FormGroup;

  today = new Date();

  compiledDates: Date[];

  _operators: Operator[] = new Array<Operator>();
  totalOperators: number = 0;

  _vehicles: Vehicle[] = new Array<Vehicle>();
  totalVehicles: number = 0;

  private _selectedDay: Date;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private planningService: PlanningService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.route.data.subscribe(
      (data: {
        operators: Operator[];
        vehicles: Vehicle[];
        compiledDates: Date[];
        plannedDay: PlannedDay;
      }) => {
        this.operators = data.operators;
        this.vehicles = data.vehicles;
        this.compiledDates = data.compiledDates;
        this.plannedDay = data.plannedDay;
      }
    );

    this.route.params.subscribe(params => {
      if (params["date"]) {
        this.selectedDay = moment(params["date"]).toDate();
      }
    });
  }

  get plannedDay(): PlannedDay {
    return this._plannedDay;
  }

  set plannedDay(plannedDay: PlannedDay) {
    this._plannedDay = plannedDay;
    this.ngOnChanges();
  }

  get hasUpdates(): boolean {
    return this._hasUpdates;
  }

  set hasUpdates(hasUpdates: boolean) {
    this._hasUpdates = hasUpdates;
  }

  get operators(): Operator[] {
    return this._operators;
  }

  set operators(operators: Operator[]) {
    this._operators = operators;
    this.totalOperators = operators ? operators.length : 0;
  }

  get vehicles(): Vehicle[] {
    return this._vehicles;
  }

  set vehicles(vehicles: Vehicle[]) {
    this._vehicles = vehicles;
    this.totalVehicles = vehicles ? vehicles.length : 0;
  }

  get selectedDay(): Date {
    return this._selectedDay;
  }

  set selectedDay(selectedDay: Date) {
    this._selectedDay = selectedDay;
    if (this.plannedDayForm) {
      this.plannedDayForm.patchValue({
        date: selectedDay
      });
    }
  }

  notCompiledDatesFilter = (d: Date): boolean => {
    return !this.isDateAlreadyCompiled(d);
  };

  private isDateAlreadyCompiled(date: Date) {
    return (
      this.compiledDates &&
      this.compiledDates.find(d => moment(d).isSame(moment(date))) != null
    );
  }

  createForm() {
    let group = {
      date: new FormControl(this.selectedDay, {
        validators: Validators.required
      }),
      orders: this.fb.array([]),
      note: [""]
    };

    this.plannedDayForm = this.fb.group(group);
  }

  ngOnChanges() {
    if (this.plannedDayForm) {
      this.plannedDayForm.reset();
      this.resetOrderForms();
      if (this.plannedDay) {
        this.plannedDayForm.patchValue({
          date: this.plannedDay.date,
          note: this.plannedDay.note
        });
        this.plannedDayForm.setControl("orders", this.fb.array([]));

        this.plannedDayForm.get("date").disable();
        if (this.plannedDay.orders) {
          let sortedOrders = this.plannedDay.orders.sort(
            (a: PlannedOrder, b: PlannedOrder) => {
              return a.position < b.position ? -1 : 1;
            }
          );
          sortedOrders.forEach(plannedOrder => this.addOrder(plannedOrder));
        } else {
          this.addOrder(null);
        }
      } else {
        this.plannedDayForm.get("date").enable();
        this.addOrder(null);
      }
    }
  }

  prepareSavePlannedDay(): PlannedDay {
    let savePlannedDay: PlannedDay = PlannedDay.fromFormGroup(
      this.plannedDayForm
    );
    savePlannedDay.orders = this.getOrdersFromForm(savePlannedDay);

    if (this.plannedDay) {
      savePlannedDay.objectId = this.plannedDay.objectId;
      savePlannedDay.date = this.plannedDay.date;
    }
    return savePlannedDay;
  }

  get ordersForms(): FormArray {
    return this.plannedDayForm.get("orders") as FormArray;
  }

  resetOrderForms() {
    while (this.ordersForms.length !== 0) {
      this.ordersForms.removeAt(0);
    }
  }

  addOrder(plannedOrder?: PlannedOrder) {
    const group = {
      objectId: [plannedOrder ? plannedOrder.objectId : null],
      order: ["", Validators.required],
      operators: this.fb.array([]),
      vehicles: this.fb.array([]),
      description: [""],
      position: [""],
      startTime: [""],
      overnight: [""],
      note: [""]
    };

    const orderCtrl = this.fb.group(group);

    if (plannedOrder) {
      orderCtrl.patchValue({
        order: plannedOrder.order,
        description: plannedOrder.description,
        position: plannedOrder.position,
        overnight: plannedOrder.overnight,
        startTime: plannedOrder.startDate
          ? moment(plannedOrder.startDate)
            .format("HH:mm")
            .toString()
          : "",
        note: plannedOrder.note
      });

      if (plannedOrder.operators) {
        plannedOrder.operators.forEach(operator =>
          (orderCtrl.get("operators") as FormArray).push(
            this.fb.control(operator)
          )
        );
      }

      if (plannedOrder.vehicles) {
        plannedOrder.vehicles.forEach(vehicle =>
          (orderCtrl.get("vehicles") as FormArray).push(
            this.fb.control(vehicle)
          )
        );
      }
    }
    this.ordersForms.push(orderCtrl);
  }

  removeOrder(index) {
    this.ordersForms.removeAt(index);
    this.plannedDayForm.markAsDirty();
  }

  get selectedOrders(): Order[] {
    let orders: Order[] = new Array<Order>();
    this.ordersForms.controls.forEach(orderForm => {
      orders.push(orderForm.get("order").value);
    });
    return orders;
  }

  getAvailableOperators(day: Date): Operator[] {
    return getArrayDifference(this.operators, [...this.getBusyOperators(), ...this.getUnavOperators(day)]);
  }

  getBusyOperators(): Operator[] {
    let busyOperators: Operator[] = [];

    this.ordersForms.controls.forEach(orderForm => {
      let operatorsForm = orderForm.get("operators") as FormArray;
      if (operatorsForm) {
        let currentOperators = operatorsForm.value;
        if (currentOperators) {
          currentOperators.forEach(o => addIfNotExists(busyOperators, o));
        }
      }
    });
    return busyOperators;
  }

  getUnavOperators(day: Date): Operator[] {
    return this.operators.filter(operator => operator.isUnavailable(day));
  }

  getAvailableVehicles(day: Date): Vehicle[] {
    return getArrayDifference(this.vehicles, [...this.getBusyVehicles(), ...this.getUnavVehicles(day)]);
  }

  getBusyVehicles(): Vehicle[] {
    let busyVehicles: Vehicle[] = [];
    this.ordersForms.controls.forEach(orderForm => {
      let vehiclesForm = orderForm.get("vehicles") as FormArray;
      if (vehiclesForm) {
        let currentVehicles = vehiclesForm.value;
        if (currentVehicles) {
          currentVehicles.forEach(o => addIfNotExists(busyVehicles, o));
        }
      }
    });
    return busyVehicles;
  }

  getUnavVehicles(day: Date): Vehicle[] {
    return this.vehicles.filter(vehicle => vehicle.isUnavailable(day));
  }

  private getOrdersFromForm(plannedDay: PlannedDay): PlannedOrder[] {
    let plannedOrders: PlannedOrder[] = [];

    this.ordersForms.controls.forEach((orderForm, position) => {
      plannedOrders.push(
        PlannedOrder.fromFormGroup(orderForm, plannedDay, position)
      );
    });
    return plannedOrders;
  }

  revert() {
    this.ngOnChanges();
  }

  plannedDaySave() {
    this.loadingSubject.next(true);
    let unsavedEntity = this.prepareSavePlannedDay();
    if (unsavedEntity.objectId) {
      this.planningService.updatePlannedDay(unsavedEntity).subscribe(
        savedEntity => {
          this.planningService
            .getPlannedDay(savedEntity.objectId, [
              "orders.order",
              "orders.vehicles",
              "orders.operators",
              "orders.order.client"
            ])
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe(reloadedEntity => {
              this.plannedDay = reloadedEntity;
              console.log(`Planned day ${this.plannedDay.date} updated`);
              this.hasUpdates = true;
              this.alertService.showConfirmMessage(
                "Pianificazione giornaliera aggiornata"
              );
            });
        },
        error => {
          console.error(
            `Error while updating planned day ${this.plannedDay.date}`,
            error
          );
          this.loadingSubject.next(false);
        }
      );
    } else {
      this.planningService.createPlannedDay(unsavedEntity).subscribe(
        savedEntity => {
          this.planningService
            .getPlannedDay(savedEntity.objectId, [
              "orders.order",
              "orders.vehicles",
              "orders.operators",
              "orders.order.client"
            ])
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe(reloadedEntity => {
              this.plannedDay = reloadedEntity;
              console.log(`Planned day ${this.plannedDay.date} added`);
              this.hasUpdates = true;
              this.alertService.showConfirmMessage(
                "Pianificazione giornaliera aggiunta"
              );
            });
        },
        error => {
          console.error(
            `Error while adding planned day ${this.plannedDay.date}`,
            error
          );
          this.loadingSubject.next(false);
        }
      );
    }
  }

  deletePlannedDay() {
    this.alertService
      .showConfirmDialog(
        "Conferma eliminazione",
        "Sei sicuro di voler eliminare la pianificazione giornaliera?"
      )
      .subscribe(result => {
        if (result) {
          this.doDelete();
        }
      });
  }

  private doDelete() {
    this.loadingSubject.next(true);
    this.planningService.deletePlannedDay(this.plannedDay).subscribe(() => {
      console.log("Planned day archived");
      this.alertService.showConfirmMessage(
        "Pianificazione giornaliera eliminata"
      );
      this.hasUpdates = true;
      this.loadingSubject.next(false);
      this.goToDashboard();
    });
  }

  private goToDailyView() {
    this.router.navigate(
      ["/dailyView", moment(this.plannedDay.date).format("YYYY-MM-DD")],
      {
        relativeTo: this.route
      }
    );
  }

  private goToDashboard() {
    this.router.navigate(["planning"]);
  }

  close() {
    this.plannedDay ? this.goToDailyView() : this.goToDashboard();
  }

  scrollToBottom() {
    try {
      setTimeout(() => {
        //Timeout necessario per fare lo scroll alla fine del rendering della view, altrimenti non scrolla
        this.ordersContainer.nativeElement.scrollTop = this.ordersContainer.nativeElement.scrollHeight;
      }, 0);
    } catch (err) {
      console.log("Error on scrolling", err);
    }
  }
}
