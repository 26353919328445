import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../helpers/util';
import { DailyReportDTO, TodoFilters } from '../../model';

@Injectable()
export class LaravelTodoService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/todo",
      list: environment.baseUrl + "/api/todos",
      store: environment.baseUrl + "/api/todo",
      delete: environment.baseUrl + "/api/todo",
      daily_summary: environment.baseUrl + "/api/daily_summary",
      update_admin_note: environment.baseUrl + "/api/todo/update_admin_note",
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, filters?: TodoFilters, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filters) {
      if (filters.activity) params["activity_ids[]"] = [filters.activity.objectId];
      if (filters.executor) params["executor_ids[]"] = [filters.executor.objectId];
      if (filters.assigner) params["assigner_ids[]"] = [filters.assigner.objectId];
      if (filters.expirationStart) params["expiration_start"] = formatDateForBackend(filters.expirationStart);
      if (filters.expirationEnd) params["expiration_end"] = formatDateForBackend(filters.expirationEnd);
      if (filters.executionStart) params["execution_start"] = formatDateForBackend(filters.executionStart);
      if (filters.executionEnd) params["execution_end"] = formatDateForBackend(filters.executionEnd);
      if (filters.searchQuery) params["search_query"] = filters.searchQuery;
      if (filters.status) params["status"] = filters.status;
    }

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(activity_id: number, done: boolean, expiration_date: Date, execution_date: Date, note: string) {
    let todo: any = {
      activity_id,
      done,
      expiration_date: formatDateForBackend(expiration_date),
      execution_date: formatDateForBackend(execution_date),
      note: note
    };
    return this.httpClient.post(this.ROUTES.store, {
      todo
    });
  }

  public update(id: number, activity_id: number, done: boolean, expiration_date: Date, execution_date: Date, note: string) {
    let todo: any = {
      id,
      activity_id,
      done,
      expiration_date: formatDateForBackend(expiration_date),
      execution_date: formatDateForBackend(execution_date),
      note: note
    };

    return this.httpClient.put(this.ROUTES.store, {
      todo
    });
  }

  public updateAdminNote(id: number, admin_note: string) {

    return this.httpClient.put(this.ROUTES.update_admin_note, {
      id,
      admin_note
    });
  }


  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public getDailySummary(from: Date, to: Date): Observable<DailyReportDTO[]> {
    let params = {
      from: formatDateForBackend(from),
      to: formatDateForBackend(to)
    };
    return this.httpClient.get<DailyReportDTO[]>(this.ROUTES.daily_summary, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }
}
