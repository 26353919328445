import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { DailyActivity, DailyActivityFilters, Todo, User } from 'src/app/shared/model';
import { AlertService, AuthService, TodoService } from 'src/app/shared/services';

@Component({
  selector: 'app-daily-activity',
  templateUrl: './daily-activity.component.html',
  styleUrls: ['./daily-activity.component.scss']
})
export class DailyActivityComponent implements OnInit {
  private loading = new BehaviorSubject<boolean>(false);
  public loading$ = this.loading.asObservable();

  users: User[];
  currentUser: User;

  private _dailyActivity: DailyActivity;

  filters: DailyActivityFilters;
  form: FormGroup;

  selectedAreas = {
    executed: [],
    unexecuted: []
  }

  executedAreas: string[];
  unexecutedAreas: string[];

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private authService: AuthService, private todoService: TodoService, private alertService: AlertService) {
    this.users = this.route.snapshot.data.users || [];

    this.authService
      .getCurrentUser().pipe(take(1)).subscribe(currentUser => this.currentUser = currentUser);
    this.filters = {
      assigner: this.currentUser,
      from: new Date(),
      to: new Date()
    }
    this._createForm();
  }

  ngOnInit() {
    this.loadDailyActivity();
  }

  private _createForm() {
    let group = {
      executed: this.fb.array([]),
      unexecuted: this.fb.array([]),
      note: [""]
    }

    this.form = this.fb.group(group);
  }


  ngOnChanges() {
    if (this.form) {
      this.form.reset();
      this.resetTodosForm();
      if (this.dailyActivity) {
        this.form.patchValue({
          note: this.dailyActivity.note
        });
        if (this.dailyActivity.todos) {
          this.dailyActivity.todos.forEach(todo =>
            this._addTodo(todo)
          );
        }
      }
    }
  }

  reset() {
    this.ngOnChanges();
  }

  private _addTodo(todo: Todo) {
    let group = {
      todo: [""],
      done: [""],
      note: [""]
    };

    const todoCtrl = this.fb.group(group);

    todoCtrl.patchValue({
      todo: todo,
      done: todo.done,
      note: todo.note
    });
    if (todo.executed) {
      this.executedForm.push(todoCtrl);
    } else {
      this.unexecutedForm.push(todoCtrl);
    }
  }

  get executedForm(): FormArray {
    return this.form.get("executed") as FormArray;
  }

  get unexecutedForm(): FormArray {
    return this.form.get("unexecuted") as FormArray;
  }

  resetTodosForm() {
    while (this.executedForm.length !== 0) {
      this.executedForm.removeAt(0);
    }
    while (this.unexecutedForm.length !== 0) {
      this.unexecutedForm.removeAt(0);
    }
  }

  loadDailyActivity(silent: boolean = false) {
    this.loading.next(true);
    forkJoin(
      this.todoService.getUserDailyActivity(this.filters.assigner || this.currentUser, this.filters.from || this.filters.to || new Date(), this.filters.replacing, ["todos.activity.assigner", "todos.executor"]).pipe(
        tap((dailyActivity) => {
          this.dailyActivity = dailyActivity;
          if (!silent) {

            this._resetAreaFilters();

            if (dailyActivity && dailyActivity.todos) {
              this.executedAreas = Array.from(new Set(dailyActivity.todos.filter(t => t.executed).map(t => t.activity.area)));
              this.selectedAreas.executed = [...this.executedAreas];
              this.unexecutedAreas = Array.from(new Set(dailyActivity.todos.filter(t => !t.executed).map(t => t.activity.area)));
              this.selectedAreas.unexecuted = [...this.unexecutedAreas];
            }
          }
        }))

    ).pipe(
      finalize(() => this.loading.next(false))
    )
      .subscribe();
  }

  reload(filters) {
    this.filters = filters;
    this.loadDailyActivity();
  }

  save() {
    this.loading.next(true);
    let savingDailyActivity = DailyActivity.fromFormGroup(this.form, this.dailyActivity);
    savingDailyActivity.todos.forEach(todo => todo.executor = this.currentUser);
    this.todoService.saveDay(savingDailyActivity).pipe(finalize(() => this.loading.next(false))).subscribe(() => {
      this.alertService.showConfirmMessage('Attività giornaliera aggiornata');
      this.loadDailyActivity(true);
    }, error => {
      this.alertService.showErrorMessage('Errore nell\'aggiornamento dell\'attività giornaliera', error);
    })
  }

  get dailyActivity(): DailyActivity {
    return this._dailyActivity;
  }

  set dailyActivity(dailyActivity: DailyActivity) {
    this._dailyActivity = dailyActivity;
    this.ngOnChanges();
  }

  private _resetAreaFilters() {
    this.selectedAreas.executed = [];
    this.selectedAreas.unexecuted = [];
    this.executedAreas = [];
    this.unexecutedAreas = [];
  }
}
