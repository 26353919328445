import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
} from '@angular/material';
import { NgObjectPipesModule } from 'angular-pipes';

import { OrdersFilterComponent } from './orders-filter.component';
import { TypeTranslatorPipe } from './type-translator.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    NgObjectPipesModule
  ],
  declarations: [OrdersFilterComponent, TypeTranslatorPipe],
  exports: [OrdersFilterComponent]
})
export class OrdersFilterModule { }
