import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Operator, OPERATOR_CATEGORY } from 'src/app/shared/model';
import {
  OperatorActivitiesComponent,
} from 'src/app/shared/modules/resource-activity/operator-activities/operator-activities.component';

@Component({
  selector: "app-busy-operators-details",
  templateUrl: "./busy-operators-details.component.html",
  styleUrls: ["./busy-operators-details.component.scss"]
})
export class BusyOperatorsDetailsComponent implements OnInit {
  all: Operator[];
  busy: Operator[];
  selectedDate: Date;

  constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.all = this.data.allOperators;
      this.busy = this.data.busyOperators;
      this.selectedDate = this.data.selectedDate;
    }
  }

  // get availableOperators(): Operator[] {
  //   return getArrayDifference(this.allOperators, this.busyOperators);
  // }

  getMapKeys(map: Map<string, any>): string[] {
    return Array.from(map.keys());
  }


  get allOperators(): Map<string, Operator[]> {
    return this.groupOperatorsByType(this.all);
  }

  filterAvailable(operators: Operator[]): Operator[] {
    return operators
      ? operators.filter(o => !this.busy.find(b => Operator.compare(b, o))).sort((o1, o2) => (o1.fullName < o2.fullName ? -1 : 1))
      : [];
  }

  filterBusy(operators: Operator[]): Operator[] {
    return operators
      ? operators.filter(o => this.busy.find(b => Operator.compare(b, o))).sort((o1, o2) => (o1.fullName < o2.fullName ? -1 : 1))
      : [];
  }

  private sortOrder = [
    OPERATOR_CATEGORY.employee,
    OPERATOR_CATEGORY.driver,
    OPERATOR_CATEGORY.external,
  ];

  private groupOperatorsByType(operators: Operator[]): Map<string, Operator[]> {
    let map: Map<string, Operator[]> = new Map<string, Operator[]>();
    this.sortOrder.forEach(key => {
      map.set(key, []);
    });

    if (operators) {
      operators.forEach(operator => {
        if (!operator.generic) {
          let array = map.get(operator.category) || [];
          array.push(operator);
          map.set(operator.category, array);
        }
      });
    }

    return map;
  }

  categoryIcon(category: string) {
    return Operator.resolveCategoryIcon(category);
  }

  categoryLabel(category: string) {
    return Operator.resolveCategoryLabel(category);
  }

  onRangeSelected(operator: Operator, event) {
    if (event && event.value) {
      this.openActivitiesDialog(operator, event.value.begin, event.value.end);
    }
  }

  private openActivitiesDialog(operator: Operator, start: Date, end: Date) {
    let data = {
      operator: operator,
      start: start,
      end: end
    };
    this.dialog.open(OperatorActivitiesComponent, {
      data,
      width: "98%",
      height: "95vh",
      //maxHeight: "76vh",
      panelClass: ["no-padding", "activities"]
    });
  }
}
