import { FormGroup } from '@angular/forms';
import { Operator } from 'src/app/shared/model';

import { CommonObject } from './common-object.model';

export enum ROLES {
  ADMIN = "admin",
  VIEWER = "viewer",
  DRIVER = "driver",
  OFFICE = "office"
};

export class User extends CommonObject {
  email: string;
  name: string;
  surname: string;
  operator: Operator;
  userRoles: ROLES[] = [];

  constructor(source) {
    super(source);
    if (source) {
      this.email = source.email;
      this.name = source.name;
      this.surname = source.surname;
      if (source.user_roles) {
        this.userRoles = source.user_roles;
      }
      if (source.operator) {
        this.operator = new Operator(source.operator);
      }
    }
  }

  static fromFormGroup(formGroup: FormGroup): User {
    const formModel = formGroup.value;

    let user: User = new User(null);
    user.createdAt = new Date();
    user.name = formModel.name;
    user.surname = formModel.surname;
    user.email = formModel.email;
    if (formModel.admin) {
      user.userRoles.push(ROLES.ADMIN);
    }
    if (formModel.viewer) {
      user.userRoles.push(ROLES.VIEWER);
    }
    if (formModel.driver) {
      user.userRoles.push(ROLES.DRIVER);
    }
    if (formModel.office) {
      user.userRoles.push(ROLES.OFFICE);
    }

    user.operator = formModel.operator;
    return user;
  }

  hasRole(role: ROLES): boolean {
    return this.userRoles.indexOf(role) != -1;
  }

  get isAdmin(): boolean {
    return this.hasRole(ROLES.ADMIN);
  }

  get isViewer(): boolean {
    return this.hasRole(ROLES.VIEWER);
  }

  get isDriver(): boolean {
    return this.hasRole(ROLES.DRIVER);
  }

  get isOffice(): boolean {
    return this.hasRole(ROLES.OFFICE);
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }
}
