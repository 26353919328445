import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ListResult } from '../helpers/listResult.interface';
import { formatDateForBackend } from '../helpers/util';
import { User } from '../model';
import { DailyActivity, DailyActivityFilters, DailyReport, Todo, TodoFilters } from '../model/activity.model';
import { LaravelDailyActivityService, LaravelTodoService } from './laravel';

@Injectable()
export class TodoService {
  constructor(private laravelTodoService: LaravelTodoService, private laravelDailyActivityService: LaravelDailyActivityService) { }

  //Todos
  public getTodo(id: number): Observable<Todo> {
    return this.laravelTodoService.getById(id).pipe(map(response => new Todo(response)));
  }

  public getTodos(page: number, perPage: number, order: string, direction: string, filters?: TodoFilters, include?: string | string[]): Observable<ListResult<Todo>> {
    return this.laravelTodoService.list(page, perPage, order, direction, filters, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<Todo>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new Todo(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new Todo(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public createTodo(todo: Todo): Observable<Todo> {
    return this.laravelTodoService.create(
      todo.activity ? todo.activity.objectId : null,
      todo.done,
      todo.expirationDate,
      todo.executionDate,
      todo.note
    ).pipe(
      map(result => new Todo(result)))
  }

  public updateTodo(todo: Todo): Observable<Todo> {
    return this.laravelTodoService.update(
      todo.objectId,
      todo.activity ? todo.activity.objectId : null,
      todo.done,
      todo.expirationDate,
      todo.executionDate,
      todo.note
    ).pipe(
      map(result => new Todo(result)))
  }

  public updateAdminNote(todo: Todo, adminNote: string): Observable<Todo> {
    return this.laravelTodoService.updateAdminNote(
      todo.objectId,
      adminNote
    ).pipe(
      map(result => new Todo(result)))
  }

  public deleteTodo(todo: Todo): Observable<Todo> {
    return this.laravelTodoService.delete(todo.objectId).pipe(
      map(result => {
        return new Todo(result);
      })
    );
  }

  public getDailyReports(from: Date, to: Date): Observable<DailyReport[]> {
    return this.laravelTodoService.getDailySummary(from, to).pipe(map(dtos => dtos.map(dto => new DailyReport(dto))));
  }

  //Daily Activities
  public getDailyActivity(id: number): Observable<DailyActivity> {
    return this.laravelDailyActivityService.getById(id).pipe(map(response => new DailyActivity(response)));
  }

  public getUserDailyActivity(user: User, date: Date, replacing: User[], include?: string | string[]): Observable<DailyActivity> {
    let fixedIncludes = typeof include === "string" ? [include] : include || [];
    if (replacing && replacing.length) {
      fixedIncludes.push('todos.activity.assigner', 'todos.activity.substitute');
    }

    const obs: Observable<any>[] = [];
    obs.push(
      this.getDailyActivities(1, 0, null, null, { assigner: user, from: date, to: date }, fixedIncludes).pipe(map(results => {
        return results.total > 0 ? results.data[0] : DailyActivity.empty(date, user);
      }))
    )
    const todosObs = (replacing || []).map(r => this.getReplacingTodos(user, date, r, fixedIncludes));
    obs.push(...todosObs);
    return forkJoin(obs).pipe(map((results) => {
      const dailyActivity = results.shift();
      results.forEach(todos => {
        dailyActivity.todos.push(...todos);
      })
      return dailyActivity;
    }))
  }

  private getReplacingTodos(user: User, date: Date, replacing: User, includes: string[]): Observable<Todo[]> {
    return this.getDailyActivities(1, 0, null, null, { assigner: replacing, from: date, to: date }, includes).pipe(
      map(results => results.total > 0 ? results.data[0] : null),
      filter(results => results != null),
      map(daily => daily.todos),
      map(todos => todos.filter(todo => User.compare(todo.activity.substitute, user)))
    );
  }

  public saveDay(dailyActivity: DailyActivity): Observable<DailyActivity> {
    return this.laravelDailyActivityService.saveDay(
      dailyActivity.objectId,
      dailyActivity.date,
      dailyActivity.assigner ? dailyActivity.assigner.objectId : null,
      dailyActivity.note,
      this._mapTodosToDtos(dailyActivity.todos)
    ).pipe(
      map(result => new DailyActivity(result)))
  }

  public getDailyActivities(page: number, perPage: number, order: string, direction: string, filters?: DailyActivityFilters, include?: string | string[]): Observable<ListResult<DailyActivity>> {
    return this.laravelDailyActivityService.list(page, perPage, order, direction, filters, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<DailyActivity>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new DailyActivity(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new DailyActivity(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  private _mapTodosToDtos(todos: Todo[]): any[] {
    if (todos) {
      return todos.map(todo => {
        return {
          id: todo.objectId,
          done: todo.done,
          activity_id: todo.activity ? todo.activity.objectId : null,
          expiration_date: formatDateForBackend(todo.expirationDate),
          execution_date: formatDateForBackend(todo.executionDate),
          note: todo.note
        }
      })
    }
    return null;
  }
  public createDailyActivity(dailyActivity: DailyActivity): Observable<DailyActivity> {
    return this.laravelDailyActivityService.create(
      dailyActivity.date,
      dailyActivity.assigner ? dailyActivity.assigner.objectId : null,
      dailyActivity.note,
      this._mapTodosToDtos(dailyActivity.todos)
    ).pipe(
      map(result => new DailyActivity(result)))
  }

  public updateDailyActivity(dailyActivity: DailyActivity): Observable<DailyActivity> {
    return this.laravelDailyActivityService.update(
      dailyActivity.objectId,
      dailyActivity.date,
      dailyActivity.assigner ? dailyActivity.assigner.objectId : null,
      dailyActivity.note,
      this._mapTodosToDtos(dailyActivity.todos)
    ).pipe(
      map(result => new DailyActivity(result)))
  }

  public deleteDailyActivity(dailyActivity: DailyActivity): Observable<DailyActivity> {
    return this.laravelDailyActivityService.delete(dailyActivity.objectId).pipe(
      map(result => {
        return new DailyActivity(result);
      })
    );
  }

  public exportDailyActivities(filters?: DailyActivityFilters): Observable<Blob> {
    return this.laravelDailyActivityService.export(filters)
      .pipe(map(data => new Blob([data], { type: "text/csv" })));
  }
}
