import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";

@Injectable()
export class LaravelAnagraphicService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/anagraphic",
      byExternalKey: environment.baseUrl + "/api/anagraphicByExternalKey",
      list: environment.baseUrl + "/api/anagraphics",
      listAddresses: environment.baseUrl + "/api/listAddresses"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public getByExternalKey(code: string, unit: number, include?: string[]): Observable<any> {
    let params = { code: code, unit: "" + unit };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.byExternalKey, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, search_query?: string, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (search_query) params["search_query"] = "" + search_query;
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public listAddresses(page: number, per_page: number, order: string, direction: string, code: string, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (code) params["code"] = "" + code;
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.listAddresses, {
      params: new HttpParams({ fromObject: params })
    });
  }
}
