import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlanningModule } from '../planning/planning.module';
import { AssignmentRoutingModule } from './assignment-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AssignmentRoutingModule,
    PlanningModule
  ]
})
export class AssignmentModule { }
