import { OrderService } from "../../../shared/services/order.service";
import { Component, OnInit, Input } from "@angular/core";
import { Order } from "../../../shared/model";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"]
})
export class OrderDetailsComponent implements OnInit {
  private loadingDetails = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingDetails.asObservable();

  private _order: Order;
  constructor(private orderService: OrderService) {}

  ngOnInit() {}

  get order(): Order {
    return this._order;
  }

  @Input()
  set order(order: Order) {
    this._order = order;
    if (!order.orderedProducts || order.orderedProducts.length == 0) {
      this.loadOrderDetails();
    }
  }

  private loadOrderDetails() {
    if (this.order) {
      this.loadingDetails.next(true);
      this.orderService
        .getOrder(this.order.objectId, ["products.product", "client"])
        .pipe(finalize(() => this.loadingDetails.next(false)))
        .subscribe(loadedOrder => {
          this._order = loadedOrder;
        });
    }
  }
}
