import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../helpers/util';
import { DailyActivityFilters } from '../../model';

@Injectable()
export class LaravelDailyActivityService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/daily_activity",
      list: environment.baseUrl + "/api/daily_activities",
      store: environment.baseUrl + "/api/daily_activity",
      delete: environment.baseUrl + "/api/daily_activity",
      save_day: environment.baseUrl + "/api/save_day",
      create_dailies: environment.baseUrl + "/api/create_dailies",
      export: environment.baseUrl + "/api/daily_activities/export",
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, filters?: DailyActivityFilters, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;
    if (filters) {
      params["user_ids[]"] = []
      if (filters.assigner) params["user_ids[]"].push(filters.assigner.objectId);
      if (filters.from) params["from"] = formatDateForBackend(filters.from);
      if (filters.to) params["to"] = formatDateForBackend(filters.to);
      if (filters.replacing) params["user_ids[]"].push(filters.replacing.map(user => user.objectId));
    }

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }


  public create(date: Date, user_id: number, note: string, todos: any[]) {
    let daily_activity: any = {
      date,
      user_id,
      todos,
      note: note
    };
    return this.httpClient.post(this.ROUTES.store, { daily_activity });
  }

  public update(id: number, date: Date, user_id: number, note: string, todos: any[]) {
    let daily_activity: any = {
      id,
      date,
      user_id,
      todos,
      note: note
    };

    return this.httpClient.put(this.ROUTES.store, { daily_activity });
  }

  public saveDay(id: number, date: Date, user_id: number, note: string, todos: any[]) {
    let daily_activity: any = {
      id,
      date: formatDateForBackend(date),
      user_id,
      todos,
      note: note
    };

    return this.httpClient.put(this.ROUTES.save_day, { daily_activity });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }

  public createDailies(from: Date, to: Date) {
    return this.httpClient.post(this.ROUTES.create_dailies, {
      from: formatDateForBackend(from),
      to: formatDateForBackend(to)
    });
  }

  public export(
    filters?: DailyActivityFilters
  ): Observable<Blob> {
    let params = {};
    if (filters) {
      params["user_ids[]"] = []
      if (filters.assigner) params["user_ids[]"].push(filters.assigner.objectId);
      if (filters.from) params["from"] = formatDateForBackend(filters.from);
      if (filters.to) params["to"] = formatDateForBackend(filters.to);
      if (filters.replacing) params["user_ids[]"].push(filters.replacing.map(user => user.objectId));
    }

    return this.httpClient.get(this.ROUTES.export, {
      params: new HttpParams({
        fromObject: params
      }),
      responseType: "blob"
    });
  }
}
