import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';
import { ShowForDirectivesModule } from 'src/app/shared/directives/show-for/show-for.module';
import { OrderLegendaModule } from 'src/app/shared/modules/order-legenda/order-legenda.module';

import { OrderStatusIndicatorModule } from '../../shared/modules/order-status-indicator/order-status-indicator.module';
import { OrdersFilterModule } from './../../shared/modules/orders-filter/orders-filter.module';
import { DeletedOrderDialogComponent } from './deleted-order-dialog/deleted-order-dialog.component';
import { OrderDetailModule } from './order-detail/order-detail.module';
import { OrdersDashboardComponent } from './orders-dashboard/orders-dashboard.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrdersRoutingModule } from './orders-routing.module';

@NgModule({
  imports: [
    CommonModule,
    OrdersRoutingModule,
    MatButtonToggleModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatMenuModule,
    MatSortModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    OrderStatusIndicatorModule,
    OrderLegendaModule,
    OrdersFilterModule,
    ShowForDirectivesModule,
    OrderDetailModule
  ],
  declarations: [
    OrdersListComponent,
    OrdersDashboardComponent,
    DeletedOrderDialogComponent
  ],
  entryComponents: [
    DeletedOrderDialogComponent
  ]
})
export class OrdersModule { }
