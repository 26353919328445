import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Operator } from '../../../shared/model/operator.model';
import { OperatorService } from '../../../shared/services';

@Injectable()
export class OperatorsResolverService implements Resolve<Operator[]> {
  constructor(private operatorService: OperatorService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Operator[]> {
    return this.operatorService.getOperators(0, 99999, null, null, false, true).pipe(
      map(results => {
        return results.data;
      })
    );
  }
}
