import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { Operator, OPERATOR_CATEGORY } from '../../model';

@Component({
  selector: 'app-operator-selection',
  templateUrl: './operator-selection.component.html',
  styleUrls: ['./operator-selection.component.scss']
})
export class OperatorSelectionComponent implements OnInit {

  all: Operator[];
  busy: Operator[];
  selectedDate: Date;

  search: FormControl = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<OperatorSelectionComponent>) { }

  ngOnInit() {
    if (this.data) {
      this.all = this.data.allOperators;
      this.busy = this.data.busyOperators;
      this.selectedDate = this.data.selectedDate;
    }
  }

  getMapKeys(map: Map<string, any>): string[] {
    return Array.from(map.keys());
  }

  get filteredOperators() {
    return this._filterOperator(
      this.search.value,
      this.all
    );
  }

  private _filterOperator(
    value: string | Operator,
    operators: Operator[]
  ): Operator[] {
    if (value instanceof Operator) {
      return [value];
    }

    if (!value) {
      return operators;
    }

    const filterValue = value.toLowerCase();
    return operators.filter(operator =>
      operator.fullName
        ? operator.fullName.toLowerCase().indexOf(filterValue) > -1
        : false
    );
  }

  get allOperators(): Map<string, Operator[]> {
    return this.groupOperatorsByCategory(this.all);
  }

  filterAvailable(operators: Operator[]): Operator[] {
    return operators
      ? operators.filter(v => !this.busy.find(b => Operator.compare(b, v))).sort((o1, o2) => (o1.fullName < o2.fullName ? -1 : 1))
      : [];
  }

  filterBusy(operators: Operator[]): Operator[] {
    return operators
      ? operators.filter(v => this.busy.find(b => Operator.compare(b, v))).sort((o1, o2) => (o1.fullName < o2.fullName ? -1 : 1))
      : [];
  }

  private sortOrder = [
    OPERATOR_CATEGORY.employee,
    OPERATOR_CATEGORY.driver,
    OPERATOR_CATEGORY.external,
  ];

  private groupOperatorsByCategory(operators: Operator[]): Map<string, Operator[]> {
    let map: Map<string, Operator[]> = new Map<string, Operator[]>();
    this.sortOrder.forEach(key => {
      map.set(key, []);
    });

    if (operators) {
      operators.filter(o => !o.generic).forEach(operator => {
        let array = map.get(operator.category) || [];
        array.push(operator);
        map.set(operator.category, array);
      });
    }

    return map;
  }

  categoryIcon(category: string) {
    return Operator.resolveCategoryIcon(category);
  }

  categoryLabel(category: string) {
    return Operator.resolveCategoryLabel(category);
  }

  selectOperator(operator: Operator) {
    this.dialogRef.close(operator);
  }

  selectGenericOperator(category: string) {
    this.selectOperator(this.all.filter(o => o.generic).find(o => o.category == category));
  }
}
