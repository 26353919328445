import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Order } from "../../../shared/model";
import { OrderDetailComponent } from "../order-detail/order-detail.component";
import { OrdersListComponent } from "../orders-list/orders-list.component";
import { ActivatedRoute } from "@angular/router";
import { DeletedOrderDialogComponent } from "../deleted-order-dialog/deleted-order-dialog.component";

@Component({
  selector: "app-orders-dashboard",
  templateUrl: "./orders-dashboard.component.html",
  styleUrls: ["./orders-dashboard.component.scss"]
})
export class OrdersDashboardComponent implements OnInit {
  @ViewChild(OrdersListComponent) orderList: OrdersListComponent;

  constructor(private dialog: MatDialog, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.data.subscribe((data: { order: Order }) => {
      if (data.order) {
        this.editOrder(data.order);
      }
    });
  }

  addOrder(plantOrder: boolean = false) {
    this.openOrderDialog(null, plantOrder);
  }

  editOrder(order: Order) {
    if (order.isDeleted()) {
      let dialogRef = this.dialog.open(DeletedOrderDialogComponent, {
        data: { order: order },
        disableClose: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.orderList.loadOrders();
        }
      });
    } else {
      this.openOrderDialog(order);
    }
  }

  private openOrderDialog(order?: Order, plantOrder?: boolean) {
    let data = {
      order: order,
      plantOrder: plantOrder
    };
    let dialogRef = this.dialog.open(OrderDetailComponent, {
      data,
      width: "98%",
      height: "95vh",
      maxHeight: "100vh",
      panelClass: "no-padding"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.reload) this.orderList.loadOrders();
        if (result.duplicate) this.openOrderDialog(result.duplicate);
      }
    });
  }
}
