import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { OrdersDashboardComponent } from "./orders-dashboard/orders-dashboard.component";
import { OrderResolverService } from "./orders-dashboard/order-resolver.service";

const routes: Routes = [
  {
    path: "",
    component: OrdersDashboardComponent
  },
  {
    path: "order/:orderId",
    component: OrdersDashboardComponent,
    resolve: {
      order: OrderResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [OrderResolverService]
})
export class OrdersRoutingModule {}
