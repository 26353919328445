import { FormControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

import { CommonObject } from '../model/common-object.model';

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATE_FORMAT_WITH_TIME = "YYYY-MM-DD HH:mm:ss";

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return moment(date).format(includeTime ? BACKEND_DATE_FORMAT_WITH_TIME : BACKEND_DATE_FORMAT);
  }
  return null;
}

export function removeFromArray(array: Array<CommonObject>, removedItem: CommonObject) {
  if (array) {
    let itemIndex = array.findIndex(item => item.objectId == removedItem.objectId);
    if (itemIndex > -1) {
      array.splice(itemIndex, 1);
    }
  }
  return array;
}

export function addIfNotExists(array: Array<CommonObject>, toBeAdded: CommonObject) {
  if (array) {
    let itemIndex = array.findIndex(item => item.objectId == toBeAdded.objectId);
    if (itemIndex == -1) {
      array.push(toBeAdded);
    }
  }
  return array;
}

export function existsInArray(array: Array<CommonObject>, toBeAdded: CommonObject) {
  if (array) {
    let itemIndex = array.findIndex(item => item.objectId == toBeAdded.objectId);
    return itemIndex != -1;
  }
  return false;
}

export function getArrayDifference<T extends CommonObject>(a1: T[], a2: T[]): T[] {
  if (!a1) return a2;
  if (!a2) return a1;
  return a1.filter(i1 => a2.findIndex(i2 => i2.objectId == i1.objectId) < 0);
}

export function autocompleteSelectionValidator(control: FormControl): ValidationErrors | null {
  let selection = control.value;
  if (typeof selection === "string") {
    return { invalid: true };
  }
  return null;
}

export function getPreviousDay(date: Date = new Date(), onlyWeekDays: boolean = true): Date {
  let dayDecrement = 1;

  if (onlyWeekDays) {
    if (moment(date).day() === 1) {
      // set to friday
      dayDecrement = 3;
    } else if (moment(date).day() === 0) {
      // set to friday
      dayDecrement = 2;
    }
  }
  return moment(date)
    .subtract(dayDecrement, "days")
    .toDate();
}

export function getNextDay(date: Date = new Date(), onlyWeekDays: boolean = true): Date {
  let dayIncrement = 1;

  if (onlyWeekDays) {
    if (moment(date).day() === 5) {
      // set to monday
      dayIncrement = 3;
    } else if (moment(date).day() === 6) {
      // set to monday
      dayIncrement = 2;
    }
  }
  return moment(date)
    .add(dayIncrement, "days")
    .toDate();
}


export function arrayGroupBy(array, key: string) {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function fromString(text: string, pattern: string = 'YY-MM-DD'): Date {
  return moment(text, pattern).toDate();
}