import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TodoFilters } from 'src/app/shared/model';

import { User } from './../../../shared/model/user.model';

@Component({
  selector: 'app-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss']
})
export class ReportFiltersComponent implements OnInit {
  filtersForm: FormGroup;

  @Input()
  users: User[];

  @Output()
  onFilter: EventEmitter<any> = new EventEmitter();

  private _defaultFilters: TodoFilters;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this._createFiltersForm();
    this._setDefaultValues();
  }

  private _createFiltersForm() {
    let group = {
      status: "",
      executor: "",
      assigner: "",
      expirationStart: "",
      expirationEnd: "",
      executionStart: "",
      executionEnd: "",
      searchQuery: ""
    };

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getTodoFilters());
  }

  private _getTodoFilters(): TodoFilters {
    let filters: TodoFilters = {};
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.executionStart = values.executionStart;
      filters.executionEnd = values.executionEnd;
      filters.executionEnd = values.executionEnd;
      filters.expirationStart = values.expirationStart;
      filters.expirationEnd = values.expirationEnd;
      filters.status = values.status;
      filters.searchQuery = values.searchQuery;
      filters.assigner = values.assigner;
      filters.executor = values.executor;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      status: "",
      expirationStart: "",
      expirationEnd: "",
      executionStart: "",
      executionEnd: "",
      searchQuery: "",
      assigner: "",
      executor: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): TodoFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: TodoFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          status:
            this.defaultFilters.status != undefined
              ? this.defaultFilters.status
              : "",
          expirationStart:
            this.defaultFilters.expirationStart != undefined
              ? this.defaultFilters.expirationStart
              : "",
          expirationEnd:
            this.defaultFilters.expirationEnd != undefined
              ? this.defaultFilters.expirationEnd
              : "",
          executionStart:
            this.defaultFilters.executionStart != undefined
              ? this.defaultFilters.executionStart
              : "",
          executionEnd:
            this.defaultFilters.executionEnd != undefined
              ? this.defaultFilters.executionEnd
              : "",
          searchQuery:
            this.defaultFilters.searchQuery != undefined
              ? this.defaultFilters.searchQuery
              : "",
          assigner:
            this.defaultFilters.assigner != undefined
              ? this.defaultFilters.assigner
              : "",
          executor:
            this.defaultFilters.executor != undefined
              ? this.defaultFilters.executor
              : ""
        });
      }
    }
  }

  get filteredAssigners(): User[] {
    return this._filterUser(
      this.filtersForm.get('assigner').value,
      this.users);
  }

  get filteredExecutors(): User[] {
    return this._filterUser(
      this.filtersForm.get('executor').value,
      this.users);
  }

  displayUser(user?: User): string | undefined {
    return user ? user.fullName : undefined;
  }

  private _filterUser(
    value: string | User,
    users: User[]
  ): User[] {
    if (value instanceof User) {
      return [value];
    }

    if (!value) {
      return users;
    }

    const filterValue = value.toLowerCase();
    return users.filter(
      user =>
        user.fullName.toLowerCase().indexOf(filterValue) > -1
    );
  }
}
