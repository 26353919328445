import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter, finalize } from 'rxjs/operators';
import { DailyActivityFilters, User } from 'src/app/shared/model';
import { ActivityService } from 'src/app/shared/services';

@Component({
  selector: 'app-daily-activity-filters',
  templateUrl: './daily-activity-filters.component.html',
  styleUrls: ['./daily-activity-filters.component.scss']
})
export class DailyActivityFiltersComponent implements OnInit {
  private loadingReplacing = new BehaviorSubject<boolean>(false);
  public loadingReplacing$ = this.loadingReplacing.asObservable();

  @Output()
  onFilter: EventEmitter<DailyActivityFilters> = new EventEmitter();

  private _defaultFilters: DailyActivityFilters;

  @Input()
  users: User[];
  replacing: User[];
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder, private activityService: ActivityService) { }

  ngOnInit() {
    this._createForm();
    this._setDefaultValues();
  }

  private _createForm() {
    let group = {
      date: ["", Validators.required],
      assigner: ["", Validators.required],
      replacing: [""]
    }

    this.filtersForm = this.fb.group(group);

    this.filtersForm.get('assigner').valueChanges.pipe(distinctUntilChanged((a1, a2) => User.compare(a1, a2)), filter(value => value instanceof User)).subscribe(assigner => {
      this.filtersForm.patchValue({ replacing: null });
      this._loadReplacing(assigner);
    })
  }

  applyFilters() {
    this.onFilter.emit(this._getActivityFilters());
  }

  get defaultFilters(): DailyActivityFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: DailyActivityFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  get filteredUsers(): User[] {
    return this._filterUser(
      this.filtersForm.get('assigner').value,
      this.users);
  }

  displayUser(user?: User): string | undefined {
    return user ? user.fullName : undefined;
  }

  resetFilters() {
    this.filtersForm.reset();
    this._setDefaultValues();
    this.applyFilters();
  }

  private _filterUser(
    value: string | User,
    users: User[]
  ): User[] {
    if (value instanceof User) {
      return [value];
    }

    if (!value) {
      return users;
    }

    const filterValue = value.toLowerCase();
    return users.filter(
      user =>
        user.fullName.toLowerCase().indexOf(filterValue) > -1
    );
  }

  private _setDefaultValues() {
    if (this.defaultFilters && this.filtersForm) {
      this.filtersForm.patchValue({
        date: this.defaultFilters.from || this.defaultFilters.to,
        assigner: this.defaultFilters.assigner
      })
    }
  }

  private _getActivityFilters(): DailyActivityFilters {
    let filters: DailyActivityFilters = {}
    if (this.filtersForm) {
      let values = this.filtersForm.value;
      filters.from = values.date;
      filters.to = values.date;
      filters.assigner = values.assigner;
      filters.replacing = values.replacing;
    }
    return filters;
  }

  private _loadReplacing(assigner: User) {
    this.replacing = [];
    this.loadingReplacing.next(true);
    if (assigner) {
      this.activityService.getReplacingUsers(assigner).pipe(finalize(() => this.loadingReplacing.next(false))).subscribe((replacing) => this.replacing = replacing)
    }
  }
}
