import * as moment from 'moment';
import { User } from 'src/app/shared/model';

import { AbstractControl, FormGroup } from '../../../../node_modules/@angular/forms';
import { environment } from './../../../environments/environment';
import { Anagraphic, Product } from './anagraphic.model';
import { Comment } from './comment.model';
import { CommonObject } from './common-object.model';
import { PlannedDay, PlannedOrder } from './planned-day.model';

export enum ORDER_TYPE {
  client = "client",
  conferment = "conferment",
  disposal = "disposal",
  plant = "plant",
  brokerage = "brokerage"
}
export class Order extends CommonObject {
  client?: Anagraphic;
  supplier?: Anagraphic;
  type: string = ORDER_TYPE.client;
  date: Date;
  urgent: boolean = false;
  onPlan: boolean = false;

  checkTech: boolean = false;
  checkCommercial: boolean = false;
  checkLogistic: boolean = false;
  checkPlant: boolean = false;

  needFormulary: boolean = false;
  needTransport: boolean = false;
  needDumping: boolean = false;
  needRollout: boolean = false;
  needPackaging: boolean = false;

  reference?: string;
  note?: string;
  attachmentFile?: File;
  private _attachment?: string;

  orderedProducts: OrderedProduct[] = [];
  plannedOrders?: PlannedOrder[] = [];
  comments?: Comment[] = [];

  closedAt?: Date;

  techEditor?: User;
  techEditedAt?: Date;

  commercialEditor?: User;
  commercialEditedAt?: Date;

  logisticEditor?: User;
  logisticEditedAt?: Date;

  plantEditor?: User;
  plantEditedAt?: Date;

  constructor(source: any) {
    super(source);
    if (source) {
      if (source.client) {
        this.client = new Anagraphic(source.client);
      }
      if (source.supplier) {
        this.supplier = new Anagraphic(source.supplier);
      }
      if (source.planned_orders) {
        this.plannedOrders = [];
        source.planned_orders.forEach(planned_order => {
          let plannedOrder = new PlannedOrder(planned_order);
          plannedOrder.order = this;
          this.plannedOrders.push(plannedOrder);
        });
      }

      this.type = source.type;
      this.date = source.date;
      this.checkTech = source.check_tech;
      this.checkCommercial = source.check_commercial;
      this.checkLogistic = source.check_logistic;
      this.checkPlant = source.check_plant;

      this.urgent = source.urgent;
      this.onPlan = source.on_plan;

      this.needFormulary = source.need_formulary;
      this.needTransport = source.need_transport;
      this.needDumping = source.need_dumping;
      this.needRollout = source.need_rollout;
      this.needPackaging = source.need_packaging;

      this.attachment = source.attachment;

      this.reference = source.reference;
      this.note = source.note;
      if (source.products) {
        source.products.forEach(product => {
          let orderedProduct = new OrderedProduct(product);
          orderedProduct.order = this;
          this.orderedProducts.push(orderedProduct);
        });
      }

      if (source.comments) {
        source.comments.forEach(commentSource => {
          let comment = new Comment(commentSource);
          comment.order = this;
          this.comments.push(comment);
        });
      }

      this.closedAt = source.closing_date;

      if (source.tech_edited_at) {
        this.techEditedAt = new Date(source.tech_edited_at);
      }
      if (source.tech_editor) {
        this.techEditor = new User(source.tech_editor);
      }

      if (source.commercial_edited_at) {
        this.commercialEditedAt = new Date(source.commercial_edited_at);
      }
      if (source.commercial_editor) {
        this.commercialEditor = new User(source.commercial_editor);
      }

      if (source.logistic_edited_at) {
        this.logisticEditedAt = new Date(source.logistic_edited_at);
      }
      if (source.logistic_editor) {
        this.logisticEditor = new User(source.logistic_editor);
      }

      if (source.plant_edited_at) {
        this.plantEditedAt = new Date(source.plant_edited_at);
      }
      if (source.plant_editor) {
        this.plantEditor = new User(source.plant_editor);
      }
    }
  }

  get status() {
    if (this.isClosed()) return "closed";
    if (this.isPlanned()) return "planned";
    if (this.isValidated()) return "validated";
    return "created";
  }

  get attachment(): string {
    return this._attachment
      ? environment.baseUrl + "/" + this._attachment
      : null;
  }

  set attachment(attachment: string) {
    this._attachment = attachment;
  }

  isPlanned(): boolean {
    return this.plannedOrders && this.plannedOrders.length > 0;
  }

  isValidated(): boolean {
    return this.checkCommercial && this.checkLogistic && this.checkTech;
  }

  isClosed(): boolean {
    return this.closedAt != null;
  }

  isDeleted(): boolean {
    return this.deletedAt != null;
  }

  getPlannedTooltip(): string {
    if (this.plannedOrders && this.plannedOrders.length > 0) {
      let plannedDates = this.plannedOrders
        .filter(p => p.plannedDay.loaded)
        .map(p => {
          let day: PlannedDay = (p.plannedDay as PlannedDay);
          return {
            date: day.date,
            closed: p.isClosed()
          }
        });
      return plannedDates.map(day => {
        let string = moment(day.date).format("DD-MM-YYYY");
        if (day.closed) {
          string += " (evaso)";
        }
        return string;
      }).join(", ");
    }
    return "";
  }

  get techTooltip() {
    return this._editorTooltip("tecnica", this.techEditor, this.techEditedAt);
  }

  get commercialTooltip() {
    return this._editorTooltip("commerciale", this.commercialEditor, this.commercialEditedAt);
  }

  get logisticTooltip() {
    return this._editorTooltip("logistica", this.logisticEditor, this.logisticEditedAt);
  }

  get plantTooltip() {
    return this._editorTooltip("impianto", this.plantEditor, this.plantEditedAt);
  }

  private _editorTooltip(type: string, editor: User, date: Date): string {
    let result = `Validazione ${type}`;
    if (editor) {
      const dateString = moment(date).format('DD-MM-YYYY hh:mm');
      result = `${result} - Modificata da ${editor.fullName} il ${dateString}`
    }
    return result;
  }

  get plantOrder(): boolean {
    return this.type == ORDER_TYPE.plant;
  }

  static fromFormGroup(formGroup: FormGroup): Order {
    const formModel = formGroup.value;

    let order: Order = new Order(null);
    if (formModel.supplier) {
      order.supplier = formModel.address;
      order.client = formModel.client;
    } else {
      order.supplier = null;
      order.client = formModel.address;
    }
    order.date = formModel.date;
    order.urgent = formModel.urgent;
    order.onPlan = formModel.onPlan;
    order.type = formModel.type;
    order.checkTech = formModel.checkTech;
    order.checkCommercial = formModel.checkCommercial;
    order.checkLogistic = formModel.checkLogistic;
    order.checkPlant = formModel.checkPlant;
    order.needFormulary = formModel.needFormulary;
    order.needTransport = formModel.needTransport;
    order.needDumping = formModel.needDumping;
    order.needRollout = formModel.needRollout;
    order.needPackaging = formModel.needPackaging;
    order.attachmentFile = formModel.attachmentFile;
    order.reference = formModel.reference;
    order.note = formModel.note;
    return order;
  }
}

export class OrderedProduct extends CommonObject {
  product: Product;
  _productId?: number;
  order: Order;
  _orderId?: number;
  supposedQuantity: string;
  //note?: string;
  tech_note?: string;
  comm_note?: string;
  plant_note?: string;
  done?: boolean;
  destination?: string[];

  constructor(source: any) {
    super(source);
    this.supposedQuantity = source.supposed_quantity;
    if (source.product) {
      this.product = new Product(source.product);
    } else if (source.product_id) {
      this._productId = source.product_id;
    }
    if (source.order) {
      this.order = new Order(source.order);
    } else if (source.order_id) {
      this._orderId = source.orderId;
    }
    this.tech_note = source.tech_note;
    this.comm_note = source.comm_note;
    this.plant_note = source.plant_note;
    this.done = source.done;
    this.destination = source.destination ? source.destination.split(',') : null;
  }

  get orderId() {
    return this.order ? this.order.objectId : this._orderId;
  }

  get productId() {
    return this.product ? this.product.objectId : this._productId;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    order: Order
  ): OrderedProduct {
    const formModel = formGroup.value;
    let orderedProduct: OrderedProduct = {
      objectId: formModel.objectId,
      orderId: null,
      productId: null,
      order: order,
      product: formModel.product,
      supposedQuantity: formModel.quantity,
      tech_note: formModel.tech_note,
      comm_note: formModel.comm_note,
      plant_note: formModel.plant_note,
      done: formModel.done,
      destination: formModel.destination
    };
    if (formModel.destination && Array.isArray(formModel.destination)) {
      orderedProduct.destination = formModel.destination.join(',');
    }
    return orderedProduct;
  }
}
