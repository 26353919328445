import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule, MatButtonModule } from "@angular/material";

import { ConfirmDialogComponent } from "./confirm-dialog.component";

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [ConfirmDialogComponent],
  entryComponents: [ConfirmDialogComponent],
  exports: [ConfirmDialogComponent]
})
export class ConfirmDialogModule {}
