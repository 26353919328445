import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/model';

import { UserService } from '../services';

@Injectable()
export class UsersResolverService implements Resolve<User[]> {
  constructor(private userService: UserService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User[]> {
    return this.userService.getUsers(0, 99999, null, null).pipe(
      map(results => results.data)
    );
  }
}
