import { User } from "./user.model";
import { FormGroup } from "@angular/forms";
import { CommonObject } from "./common-object.model";
import { Order } from "./order.model";

export class Comment extends CommonObject {
  body: string;
  author: User;
  order: Order;
  sentTo: User[] = [];

  constructor(source) {
    super(source);
    if (source) {
      this.body = source.body;
      if (source.author) {
        this.author = new User(source.author);
      }
      if (source.order) {
        this.order = new Order(source.order);
      }
      if (source.sent_to) {
        this.sentTo = [];
        source.sent_to.forEach(userSource => {
          let user = new User(userSource);
          this.sentTo.push(user);
        });
      }
    }
  }

  static fromFormGroup(formGroup: FormGroup): Comment {
    const formModel = formGroup.value;

    let comment: Comment = new Comment(null);
    comment.body = formModel.body;
    return comment;
  }
}
