import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule,
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ShowForDirectivesModule } from 'src/app/shared/directives/show-for/show-for.module';

import { DailyActivityFiltersComponent } from './daily-activity-filters/daily-activity-filters.component';
import { DailyActivityRoutingModule } from './daily-activity-routing.module';
import { DailyActivityComponent } from './daily-activity/daily-activity.component';
import { ReportCalendarComponent } from './report-calendar/report-calendar.component';
import { ReportDayComponent } from './report-day/report-day.component';
import { ReportFiltersComponent } from './report-filters/report-filters.component';
import { ReportListComponent } from './report-list/report-list.component';
import { TodoDetailComponent } from './todo-detail/todo-detail.component';

@NgModule({
  declarations: [DailyActivityComponent, DailyActivityFiltersComponent, ReportCalendarComponent, ReportDayComponent, ReportListComponent, ReportFiltersComponent, TodoDetailComponent],
  imports: [
    CommonModule,
    DailyActivityRoutingModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CalendarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ShowForDirectivesModule
  ],
  entryComponents: [ReportDayComponent, TodoDetailComponent]
})
export class DailyActivityModule { }
