import { Vehicle } from "../../../shared/model/vehicle.model";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from "@angular/router";
import { VehicleService } from "../../../shared/services";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class VehiclesResolverService implements Resolve<Vehicle[]> {
  constructor(private vehicleService: VehicleService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Vehicle[]> {
    return this.vehicleService
      .getVehicles(0, 99999, null, null, false, true)
      .pipe(
        map(results => {
          return results.data;
        })
      );
  }
}
