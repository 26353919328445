import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { autocompleteSelectionValidator } from 'src/app/shared/helpers/util';
import { PlannedOrder } from 'src/app/shared/model/planned-day.model';

import { Order, ORDER_TYPE, OrderedProduct } from '../../../shared/model';
import { Anagraphic } from '../../../shared/model/anagraphic.model';
import { AlertService, AnagraphicService, AuthService, OrderService, ProductService } from '../../../shared/services';
import { Product } from './../../../shared/model/anagraphic.model';
import { PlanningService } from './../../../shared/services/planning.service';


@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"]
})
export class OrderDetailComponent implements OnInit {
  private includes = ["products.product",
    "client",
    "supplier",
    "comments.author",
    "comments.sent_to",
    "planned_orders.planned_day",
    "tech_editor",
    "commercial_editor",
    "logistic_editor",
    "plant_editor"
  ];

  @ViewChild("detailsContainer")
  private detailsContainer: ElementRef;

  plantOrder: boolean = false;

  clientSearchResults = [];
  supplierSearchResults = [];
  searchResultMaxLength = 15;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private searchResultLength = new BehaviorSubject<number>(-1);
  public searchResultLength$ = this.searchResultLength.asObservable();

  private searchingClient = new BehaviorSubject<boolean>(false);
  public searchingClient$ = this.searchingClient.asObservable();

  private searchSupplierResultLength = new BehaviorSubject<number>(-1);
  public searchSupplierResultLength$ = this.searchSupplierResultLength.asObservable();

  private searchingSupplier = new BehaviorSubject<boolean>(false);
  public searchingSupplier$ = this.searchingSupplier.asObservable();

  private generatingPDF = new BehaviorSubject<boolean>(false);
  public generatingPDF$ = this.generatingPDF.asObservable();

  _client: Anagraphic;
  _supplier: Anagraphic;
  linkedAnagraphics: Anagraphic[] = [];

  _order: Order;
  _hasUpdates: boolean = false;

  orderForm: FormGroup;

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  filteredProducts: Product[];
  filteredAddresses: Observable<Anagraphic[]>;

  get displayedColumns(): string[] {
    return this.plantOrder
      ? ["done", "product", "tech_note", "add"]
      : [
        "product",
        "destiny",
        "dangerCodes",
        "checks",
        "linkedRef",
        "destination",
        "quantity",
        "tech_note",
        "add"
      ];
  }
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrderDetailComponent>,
    private orderService: OrderService,
    private anagraphicService: AnagraphicService,
    private alertService: AlertService,
    private productService: ProductService,
    private planningService: PlanningService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.plantOrder = this.data.plantOrder;
      this.order = this.data.order;
    }
    this.createForm();
    this.ngOnChanges();
    this.loadOrderDetails();
  }

  private loadOrderDetails() {
    if (this.order) {
      this.loadingSubject.next(true);

      this.orderService
        .getOrder(this.order.objectId, this.includes)
        .subscribe(loadedOrder => {
          if (this.order) {
            loadedOrder.date = this.order.date; //Per gestire la duplicazione: il campo 'date' è obbligatorio, quindi ha un valore lato backend, però non va duplicato lato frontend
          }
          this.order = loadedOrder;
          console.log(this.order);
          this.loadingSubject.next(false);
        });
    }
  }

  get order(): Order {
    return this._order;
  }

  set order(order: Order) {
    this._order = order;
    if (order) {
      this.plantOrder = order.plantOrder;
    }
    this.ngOnChanges();
  }

  get hasUpdates(): boolean {
    return this._hasUpdates;
  }

  set hasUpdates(hasUpdates: boolean) {
    this._hasUpdates = hasUpdates;
  }

  createForm() {
    let group = {
      client: ["", [Validators.required, autocompleteSelectionValidator]],
      supplier: [""],
      address: ["", [Validators.required, autocompleteSelectionValidator]],
      date: [""],
      urgent: [""],
      onPlan: [""],
      checkTech: [""],
      checkCommercial: [""],
      checkLogistic: [""],
      checkPlant: [""],
      needFormulary: [""],
      needTransport: [""],
      needDumping: [""],
      needRollout: [""],
      needPackaging: [""],
      attachmentFile: [""],
      reference: [""],
      type: [ORDER_TYPE.client, Validators.required],
      note: [""],
      orderedProducts: this.fb.array([]),
      comments: this.fb.array([])
    };

    this.orderForm = this.fb.group(group);

    this.orderForm.controls["client"].valueChanges.subscribe(client => {
      this.clientSearchResults = [];
      if (client && client instanceof Anagraphic) {
        this.client = client;
      }
    });

    this.orderForm.controls["supplier"].valueChanges.subscribe(supplier => {
      this.supplierSearchResults = [];
      if (supplier && supplier instanceof Anagraphic) {
        this.supplier = supplier;
      }
    });

    this.filteredAddresses = this.orderForm.controls[
      "address"
    ].valueChanges.pipe(
      startWith<string | Anagraphic>(""),
      map(anagraphic =>
        typeof anagraphic === "string"
          ? anagraphic
          : anagraphic
            ? anagraphic.completeAddress
            : ""
      ),
      map(value => this._filterAddress(value))
    );

    this.orderForm.controls["client"].valueChanges
      .pipe(
        debounceTime(250),
        tap(() => this.searchingClient.next(true)),
        distinctUntilChanged(),
        switchMap(data => {
          if (data && typeof data === "string") {
            return this.anagraphicService
              .getAnagraphics(
                1,
                this.searchResultMaxLength,
                "name",
                "asc",
                data
              )
              .pipe(finalize(() => this.searchingClient.next(false)));
          }
          return of(null).pipe(
            finalize(() => this.searchingClient.next(false))
          );
        })
      )
      .subscribe(result => {
        if (result) {
          this.clientSearchResults = result.data;
          this.searchResultLength.next(result.total);
        }
      });

    this.orderForm.controls["supplier"].valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(data => {
          this.searchingSupplier.next(true);
          if (data && typeof data === "string") {
            return this.anagraphicService
              .getAnagraphics(
                1,
                this.searchResultMaxLength,
                "name",
                "asc",
                data
              )
              .pipe(finalize(() => this.searchingSupplier.next(false)));
          } else {
            return of(null).pipe(
              finalize(() => this.searchingSupplier.next(false))
            );
          }
        })
      )
      .subscribe(result => {
        if (result) {
          this.supplierSearchResults = result.data;
          this.searchSupplierResultLength.next(result.total);
        }
      });

    this.setDefaultValues();
  }

  addProduct(orderedProduct: OrderedProduct) {
    let group = {
      objectId: [orderedProduct ? orderedProduct.objectId : null],
      product: ["", [Validators.required, autocompleteSelectionValidator]],
      quantity: ["", !this.plantOrder ? Validators.required : null],
      tech_note: [""],
      comm_note: [""],
      plant_note: [""],
      done: [""],
      destination: [""]
    };

    const orderedProductCtrl = this.fb.group(group);

    if (orderedProduct) {
      orderedProductCtrl.patchValue({
        objectId: orderedProduct.objectId,
        product: orderedProduct.product,
        quantity: orderedProduct.supposedQuantity,
        tech_note: orderedProduct.tech_note,
        comm_note: orderedProduct.comm_note,
        plant_note: orderedProduct.plant_note,
        done: orderedProduct.done,
        destination: orderedProduct.destination
      });
    }

    orderedProductCtrl.controls["product"].valueChanges
      .pipe(
        startWith<string | Product>(""),
        map(product =>
          typeof product === "string"
            ? product
            : product
              ? product.description
              : ""
        ),
        map(value => this._filterProduct(value))
      )
      .subscribe(results => {
        this.filteredProducts = results;
      });

    this.productsForm.push(orderedProductCtrl);
    this.updateDatasource();
    this.scrollToBottom();
  }

  private updateDatasource() {
    this.dataSource.next(this.productsForm.controls);
  }

  get productsForm(): FormArray {
    return this.orderForm.get("orderedProducts") as FormArray;
  }

  resetProductsForm() {
    while (this.productsForm.length !== 0) {
      this.productsForm.removeAt(0);
    }
    this.updateDatasource();
  }

  removeProduct(index) {
    this.alertService.showConfirmDialog('Rimuovi prodotto', 'Procedere con la rimozione del prodotto dall\'ordine?').pipe(filter(confirm => confirm)).subscribe(() => {
      this.productsForm.removeAt(index);
      this.orderForm.markAsDirty();
      this.updateDatasource();
    })
  }

  get products(): Product[] {
    if (this.supplier) {
      return this.supplier.products;
    } else {
      return this.client ? this.client.products : [];
    }
  }

  private resolveClientProducts() {
    if (!this.client.products || this.products.length == 0) {
      return forkJoin(
        this.anagraphicService.getAnagraphic(this.client.objectId, "products"),
        this.productService.getGeneralProducts(null, null, null, null, null)
      ).pipe(
        map(results => {
          let client = results[0];
          if (!client.products) {
            client.products = [];
          }
          client.products.push.apply(client.products, results[1].data);
          this._client = client;
        })
      );
    } else {
      return of(null);
    }
  }

  private resolveSupplierProducts() {
    if (!this.supplier.products || this.products.length == 0) {
      return forkJoin(
        this.anagraphicService.getAnagraphic(
          this.supplier.objectId,
          "products"
        ),
        this.productService.getGeneralProducts(null, null, null, null, null)
      ).pipe(
        map(results => {
          let supplier = results[0];
          if (!supplier.products) {
            supplier.products = [];
          }
          supplier.products.push.apply(supplier.products, results[1].data);
          this._supplier = supplier;
        })
      );
    } else {
      return of(null);
    }
  }

  private resolveClientAddresses(): Observable<Anagraphic[]> {
    this.linkedAnagraphics = [];
    if (this.client) {
      //this.anagraphicService.getLinkedAnagraphics(this.client).subscribe(linkedAnagraphics => (this.linkedAnagraphics = linkedAnagraphics));
      return this.anagraphicService.getLinkedAnagraphics(this.client);
    } else {
      return of(null);
    }
  }

  private resolveSupplierAddresses(): Observable<Anagraphic[]> {
    this.linkedAnagraphics = [];
    if (this.supplier) {
      return this.anagraphicService.getLinkedAnagraphics(this.supplier);
    } else {
      return of(null);
    }
  }

  displayAnagraphic(anagraphic?: Anagraphic): string | undefined {
    return anagraphic ? anagraphic.name : undefined;
  }

  ngOnChanges() {
    if (this.orderForm) {
      this.orderForm.reset();
      this.resetProductsForm();
      if (this.order) {
        this.orderForm.patchValue({
          date: this.order.date,
          urgent: this.order.urgent,
          onPlan: this.order.onPlan,
          client: this.order.client.headquarter,
          supplier: this.order.supplier
            ? this.order.supplier.headquarter
            : null,
          address: this.order.supplier
            ? this.order.supplier
            : this.order.client,
          type: this.order.type,
          checkTech: this.order.checkTech,
          checkCommercial: this.order.checkCommercial,
          checkLogistic: this.order.checkLogistic,
          checkPlant: this.order.checkPlant,
          needFormulary: this.order.needFormulary,
          needTransport: this.order.needTransport,
          needDumping: this.order.needDumping,
          needRollout: this.order.needRollout,
          needPackaging: this.order.needPackaging,
          reference: this.order.reference,
          note: this.order.note
        });

        if (this.order.orderedProducts) {
          this.order.orderedProducts.forEach(orderedProduct =>
            this.addProduct(orderedProduct)
          );
        }

        this.authService.isAdmin().pipe(take(1)).subscribe(admin => {
          this.orderForm.disable();
          if (this.order.isClosed()) {
            if (admin) {
              this.orderForm.controls["checkTech"].enable();
              this.orderForm.controls["checkLogistic"].enable();
              this.orderForm.controls["checkPlant"].enable();
              this.orderForm.controls["checkCommercial"].enable();
            }
          } else if (admin) {
            this.orderForm.enable();
          }
        })

      } else {
        this.setDefaultValues();
      }
    }
  }

  private setDefaultValues() {
    if (this.orderForm) {
      if (this.plantOrder) {
        this.loadingSubject.next(true);
        this.orderService
          .getDefaultClient("products")
          .pipe(finalize(() => this.loadingSubject.next(false)))
          .subscribe(defaultClient => {
            this.client = defaultClient;
            this.orderForm.patchValue({
              client: defaultClient,
              address: defaultClient,
              type: ORDER_TYPE.plant
            });
          });
      } else {
        this.orderForm.patchValue({
          type: ORDER_TYPE.client
        });
      }
    }
  }

  prepareSaveOrder(): Order {
    let saveOrder: Order = Order.fromFormGroup(this.orderForm);

    if (this.order) {
      if (this.order.objectId) {
        saveOrder.objectId = this.order.objectId;
      }
    }

    saveOrder.orderedProducts = this.getProductsFromForm(saveOrder);
    return saveOrder;
  }

  private getProductsFromForm(order: Order): OrderedProduct[] {
    let orderedProducts: OrderedProduct[] = [];

    this.productsForm.controls.forEach(productForm => {
      orderedProducts.push(OrderedProduct.fromFormGroup(productForm, order));
    });
    return orderedProducts;
  }

  revert() {
    this.ngOnChanges();
  }

  close() {
    if (this.orderForm.pristine) {
      this.doClose();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  private doClose() {
    this.dialogRef.close({ reload: this.hasUpdates });
  }

  onSubmit() {
    this.loadingSubject.next(true);
    this.orderForm.enable(); //mrosetti - Necessario per leggere i valori dal form: vengono presi solo quelli dei campi non disabilitati
    let unsavedOrder = this.prepareSaveOrder();
    if (unsavedOrder.objectId) {
      this.orderService.updateOrder(unsavedOrder).subscribe(
        savedOrder => {
          this.orderService
            .getOrder(savedOrder.objectId, this.includes)
            .subscribe(loadedOrder => {
              this.order = loadedOrder;
              console.log(`Order ${this.order.objectId} updated`);
              this.hasUpdates = true;
              this.alertService.showConfirmMessage("Ordine aggiornato");
              this.loadingSubject.next(false);
            });
        },
        error => {
          console.error(
            `Error while updating order ${this.order.objectId}`,
            error
          );
          this.loadingSubject.next(false);
        }
      );
    } else {
      this.orderService.createOrder(unsavedOrder).subscribe(
        savedOrder => {
          this.orderService
            .getOrder(savedOrder.objectId, this.includes)
            .subscribe(loadedOrder => {
              this.order = loadedOrder;
              console.log(`Order added`);
              this.hasUpdates = true;
              this.alertService.showConfirmMessage("Ordine aggiunto");
              this.loadingSubject.next(false);
            });
        },
        error => {
          console.error(`Error while adding order`, error);
          this.loadingSubject.next(false);
        }
      );
    }
  }

  deleteOrder() {
    this.alertService
      .showConfirmDialog(
        "Conferma eliminazione",
        "Sei sicuro di voler eliminare l'ordine?"
      )
      .subscribe(result => {
        if (result) {
          this.doDelete();
        }
      });
  }

  private doDelete() {
    this.loadingSubject.next(true);
    this.orderService.deleteOrder(this.order).subscribe(() => {
      console.log("Order archived");
      this.alertService.showConfirmMessage(
        `Ordine ${this.order.objectId} eliminato`
      );
      this.hasUpdates = true;
      this.loadingSubject.next(false);
      this.close();
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      this.orderForm.patchValue({
        attachmentFile: file
      });
      this.orderForm.markAsDirty();
    }
  }

  downloadPDF() {
    this.generatingPDF.next(true);
    let fileName = `Ordine ${this.order.objectId}`;
    this.orderService
      .downloadPDF(this.order)
      .pipe(finalize(() => this.generatingPDF.next(false)))
      .subscribe(blob => {
        saveAs(blob, fileName);
      });
  }

  duplicate() {
    this.alertService
      .showConfirmDialog(
        "Duplica",
        `Vuoi davvero duplicare l'ordine ${this.order.objectId}?`
      )
      .subscribe(result => {
        if (result) {
          this.doDuplicate();
        }
      });
  }

  private doDuplicate() {
    this.loadingSubject.next(true);
    this.orderService
      .duplicate(this.order)
      .pipe(
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe(duplicatedOrder => {
        this.dialogRef.close({ reload: true, duplicate: duplicatedOrder });
      });
  }

  displayProduct = (product?: Product, includeDangerCodes?: boolean) => {
    let result = "";
    if (product) {
      if (this.plantOrder) {
        result += product.description;
      } else {
        if (product.productCode != "000000") {
          result += product.CER + " - ";
        }
        result += `${product.description ? product.description : ""}`;
        if (includeDangerCodes && product.dangerCodes) {
          result += ` ${product.dangerCodes}`;
        }
      }
    }
    return result;
  };

  displayAddress(anagraphic?: Anagraphic): string | undefined {
    return anagraphic ? anagraphic.completeAddress : "";
  }

  get client(): Anagraphic {
    return this._client;
  }

  set client(client: Anagraphic) {
    this._client = client;
    this.loadingSubject.next(true);
    forkJoin(this.resolveClientAddresses(), this.resolveClientProducts())
      .pipe(
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe(results => {
        this.linkedAnagraphics = results[0];
        //mrosetti - l'aggiornamento dei prodotti è gestito nella resolveProducts;
      });
  }

  get supplier(): Anagraphic {
    return this._supplier;
  }

  set supplier(supplier: Anagraphic) {
    this._supplier = supplier;
    this.loadingSubject.next(true);
    forkJoin(this.resolveSupplierAddresses(), this.resolveSupplierProducts())
      .pipe(
        finalize(() => {
          this.loadingSubject.next(false);
        })
      )
      .subscribe(results => {
        this.linkedAnagraphics = results[0];
        //mrosetti - l'aggiornamento dei prodotti è gestito nella resolveProducts;
      });
  }

  private _filterProduct(value: string): Product[] {
    const filterValue = value ? value.toLowerCase() : "";
    return this.products
      ? this.products.filter(product => {
        return (
          (product.description &&
            product.description.toLowerCase().indexOf(filterValue) != -1) ||
          product.CER.toLowerCase().indexOf(filterValue) != -1
        );
      })
      : [];
  }

  private _filterAddress(value: string): Anagraphic[] {
    const filterValue = value.toLowerCase();

    return this.linkedAnagraphics.filter(
      anagraphic =>
        anagraphic.completeAddress.toLowerCase().indexOf(filterValue) != -1
    );
  }

  compareAnagraphic(a1: Anagraphic, a2: Anagraphic) {
    return a1 && a2 ? a1.objectId === a2.objectId : a1 === a2;
  }

  closeOrder() {
    let message = "Sei sicuro di voler evadere l'ordine?";
    if (this.order.plannedOrders && this.order.plannedOrders.length > 1) {
      message = `Ordine pianificato in ${this.order.plannedOrders.length} giornate. Tutte le pianificazioni verranno evase. Continuare?`
    }
    this.alertService
      .showConfirmDialog(
        "Conferma evasione",
        message
      )
      .subscribe(result => {
        if (result) {
          this.doClosePlannedOrders(this.order.plannedOrders);
        }
      });
  }

  private doClosePlannedOrders(plannedOrders: PlannedOrder[]) {
    if (plannedOrders && plannedOrders.length) {
      this.loadingSubject.next(true);
      forkJoin(plannedOrders.map(plannedOrder => this.planningService.closePlannedOrder(plannedOrder.objectId)))
        .pipe(
          finalize(() => {
            this.loadingSubject.next(false);
          })
        )
        .subscribe(() => {
          this.alertService.showConfirmMessage(
            `Ordine ${this.order.objectId} evaso`
          );
          this.hasUpdates = true;
          this.close();
        })
    }
  }

  reopenOrder() {
    let message = "Sei sicuro di voler aprire nuovamente l'ordine?";
    if (this.order.plannedOrders && this.order.plannedOrders.length > 1) {
      message = `Ordine pianificato in ${this.order.plannedOrders.length} giornate. Tutte le pianificazioni verranno riaperte. Continuare?`
    }
    this.alertService
      .showConfirmDialog(
        "Conferma riapertura",
        message
      )
      .subscribe(result => {
        if (result) {
          this.doReopenPlannedOrders(this.order.plannedOrders);
        }
      });
  }

  private doReopenPlannedOrders(plannedOrders: PlannedOrder[]) {
    if (plannedOrders && plannedOrders.length) {
      this.loadingSubject.next(true);
      forkJoin(plannedOrders.map(plannedOrder => this.planningService.reopenPlannedOrder(plannedOrder)))
        .pipe(
          finalize(() => {
            this.loadingSubject.next(false);
          })
        )
        .subscribe(() => {
          this.alertService.showConfirmMessage(
            `Ordine ${this.order.objectId} riaperto`
          );
          this.hasUpdates = true;
          this.close();
        })
    }
  }

  private scrollToBottom() {
    try {
      this.detailsContainer.nativeElement.scrollTop = this.detailsContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.log("Error on scrolling", err);
    }
  }

  onPlanningDateSelected(event) {
    let date = event.value.toDate();
    if (date) {
      this.planOrder(date);
    }
  }

  private planOrder(day: Date) {
    if (this.order) {
      let formattedDay = moment(day).format("DD-MM-YYYY");
      this.planningService.planOrder(this.order, day).subscribe(() => {
        this.alertService.showConfirmMessage(`Ordine ${this.order.objectId} pianificato per il ${formattedDay}`);
        this.hasUpdates = true;
        this.loadOrderDetails();
      }, error => {
        this.alertService.showErrorMessage(`Errore durante la pianificazione per ${formattedDay}`, error);
      })
    }

  }
}
