import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ListResult } from '../helpers/listResult.interface';
import { User } from '../model';
import { Comment } from '../model/comment.model';
import { AuthService } from './auth.service';
import { LaravelCommentService } from './laravel';

@Injectable()
export class CommentService {
  constructor(private laravelCommentService: LaravelCommentService, private authService: AuthService) { }

  public getComment(id: number): Observable<Comment> {
    return new Observable<Comment>(subscriber => {
      this.laravelCommentService.getById(id).subscribe(
        response => {
          subscriber.next(new Comment(response));
          subscriber.complete();
        },
        error => {
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  public getComments(page: number, perPage: number, order: string, direction: string, orderId?: number, include?: string | string[]): Observable<ListResult<Comment>> {
    return this.laravelCommentService.list(page, perPage, order, direction, orderId, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<Comment>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new Comment(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new Comment(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public createComment(comment: Comment, notificated?: User[]): Observable<Comment> {
    return this.authService.getCurrentUser().pipe(
      switchMap(currentUser => this.laravelCommentService.create(comment.body, currentUser.objectId, comment.order.objectId, notificated ? notificated.map(u => u.objectId) : null).pipe(
        map(result => {
          return new Comment(result);
        }))
      ));
  }

  public updateComment(comment: Comment, notificated?: User[]): Observable<Comment> {
    return this.authService.getCurrentUser().pipe(
      switchMap(currentUser => this.laravelCommentService.update(comment.objectId, comment.body, currentUser.objectId, comment.order.objectId, notificated ? notificated.map(u => u.objectId) : null).pipe(
        map(result => {
          return new Comment(result);
        }))
      ));
  }

  public deleteComment(comment: Comment): Observable<Comment> {
    return this.laravelCommentService.delete(comment.objectId).pipe(
      map(result => {
        return new Comment(result);
      })
    );
  }
}
