import { Pipe, PipeTransform } from '@angular/core';

import { ORDER_TYPE } from '../../model';

@Pipe({
  name: 'typeTranslator'
})
export class TypeTranslatorPipe implements PipeTransform {

  transform(value: ORDER_TYPE, args?: any): any {
    switch (value) {
      case "client":
        return "Cliente"

      case "conferment":
        return "Conferimento"
      case "disposal":
        return "Smaltimento"
      case "plant":
        return "Impianto"
      case "brokerage":
        return "Intermediazione"

      default:
        return "";
    }
  }

}
