import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule, MatTooltipModule } from "@angular/material";

import { VehicleInListComponent } from "./vehicle-in-list.component";

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  declarations: [VehicleInListComponent],
  exports: [VehicleInListComponent]
})
export class VehicleInListModule {}
