import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatTooltipModule,
} from '@angular/material';
import { OperatorInListModule } from 'src/app/shared/modules/operator-in-list/operator-in-list.module';

import { OperatorSelectionComponent } from './operator-selection.component';

@NgModule({
  declarations: [OperatorSelectionComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    OperatorInListModule,
    ReactiveFormsModule
  ],
  exports: [OperatorSelectionComponent],
  entryComponents: [OperatorSelectionComponent]
})
export class OperatorSelectionModule { }
