import { FormArray, FormGroup } from '@angular/forms';

import { CommonObject } from './common-object.model';
import { User } from './user.model';

export enum AcvitivyAreas {
    "Locale di deposito colli DP1/c" = "Locale di deposito colli DP1/c",
    "Locale di deposito colli DP2" = "Locale di deposito colli DP2",
    "Altre aree" = "Altre aree",
    "Controlli area cisterne" = "Controlli area cisterne",
    "Impianto" = "Impianto",
    "Laboratorio" = "Laboratorio",
    "Non specificata" = "Non specificata"
}

export enum ActivityTypes {
    "Rifiuti in colli" = "Rifiuti in colli",
    "Rifiuti in cisterne" = "Rifiuti in cisterne",
    "Controlli vari Impianto" = "Controlli vari Impianto"
}

export class Activity extends CommonObject {
    name: string;
    description?: string;
    note?: string;
    type?: string;
    area?: string;
    assigner?: User;
    substitute?: User;
    frequency?: string;

    constructor(source) {
        super(source);
        if (source) {
            this.name = source.name;
            this.description = source.description;
            this.note = source.note;
            this.type = source.type;
            this.area = source.area;
            this.frequency = source.frequency;

            if (source.assigner) {
                this.assigner = new User(source.assigner);
            }

            if (source.substitute) {
                this.substitute = new User(source.substitute);
            }
        }
    }

    static fromFormGroup(formGroup: FormGroup): Activity {
        const formModel = formGroup.value;

        let activiy: Activity = new Activity(null);
        activiy.name = formModel.name;
        activiy.description = formModel.description;
        activiy.note = formModel.note;
        activiy.type = formModel.type;
        activiy.area = formModel.area;
        activiy.assigner = formModel.assigner;
        activiy.substitute = formModel.substitute;
        activiy.frequency = formModel.frequency;
        return activiy;
    }
}

export interface ActivityFilters {
    search?: string;
    assigner?: User;
    substitue?: User;
    frequency?: string;
}

export class Todo extends CommonObject {
    done: boolean;
    activity?: Activity;
    expirationDate?: Date;
    executionDate?: Date;
    executor?: User;
    note?: string;
    adminNote?: string;

    constructor(source) {
        super(source);
        if (source) {
            this.done = source.done;
            if (source.activity) {
                this.activity = new Activity(source.activity);
            }
            if (source.expiration_date) {
                this.expirationDate = new Date(source.expiration_date);
            }
            if (source.execution_date) {
                this.executionDate = new Date(source.execution_date);
            }
            if (source.executor) {
                this.executor = new User(source.executor);
            }
            this.note = source.note || "";
            this.adminNote = source.admin_note;
        }
    }

    static fromFormGroup(formGroup: FormGroup, original?: Todo): Todo {
        const formModel = formGroup.value;

        let todo: Todo = new Todo(null);
        todo.done = formModel.done;
        todo.note = formModel.note;
        if (original) {
            todo.objectId = original.objectId;
        }
        //mrosetti - Other fields cannot be changed on frontend
        return todo;
    }

    get executed(): boolean {
        return this.executionDate != null;
    }

    get warning(): boolean {
        return this.executed && this.note != "";
    }
}

export interface TodoFilters {
    activity?: Activity;
    expirationStart?: Date;
    expirationEnd?: Date;
    showExecuted?: boolean;
    executionStart?: Date;
    executionEnd?: Date;
    executor?: User;
    assigner?: User;
    searchQuery?: string;
    status?: "done" | "undone" | "warning"
}

export class DailyActivity extends CommonObject {
    date: Date;
    assigner?: User;
    note?: string;
    todos?: Todo[];

    constructor(source) {
        super(source);
        if (source) {
            this.note = source.note;
            if (source.date) {
                this.date = new Date(source.date);
            }
            if (source.user) {
                this.assigner = new User(source.user);
            }
            if (source.todos) {
                this.todos = source.todos.map(dto => new Todo(dto));
            }
        }
    }

    static empty(date: Date, assigner: User, todos: Todo[] = []): DailyActivity {
        let dailyActivity: DailyActivity = new DailyActivity(null);
        dailyActivity.date = date;
        dailyActivity.assigner = assigner;
        dailyActivity.todos = todos;
        return dailyActivity;
    }

    static fromFormGroup(formGroup: FormGroup, original?: DailyActivity): DailyActivity {
        const formModel = formGroup.value;

        let dailyActivity: DailyActivity = new DailyActivity(null);
        dailyActivity.note = formModel.note;
        const executed: Todo[] = (<FormArray>formGroup.get('executed')).controls
            .map(control => <FormGroup>control)
            .map(formGroup => Todo.fromFormGroup(formGroup, formGroup.get('todo').value)) || [];
        const unexecuted: Todo[] = (<FormArray>formGroup.get('unexecuted')).controls
            .map(control => <FormGroup>control)
            .map(formGroup => Todo.fromFormGroup(formGroup, formGroup.get('todo').value)) || [];
        dailyActivity.todos = executed.concat(unexecuted);
        if (original) {
            dailyActivity.objectId = original.objectId;
            dailyActivity.assigner = original.assigner;
            dailyActivity.date = original.date;
        }
        return dailyActivity;
    }
}

export interface DailyActivityFilters {
    from?: Date;
    to?: Date;
    assigner?: User;
    replacing?: User[];
}

export class DailyReportDTO {
    day: Date;
    total: number;
    executed: number;
    completed: number;
}

export class DailyReport {
    day: Date;
    total: number;
    executed: number;
    completed: number;

    constructor(dto: DailyReportDTO) {
        if (dto) {
            this.day = dto.day;
            this.total = dto.total | 0;
            this.executed = dto.executed | 0;
            this.completed = dto.completed | 0;
        }
    }

    get warnings(): number {
        return this.executed - this.completed;
    }

    get undone(): number {
        return this.total - this.executed;
    }
}