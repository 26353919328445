import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "../services";

@Injectable()
export class UnauthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(resp => {
        // on Response
        if (resp instanceof HttpResponse) {
          // Do whatever you want with the response.
          return resp;
        }
      }),
      catchError(err => {
        // onError
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          console.log(err.status);
          console.log(err.statusText);
          if (err.status === 401) {
            this.authService.logout();
            this.router.navigate(["/login"]);
          }
        }
        throw err;
      })
    );
  }
}
