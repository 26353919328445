import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../helpers/listResult.interface';
import { User } from '../model';
import { LaravelUserService } from './laravel';

@Injectable()
export class UserService {
  constructor(private laravelUserService: LaravelUserService) { }

  public getUser(id: number, include?: string | string[]): Observable<User> {
    return this.laravelUserService.getById(id, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return new User(response);
      })
    );
  }

  public getUsers(page: number, perPage: number, order: string, direction: string, filter?: string, include?: string | string[]): Observable<ListResult<User>> {
    return this.laravelUserService.list(page, perPage, order, direction, filter, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<User>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new User(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new User(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public createUser(user: User, password): Observable<User> {
    return this.laravelUserService.create(user.name, user.surname, user.email, password, user.isAdmin, user.isViewer, user.isOffice, user.operator ? user.operator.objectId : null).pipe(
      map(result => new User(result))
    );
  }

  public updateUser(user: User): Observable<User> {
    return this.laravelUserService.update(user.objectId, user.name, user.surname, user.email, user.isAdmin, user.isViewer, user.isOffice, user.operator ? user.operator.objectId : null).pipe(
      map(result => new User(result))
    );
  }

  public updatePassword(userId: number, newPassword: string) {
    return this.laravelUserService.changePassword(userId, newPassword).pipe(
      map(result => new User(result))
    );
  }

  public deleteUser(user: User): Observable<User> {
    return this.laravelUserService.delete(user.objectId).pipe(
      map(result => new User(result))
    );
  }
}
