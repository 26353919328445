import { NgModule } from '@angular/core';

import {
  LaravelActivityService,
  LaravelAnagraphicService,
  LaravelCommentService,
  LaravelDailyActivityService,
  LaravelOperatorService,
  LaravelOrderedProductService,
  LaravelOrderService,
  LaravelPlannedDayService,
  LaravelPlannedOrderService,
  LaravelProductService,
  LaravelTodoService,
  LaravelUserService,
  LaravelVehicleService,
} from '.';

@NgModule({
  declarations: [],
  imports: [],
  providers: [LaravelOperatorService, LaravelOrderService, LaravelOrderedProductService, LaravelVehicleService, LaravelAnagraphicService, LaravelProductService, LaravelPlannedDayService, LaravelPlannedOrderService, LaravelUserService, LaravelCommentService, LaravelActivityService, LaravelTodoService, LaravelDailyActivityService]
})
export class LaravelServiceModule { }
