import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../helpers/listResult.interface';
import { Operator } from '../model/operator.model';
import { Resource } from '../model/resource.model';
import { LaravelOperatorService } from './laravel/laravel-operator.service';

@Injectable()
export class OperatorService {
  constructor(private laravelOperatorService: LaravelOperatorService) { }

  public getOperator(id: number): Observable<Operator> {
    return this.laravelOperatorService.getById(id).pipe(
      map(result => {
        return new Operator(result);
      })
    );
  }

  public getOperators(page: number, perPage: number, order: string, direction: string, archived: boolean, includeGenerics: boolean = false): Observable<ListResult<Operator>> {
    return this.laravelOperatorService.list(page, perPage, order, direction, archived, includeGenerics).pipe(
      map(response => {
        let data = new Array<Operator>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new Operator(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new Operator(element));
          });
          total = response.length;
        }
        return { data: data, total: total };
      })
    );
  }

  public createOperator(operator: Operator): Observable<Operator> {
    return this.laravelOperatorService.create(operator.name, operator.surname, operator.patente_C, operator.patente_E, operator.ADR, operator.preposto, operator.autospurgo, operator.ragno, operator.gru, operator.spazi_confinati, operator.pictureUrl, operator.category).pipe(
      map(result => {
        return new Operator(result);
      })
    );
  }

  public updateOperator(operator: Operator): Observable<Operator> {
    return this.laravelOperatorService.update(operator.objectId, operator.name, operator.surname, operator.patente_C, operator.patente_E, operator.ADR, operator.preposto, operator.autospurgo, operator.ragno, operator.gru, operator.spazi_confinati, operator.pictureUrl, operator.category).pipe(
      map(result => {
        return new Operator(result);
      })
    );
  }

  public updateUnavailability(resource: Resource): Observable<Operator> {
    return this.laravelOperatorService.updateUnavailability(resource.objectId, resource.unavStartDate, resource.unavEndDate, resource.unavNote).pipe(
      map(result => {
        return new Operator(result);
      })
    );
  }

  public archiveOperator(operator: Operator): Observable<Operator> {
    return this.laravelOperatorService.archive(operator.objectId).pipe(
      map(result => {
        return new Operator(result);
      })
    );
  }
}
