import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatDialog } from '@angular/material';
import { OperatorSelectionComponent } from 'src/app/shared/modules/operator-selection/operator-selection.component';

import { Operator, Order } from '../../../shared/model';
import { Vehicle } from '../../../shared/model/vehicle.model';
import { OrderSelectionComponent } from '../order-selection/order-selection.component';
import { VehicleSelectionComponent } from './../../../shared/modules/vehicle-selection/vehicle-selection.component';

@Component({
  selector: "app-planned-order",
  templateUrl: "./planned-order.component.html",
  styleUrls: ["./planned-order.component.scss"]
})
export class PlannedOrderComponent implements OnInit {
  //chips
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];
  operatorCtrl = new FormControl();
  vehicleCtrl = new FormControl();

  _orderForm: FormGroup;

  private _availableOperators: Operator[];
  private _busyOperators: Operator[];
  private _unavOperators: Operator[];

  private _availableVehicles: Vehicle[];
  private _busyVehicles: Vehicle[];
  private _unavVehicles: Vehicle[];

  _selectedOrders: Order[];

  private _date: Date;

  @ViewChild("operatorInput") operatorInput: ElementRef;
  @ViewChild("vehicleInput") vehicleInput: ElementRef;

  @Output() onRemove: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog, private fb: FormBuilder) { }

  ngOnInit() { }

  get filteredOperators(): Operator[] {
    return this._filterOperator(
      this.operatorCtrl.value,
      this.availableOperators.filter(o => !o.generic));
  }

  get genericOperators(): Operator[] {
    return this.allOperators.filter(o => o.generic);
  }

  get filteredBusyOperators(): Operator[] {
    return this._filterOperator(this.operatorCtrl.value, this.busyOperators.filter(o => !o.generic));
  }

  get filteredUnavOperators(): Operator[] {
    return this._filterOperator(this.operatorCtrl.value, this.unavOperators.filter(o => !o.generic));
  }

  get allOperators(): Operator[] {
    return [...this.availableOperators, ...this.busyOperators, ...this.unavOperators];
  }

  get filteredVehicles() {
    return this._filterVehicle(
      this.vehicleCtrl.value,
      this.availableVehicles.filter(v => v.license != null)
    );
  }

  get allVehicles(): Vehicle[] {
    return [...this.availableVehicles, ...this.busyVehicles, ...this.unavVehicles]
  }

  get filteredGenericsVehicles() {
    return this._filterVehicle(
      this.vehicleCtrl.value,
      this.allVehicles.filter(v => !v.license)
    );
  }

  get filteredUnavVehicles() {
    return this._filterVehicle(this.vehicleCtrl.value, this.unavVehicles);
  }

  get filteredBusyVehicles() {
    return this._filterVehicle(this.vehicleCtrl.value, this.busyVehicles);
  }

  get orderForm(): FormGroup {
    return this._orderForm;
  }

  @Input()
  set orderForm(orderForm: FormGroup) {
    this._orderForm = orderForm;
  }

  get availableOperators(): Operator[] {
    return this._availableOperators;
  }

  @Input()
  set availableOperators(availableOperators: Operator[]) {
    this._availableOperators = availableOperators;
  }

  get busyOperators(): Operator[] {
    return this._busyOperators;
  }

  @Input()
  set busyOperators(busyOperators: Operator[]) {
    this._busyOperators = busyOperators;
  }

  get unavOperators(): Operator[] {
    return this._unavOperators;
  }

  @Input()
  set unavOperators(unavOperators: Operator[]) {
    this._unavOperators = unavOperators;
  }

  get availableVehicles(): Vehicle[] {
    return this._availableVehicles;
  }

  @Input()
  set availableVehicles(availableVehicles: Vehicle[]) {
    this._availableVehicles = availableVehicles;
  }

  get busyVehicles(): Vehicle[] {
    return this._busyVehicles;
  }

  @Input()
  set busyVehicles(busyVehicles: Vehicle[]) {
    this._busyVehicles = busyVehicles;
  }

  get unavVehicles(): Vehicle[] {
    return this._unavVehicles;
  }

  @Input()
  set unavVehicles(unavVehicles: Vehicle[]) {
    this._unavVehicles = unavVehicles;
  }

  get selectedOrders(): Order[] {
    return this._selectedOrders;
  }

  @Input()
  set selectedOrders(selectedOrders: Order[]) {
    this._selectedOrders = selectedOrders;
  }

  get date(): Date {
    return this._date;
  }

  @Input()
  set date(date: Date) {
    this._date = date;
  }

  private _filterOperator(
    value: string | Operator,
    operators: Operator[]
  ): Operator[] {
    if (value instanceof Operator) {
      return [value];
    }

    if (!value) {
      return operators;
    }

    const filterValue = value.toLowerCase();
    return operators.filter(
      operator =>
        operator.name.toLowerCase().indexOf(filterValue) > -1 ||
        operator.surname.toLowerCase().indexOf(filterValue) > -1
    );
  }

  removeOperator(index: number): void {
    this.selectedOperators.removeAt(index);
    this.orderForm.markAsDirty();
  }

  get selectedOperators(): FormArray {
    return this.orderForm.get("operators") as FormArray;
  }

  operatorSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOperators.push(this.fb.control(event.option.value));
    this.orderForm.markAsDirty();
    this.operatorInput.nativeElement.value = "";
    this.operatorCtrl.setValue(null);
  }

  private _filterVehicle(
    value: string | Vehicle,
    vehicles: Vehicle[]
  ): Vehicle[] {
    if (value instanceof Vehicle) {
      return [value];
    }

    if (!value) {
      return vehicles;
    }

    const filterValue = value.toLowerCase();
    return vehicles.filter(vehicle => {
      return (
        vehicle.typeLabel.toLowerCase().indexOf(filterValue) > -1 ||
        (vehicle.license
          ? vehicle.license.toLowerCase().indexOf(filterValue) > -1
          : false)
      );
    });
  }

  isVehicleBusy(vehicle: Vehicle): boolean {
    return this.busyVehicles.find(b => Vehicle.compare(b, vehicle)) != null;
  }

  removeVehicle(index: number): void {
    this.selectedVehicles.removeAt(index);
    this.orderForm.markAsDirty();
  }

  get selectedVehicles(): FormArray {
    return this.orderForm.get("vehicles") as FormArray;
  }

  removeOrder() {
    this.onRemove.emit();
  }

  selectOrder() {
    let data = {
      order: this.orderForm.value["order"],
      selectedOrders: this.selectedOrders
    };
    let dialogRef = this.dialog.open(OrderSelectionComponent, {
      data,
      width: "98%",
      height: "95vh",
      maxHeight: "100vh",
      panelClass: "no-padding"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (
        result.order &&
        !this.compareOrder(result.order, this.orderForm.value["order"])
      ) {
        this.orderForm.patchValue({
          order: result.order,
          note: result.order.note
        });
        this.orderForm.markAsDirty();
      }
    });
  }

  private compareOrder(o1: Order, o2: Order) {
    return o1 && o2 ? o1.objectId === o2.objectId : o1 === o2;
  }

  addOperator() {
    let data = {
      allOperators: [...this.availableOperators, ...this.busyOperators, ...this.unavOperators],
      busyOperators: this.busyOperators,
      selectedDate: this.date
    };
    let dialogRef = this.dialog.open(OperatorSelectionComponent, { data, width: "600px" });

    dialogRef.afterClosed().subscribe(selectedOperator => {
      if (selectedOperator) {
        this.selectedOperators.push(this.fb.control(selectedOperator));
        this.orderForm.markAsDirty();
      }
    })
  }

  addVehicle() {
    let data = {
      allVehicles: [...this.availableVehicles, ...this.busyVehicles, ...this.unavVehicles],
      busyVehicles: this.busyVehicles,
      selectedDate: this.date
    };
    let dialogRef = this.dialog.open(VehicleSelectionComponent, { data, width: "600px" });

    dialogRef.afterClosed().subscribe(selectedVehicle => {
      if (selectedVehicle) {
        this.selectedVehicles.push(this.fb.control(selectedVehicle));
        this.orderForm.markAsDirty();
      }
    })
  }
}
