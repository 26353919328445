import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlanningDashboardComponent } from "./planning-dashboard/planning-dashboard.component";
import { PlanningDetailComponent } from "./planning-detail/planning-detail.component";
import { VehiclesResolverService } from "./helpers/vehicles-resolver.service";
import { OperatorsResolverService } from "./helpers/operators-resolver.service";
import { CompiledDatesResolverService } from "./helpers/compiledDates-resolver.service";
import { PlanningDayResolverService } from "./helpers/planningDay-resolver.service";
import { DailyViewComponent } from "./daily-view/daily-view.component";

const routes: Routes = [
  {
    path: "",
    component: PlanningDashboardComponent,
    resolve: {
      operators: OperatorsResolverService,
      vehicles: VehiclesResolverService
    }
  },
  {
    path: "dailyView/:date",
    component: DailyViewComponent,
    resolve: {
      operators: OperatorsResolverService,
      vehicles: VehiclesResolverService
    }
  },
  {
    path: "dailyPlan",
    component: PlanningDetailComponent,
    resolve: {
      operators: OperatorsResolverService,
      vehicles: VehiclesResolverService,
      compiledDates: CompiledDatesResolverService
    }
  },
  {
    path: "dailyPlan/:date",
    component: PlanningDetailComponent,
    resolve: {
      plannedDay: PlanningDayResolverService,
      operators: OperatorsResolverService,
      vehicles: VehiclesResolverService,
      compiledDates: CompiledDatesResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [OperatorsResolverService, PlanningDayResolverService, VehiclesResolverService, CompiledDatesResolverService]
})
export class PlanningRoutingModule {}
