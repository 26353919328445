import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ListResult } from "../helpers/listResult.interface";

import { LaravelProductService } from "./laravel";
import { Product } from "../model";

@Injectable()
export class ProductService {
  constructor(private laravelProductService: LaravelProductService) {}

  public getProduct(
    id: number,
    include?: string | string[]
  ): Observable<Product> {
    return this.laravelProductService
      .getById(id, typeof include === "string" ? [include] : include)
      .pipe(
        map(response => {
          return new Product(response);
        })
      );
  }

  public getGeneralProducts(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    include?: string | string[]
  ): Observable<ListResult<Product>> {
    return this.getProducts(page, perPage, order, direction, "0", include);
  }

  public getProducts(
    page: number,
    perPage: number,
    order: string,
    direction: string,
    producerCode?: string,
    include?: string | string[]
  ): Observable<ListResult<Product>> {
    return this.laravelProductService
      .list(
        page,
        perPage,
        order,
        direction,
        producerCode,
        typeof include === "string" ? [include] : include
      )
      .pipe(
        map(response => {
          let data = new Array<Product>();
          let total = 0;
          if (perPage) {
            response.data.forEach(element => {
              data.push(new Product(element));
            });
            total = response.total;
          } else {
            response.forEach(element => {
              data.push(new Product(element));
            });
            total = response.length;
          }
          return { data: data, total: total };
        })
      );
  }
}
