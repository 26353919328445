import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../../environments/environment";

@Injectable()
export class LaravelCommentService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/comment",
      list: environment.baseUrl + "/api/comments",
      store: environment.baseUrl + "/api/comment",
      delete: environment.baseUrl + "/api/comment"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(page: number, per_page: number, order: string, direction: string, order_id?: number, include?: string[]): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (order_id) params["order_id"] = "" + order_id;
    if (include) params["include[]"] = include;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(body: string, author_id: number, order_id?: number, notificated_ids?: number[]) {
    let comment: any = {
      body: body,
      author_id: author_id
    };
    if (order_id) {
      comment.order_id = order_id;
    }
    if (notificated_ids) comment["notificated_ids"] = notificated_ids;
    return this.httpClient.post(this.ROUTES.store, {
      comment: comment
    });
  }

  public update(id: number, body: string, author_id: number, order_id?: number, notificated_ids?: number[]) {
    let comment: any = {
      id: id,
      body: body,
      author_id: author_id
    };
    if (order_id) {
      comment.order_id = order_id;
    }
    if (notificated_ids) comment["notificated_ids"] = notificated_ids;
    return this.httpClient.put(this.ROUTES.store, {
      comment: comment
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
