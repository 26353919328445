import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { addIfNotExists, getArrayDifference } from 'src/app/shared/helpers/util';

import { PlannedDay, PlannedOrder } from '../../../shared/model/planned-day.model';
import { AlertService } from '../../../shared/services/alert.service';
import { Operator } from './../../../shared/model/operator.model';
import { Order } from './../../../shared/model/order.model';
import { Vehicle } from './../../../shared/model/vehicle.model';
import { PlanningService } from './../../../shared/services/planning.service';

@Component({
  selector: "app-planned-order-dialog",
  templateUrl: "./planned-order-dialog.component.html",
  styleUrls: ["./planned-order-dialog.component.scss"]
})
export class PlannedOrderDialogComponent implements OnInit {
  orderForm: FormGroup;

  private _plannedOrder: PlannedOrder;
  private _hasUpdates: boolean = false;

  plannedDay: PlannedDay;
  allOperators: Operator[];
  allVehicles: Vehicle[];

  get selectedOrders(): Order[] {
    return this.plannedDay && this.plannedDay.orders
      ? this.plannedDay.orders.map(p => p.order as Order)
      : [];
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PlannedOrderDialogComponent>,
    private alertService: AlertService,
    private planningService: PlanningService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.createForm();
    if (this.data) {
      this.plannedDay = this.data.plannedDay;
      this.allOperators = this.data.allOperators;
      this.allVehicles = this.data.allVehicles;
      this.plannedOrder = this.data.plannedOrder;
    }
  }

  private createForm() {
    const group = {
      order: ["", Validators.required],
      operators: this.fb.array([]),
      vehicles: this.fb.array([]),
      description: [""],
      position: [""],
      startTime: [""],
      overnight: [""],
      note: [""]
    };

    this.orderForm = this.fb.group(group);
  }

  get plannedOrder(): PlannedOrder {
    return this._plannedOrder;
  }

  @Input()
  set plannedOrder(plannedOrder: PlannedOrder) {
    this._plannedOrder = plannedOrder;
    this.ngOnChanges();
  }

  get hasUpdates(): boolean {
    return this._hasUpdates;
  }

  set hasUpdates(hasUpdates: boolean) {
    this._hasUpdates = hasUpdates;
  }

  ngOnChanges() {
    if (this.orderForm) {
      this.orderForm.reset();
      this.resetOperatorsForm();
      this.resetVehiclesForm();
      if (this.plannedOrder) {
        this.orderForm.patchValue({
          order: this.plannedOrder.order,
          description: this.plannedOrder.description,
          position: this.plannedOrder.position,
          overnight: this.plannedOrder.overnight,
          startTime: this.plannedOrder.startDate
            ? moment(this.plannedOrder.startDate)
              .format("HH:mm")
              .toString()
            : "",
          note: this.plannedOrder.note
        });

        if (this.plannedOrder.operators) {
          this.plannedOrder.operators.forEach(operator =>
            this.operatorsForm.push(this.fb.control(operator))
          );
        }

        if (this.plannedOrder.vehicles) {
          this.plannedOrder.vehicles.forEach(vehicle =>
            this.vehiclesForm.push(this.fb.control(vehicle))
          );
        }
      }
    }
  }

  reset() {
    this.ngOnChanges();
  }

  save() {
    let savingPlannedOrder: PlannedOrder = PlannedOrder.fromFormGroup(
      this.orderForm,
      this.plannedDay,
      this.plannedOrder.position
    );
    savingPlannedOrder.objectId = this.plannedOrder.objectId;
    this.planningService.updatePlannedOrder(savingPlannedOrder).subscribe(
      () => {
        this.hasUpdates = true;
        this.alertService.showConfirmMessage(
          `Ordine ${this.plannedOrder.order.objectId} aggiornato`
        );
        this.doClose();
      },
      error => {
        this.alertService.showErrorMessage(
          `Errore nell'aggiornamento dell'ordine ${
          this.plannedOrder.order.objectId
          }`,
          error
        );
      }
    );
  }

  removeOrder() {
    this.alertService
      .showConfirmDialog(
        "Elimina",
        "Sei sicuro di voler eliminare l'ordine pianificato?"
      )
      .subscribe(result => {
        if (result) {
          this.doRemove();
        }
      });
  }

  private doRemove() {
    this.planningService.deletePlannedOrder(this.plannedOrder).subscribe(
      () => {
        this.hasUpdates = true;
        this.alertService.showConfirmMessage(
          `Ordine ${
          this.plannedOrder.order.objectId
          } rimosso dalla pianificazione`
        );
        this.doClose();
      },
      error => {
        this.alertService.showErrorMessage(
          `Errore nell'eliminazione dell'ordine ${
          this.plannedOrder.order.objectId
          } dalla pianificazione`,
          error
        );
      }
    );
  }

  get operatorsForm(): FormArray {
    return this.orderForm.get("operators") as FormArray;
  }

  get vehiclesForm(): FormArray {
    return this.orderForm.get("vehicles") as FormArray;
  }

  resetOperatorsForm() {
    while (this.operatorsForm.length !== 0) {
      this.operatorsForm.removeAt(0);
    }
  }

  resetVehiclesForm() {
    while (this.vehiclesForm.length !== 0) {
      this.vehiclesForm.removeAt(0);
    }
  }

  getAvailableOperators(): Operator[] {
    return getArrayDifference(this.allOperators, [...this.getBusyOperators(), ...this.getUnavOperators()]);
  }

  getBusyOperators(): Operator[] {
    let result = this.plannedDay
      ? this.plannedDay.getBusyOperators(getArrayDifference(this.allOperators, this.getUnavOperators()), this.plannedOrder)
      : [];

    let currentOperators = this.operatorsForm.value;
    if (currentOperators) {
      currentOperators.forEach(o => addIfNotExists(result, o));
    }
    return result;
  }

  getUnavOperators(): Operator[] {
    return this.allOperators.filter(operator => operator.isUnavailable(this.plannedDay.date));
  }

  getAvailableVehicles(): Vehicle[] {
    return getArrayDifference(this.allVehicles, [...this.getBusyVehicles(), ...this.getUnavVehicles()]);
  }

  getBusyVehicles(): Vehicle[] {
    let result = this.plannedDay
      ? this.plannedDay.getBusyVehicles(getArrayDifference(this.allVehicles, this.getUnavVehicles()), this.plannedOrder)
      : [];
    let current = this.vehiclesForm.value;
    if (current) {
      current.forEach(o => addIfNotExists(result, o));
    }
    return result;
  }

  getUnavVehicles(): Vehicle[] {
    return this.allVehicles.filter(v => v.isUnavailable(this.plannedDay.date));
  }

  close() {
    if (this.orderForm.pristine) {
      this.doClose();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.doClose();
          }
        });
    }
  }

  private doClose() {
    this.dialogRef.close(this.hasUpdates);
  }
}
