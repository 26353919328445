import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Vehicle } from 'src/app/shared/model';

@Component({
  selector: 'app-vehicle-activities',
  templateUrl: './vehicle-activities.component.html',
  styleUrls: ['./vehicle-activities.component.scss']
})
export class VehicleActivitiesComponent implements OnInit {

  vehicle: Vehicle;
  start: Date;
  end: Date;

  constructor(
    public dialogRef: MatDialogRef<VehicleActivitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.vehicle = this.data.vehicle;
      this.start = this.data.start;
      this.end = this.data.end;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
