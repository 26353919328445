import { CommonObject } from './common-object.model';

export class Anagraphic extends CommonObject {
  code: string;
  unit: number;
  activityType: string;
  name: string;
  address?: string;
  civic?: string;
  CAP?: string;
  city?: string;
  province?: string;
  note?: string;
  products?: Product[];
  productsAsClient?: Product[];
  headquarter: Anagraphic;

  constructor(source: any) {
    super(source);
    this.code = source.code;
    this.unit = Number(source.unit);
    this.activityType = source.activity_type;
    this.name = source.name;
    this.address = source.address;
    this.civic = source.civic;
    this.CAP = source.CAP;
    this.city = source.city;
    this.province = source.province;
    this.note = source.note;
    if (source.products) {
      this.products = new Array<Product>();
      source.products.forEach(productSource => {
        let product = new Product(productSource);
        product.producer = this;
        this.products.push(product);
      });
    }
    if (source.productsAsClient) {
      this.productsAsClient = new Array<Product>();
      source.products_as_client.forEach(productSource => {
        let product = new Product(productSource);
        product.client = this;
        this.productsAsClient.push(product);
      });
    }
    if (source.headquarter) {
      this.headquarter = new Anagraphic(source.headquarter);
    } else {
      this.headquarter = this;
    }
  }

  get completeAddress(): string {
    let result = "";
    if (this.address) {
      result += `${this.address} `;
    }
    if (this.civic) {
      result += this.civic;
    }

    result += " - ";

    if (this.CAP) {
      result += `${this.CAP} `;
    }

    if (this.city) {
      result += `${this.city} `;
    }

    if (this.province) {
      result += `(${this.province})`;
    }

    return result;
  }
}

export class Product extends CommonObject {
  description?: string;
  note?: string;

  validityStart?: Date;
  validityEnd?: Date;
  disposal?: string;
  agentCode?: string;
  dangerCodes?: string;
  status?: string;

  productCode: string;
  physicalState: string;
  incremental: number;
  destination?: string;
  checks?: string;

  _producerCode: string;
  _producerUnit: number;
  producer?: Anagraphic;

  _clientCode?: string;
  _clientUnit?: number;
  client?: Anagraphic;

  _dumpingCode?: string;
  _dumpingUnit?: number;
  dumping?: Anagraphic;

  destinations: string[];
  linkedRef?: string;

  constructor(source: any) {
    super(source);
    if (source) {
      this.description = source.description;
      this.note = source.note;
      this.validityStart = source.validity_start;
      this.validityEnd = source.validity_end;
      this.disposal = source.disposal;
      this.agentCode = source.agent_code;
      this.dangerCodes = source.danger_codes;
      this.status = source.status;
      this.productCode = source.product_code;
      this.physicalState = source.physical_state;
      this.incremental = source.incremental;

      this._producerCode = source.producer_code;
      this._producerUnit = source.producer_unit;
      if (source.producer) {
        this.producer = new Anagraphic(source.producer);
      }

      this._clientCode = source.client_code;
      this._clientUnit = source.client_unit;
      if (source.client) {
        this.client = new Anagraphic(source.client);
      }

      this._dumpingCode = source.dumping_code;
      this._dumpingUnit = source.dumping_unit;
      if (source.dumping) {
        this.dumping = new Anagraphic(source.dumping);
      }
      this.destination = source.destination;
      if (source.destinations) {
        this.destinations = source.destinations.split(",");
      }
      this.checks = source.checks;
      this.linkedRef = source.linked_ref;
    }
  }

  get producerCode(): string {
    return this.producer ? this.producer.code : this._producerCode;
  }

  get producerUnit(): number {
    return this.producer ? this.producer.unit : this._producerUnit;
  }

  get clientCode(): string {
    return this.client ? this.client.code : this._clientCode;
  }

  get clientUnit(): number {
    return this.client ? this.client.unit : this._clientUnit;
  }

  get dumpingCode(): string {
    return this.dumping ? this.dumping.code : this._dumpingCode;
  }

  get dumpingUnit(): number {
    return this.dumping ? this.dumping.unit : this._dumpingUnit;
  }

  get CER(): string {
    return `${this.producerCode}-${this.productCode}-${this.physicalState}${this.incremental}`;
  }
}
