import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatAccordion, MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { fromString } from 'src/app/shared/helpers/util';
import { DailyActivity, DailyActivityFilters, Todo, User } from 'src/app/shared/model';
import { TodoService } from 'src/app/shared/services';

import { TodoDetailComponent } from './../todo-detail/todo-detail.component';

@Component({
  selector: 'app-report-day',
  templateUrl: './report-day.component.html',
  styleUrls: ['./report-day.component.scss']
})
export class ReportDayComponent implements OnInit {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  private _date: Date;

  activities: DailyActivity[] = [];

  notes: { detail: string, user: User }[] = [];
  groups: Map<string, Group> = new Map<string, Group>();

  @ViewChildren(MatAccordion) accordions: MatAccordion[];

  constructor(
    private todoService: TodoService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportDayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.date = fromString(this.data.date);
    }
  }

  loadTodos() {
    this.loadingSubject.next(true);
    let filters: DailyActivityFilters = {
      from: this.date,
      to: this.date
    }
    this.todoService.getDailyActivities(1, 0, "", "", filters, ["todos.executor", "todos.activity", "todos.activity.assigner", "todos.activity.substitute", "user"])
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(activities => {
        this.activities = activities.data;
        this._refreshNotes();
        this._refreshGroups();
      })

  }

  get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  private _refreshNotes() {
    this.notes = this.activities.filter(activity => activity.note != null && activity.note.length).map(activity => {
      return {
        detail: activity.note,
        user: activity.assigner
      }
    })
  }

  private _refreshGroups() {
    this.groups = new Map<string, Group>()

    this.activities.map(activity => activity.todos).forEach(tds => {
      tds.forEach(todo => {
        const area = todo.activity.area;
        const group: Group = this.groups.get(area) || new Group();
        group.addTodo(todo);
        this.groups.set(area, group);
      })
    })
  }

  close() {
    this.dialogRef.close();
  }

  editTodo(todo: Todo) {
    let data = {
      todo: todo
    };

    let dialogRef = this.dialog.open(TodoDetailComponent, {
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        todo.adminNote = result.adminNote;
      }
    });
  }

  get date(): Date {
    return this._date;
  }

  set date(date: Date) {
    this._date = date;
    this.loadTodos();
  }

  closeAll() {
    if (this.accordions) {
      this.accordions.forEach(a => a.closeAll());
    }
  }

  openAll() {
    if (this.accordions) {
      this.accordions.forEach(a => a.openAll());
    }
  }
}

class Group {
  undone: Todo[] = [];
  warning: Todo[] = [];
  done: Todo[] = [];

  clean() {
    this.undone = [];
    this.warning = [];
    this.done = [];
  }

  addTodo(todo: Todo) {
    if (todo.warning) {
      this.warning.push(todo);
    } else if (todo.executed) {
      this.done.push(todo);
    } else {
      this.undone.push(todo);
    }
  }

  count() {
    return this.undone.length + this.warning.length + this.done.length;
  }
}
