import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { BehaviorSubject, merge } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';

import { Order, ORDER_TYPE } from '../../../shared/model';
import { AlertService, OrderFilters, OrderService } from '../../../shared/services';
import { OrderDatasource } from '../helpers/order.datasource';

@Component({
  selector: "app-orders-list",
  templateUrl: "./orders-list.component.html",
  styleUrls: ["./orders-list.component.scss"]
})
export class OrdersListComponent implements OnInit {

  @ViewChild("downloadCSVLink")
  private downloadCSVLink: ElementRef;

  private generatingCSV = new BehaviorSubject<boolean>(false);
  public generatingCSV$ = this.generatingCSV.asObservable();

  showFilters: boolean = false;
  defaultOrderFilters: OrderFilters = { includePlanned: true, types: Object.keys(ORDER_TYPE) };

  dataSource: OrderDatasource;
  displayedColumns = [
    "id",
    "attachment",
    "createdAt",
    "date",
    "checkTech",
    "checkCommercial",
    "checkLogistic",
    "checkPlant",
    "client",
    "needs",
    "status",
    "add"
  ];
  pageSizeOptions: number[] = [100, 200, 500];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  @Output()
  onAdd: EventEmitter<any> = new EventEmitter();
  @Output()
  onEdit: EventEmitter<Order> = new EventEmitter();

  private filtersSubject: BehaviorSubject<OrderFilters> = new BehaviorSubject<
    OrderFilters
  >(null);

  constructor(private orderService: OrderService, private alertService: AlertService) { }

  ngOnInit() {
    this.dataSource = new OrderDatasource(this.orderService);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page, this.filtersSubject)
      .pipe(tap(() => this.loadOrders()))
      .subscribe();

    this.filtersSubject.next(this.defaultOrderFilters);
  }

  loadOrders() {
    this.filtersSubject.pipe(take(1)).subscribe(filters => {
      this.dataSource.loadOrders(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize | this.pageSizeOptions[0],
        this.sort.active,
        this.sort.direction,
        filters,
        ["client", "supplier", "planned_orders.planned_day"]
      );
    });
  }

  editOrder(order: Order): void {
    this.onEdit.emit(order);
  }

  addOrder(plantOrder: boolean = false): void {
    this.onAdd.emit(plantOrder);
  }

  getRowClass(order: Order) {
    let result = "";
    if (order && order.isClosed()) {
      result += " closed ";
    }
    if (order && order.isDeleted()) {
      result += " trashed ";
    }
    return (result += order ? order.type : "");
  }

  onFilter(filters: OrderFilters) {
    this.filtersSubject.next(filters);
  }

  exportOrdersCSV() {
    this.generatingCSV.next(true);
    let filename = `EsportazioneOrdini.csv`;
    this.filtersSubject.pipe(take(1)).subscribe(filters => {
      this.orderService.exportOrders(filters).pipe(finalize(() => this.generatingCSV.next(false))).subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = this.downloadCSVLink.nativeElement;
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
        this.alertService.showConfirmMessage('Esportazione ordini generata con successo');
      }, error => {
        this.alertService.showErrorMessage('Errore nell\'esportazione degli ordini', error);
      });

    });
  }
}
