import { BehaviorSubject } from "rxjs";
import { MAT_DIALOG_DATA } from "@angular/material";
import { Inject } from "@angular/core";
import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { Order } from "src/app/shared/model";

import { AlertService } from "./../../../shared/services/alert.service";
import { OrderService } from "./../../../shared/services/order.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "deleted-order-dialog",
  templateUrl: "./deleted-order-dialog.component.html",
  styleUrls: ["./deleted-order-dialog.component.scss"]
})
export class DeletedOrderDialogComponent {
  order: Order;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<DeletedOrderDialogComponent>,
    private alertService: AlertService,
    private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      this.order = this.data.order;
    }
  }

  restore() {
    this.loadingSubject.next(true);
    this.orderService
      .restoreOrder(this.order)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        () => {
          this.alertService.showConfirmMessage(
            `Ordine ${this.order.objectId} ripristinato`
          );
          this.dialogRef.close(true);
        },
        error => {
          this.alertService.showErrorMessage(
            `Errore durante il ripristino dell'ordine ${this.order.objectId}`,
            error
          );
        }
      );
  }

  delete() {
    this.loadingSubject.next(true);
    this.orderService
      .deleteOrder(this.order)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        () => {
          this.alertService.showConfirmMessage(
            `Ordine ${this.order.objectId} rimosso`
          );
          this.dialogRef.close(true);
        },
        error => {
          this.alertService.showErrorMessage(
            `Errore durante la rimozione dell'ordine ${this.order.objectId}`,
            error
          );
        }
      );
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
